import { Avatar, Chip, makeStyles } from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";

import { theme } from "styles/theme";
import { useHistory } from "react-router";

import {
  getQueryParamJSONArray,
  getQueryParamList,
  toggleQueryParam,
  unsetQueryParam,
  updateQueryParam,
  useQueryParams,
} from "utils/history";
import { FILTER_BAR_OPEN_QUERY_PARAM } from ".";
import { FilterInterface } from "types/filter-types";
import { ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { generateFilterQuery, prepareFilter } from "apps/ops-dashboard/services/filters";
import { AGENT_GENERAL_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";

const styles = makeStyles({
  container: {
    position: "absolute",
    top: 56,
    width: "280px",
    zIndex: -1,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});

const FilterChips = () => {
  const classes = styles();
  const history = useHistory();
  const query = useQueryParams();
  const filterOptions = prepareFilter();

  const handleDelete = (key, value, deleteFilterOn: 'order'|'agent') => {
    const filterQueryParam = deleteFilterOn === 'order' ? ORDER_FILTER_QUERY_PARAM : AGENT_GENERAL_FILTER_QUERY_PARAM
    history.replace(
      updateQueryParam(filterQueryParam, generateFilterQuery(key, value, deleteFilterOn))
    );
  };

  const openSideBar = () => {
    history.replace(
      toggleQueryParam(FILTER_BAR_OPEN_QUERY_PARAM)
    )
  }
  

  return (
    <div className={classes.container}>
      {getQueryParamJSONArray(ORDER_FILTER_QUERY_PARAM).flatMap(
        (filter: FilterInterface) => {
          return filter.values.map((value) => {
            const filterOption = filterOptions.find(
              (filterOption) => filterOption.key === filter.key
            );
            if (!filterOption) {
              return <div key={filter.key + value}></div>;
            }
            const filterItem = filterOption.items.find(
              (f) => f.value === value
            );
            if (!filterItem) {
              return <div key={filter.key + value}></div>;
            }
            return (
              <Chip
                key={filter.key + value}
                color="primary"
                label={filterItem.name}
                onDelete={() => handleDelete(filter.key, value, 'order')}
                component="code"
                className={classes.chip}
                onClick={() => openSideBar()}
                size="small"
                avatar={<Avatar>O</Avatar>}
              />
            );
          });
        }
      )}
      {getQueryParamJSONArray(AGENT_GENERAL_FILTER_QUERY_PARAM).flatMap(
        (filter: FilterInterface) => {
          return filter.values.map((value) => {
            const filterOption = filterOptions.find(
              (filterOption) => filterOption.key === filter.key
            );
            if (!filterOption) {
              return <div key={filter.key + value}></div>;
            }
            const filterItem = filterOption.items.find(
              (f) => f.value === value
            );
            if (!filterItem) {
              return <div key={filter.key + value}></div>;
            }
            return (
              <Chip
                key={filter.key + value}
                color="primary"
                label={filterItem.name}
                onDelete={() => handleDelete(filter.key, value, 'agent')}
                component="code"
                className={classes.chip}
                onClick={() => openSideBar()}
                size="small"
                avatar={<Avatar>A</Avatar>}
              />
            );
          });
        }
      )}
    </div>
  );
};

export default FilterChips;

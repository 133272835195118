import { Button, InputLabel, MenuItem, Select, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import AgentInfoAccordian from "apps/ops-dashboard/components/agents/AgentInfoAccordian";
import SelectedOrders from "apps/ops-dashboard/components/orders/SelectedOrders";
import { assignOrders } from "apps/ops-dashboard/services/orders/assign-order";
import { AgentsInterface } from "states/data/agents";
import {
  ASSIGN_MODE_ASSIGNING,
  ASSIGN_MODE_DONE,
  ASSIGN_MODE_NOT_STARTED,
  OrderAssignStatInterface,
  ordersAssignStatSlice,
  OrdersInterface,
  resetStateOrderAssigning,
  setWarehouseID,
  toggleSkipWHDrop,
  unsetSkipWHDrop,
  WHDetailsInterface,
} from "states/data/orders";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getQueryParam,
  getQueryParamList,
  unsetQueryParam,
  updateQueryParam,
  useQueryParams,
} from "utils/history";
import Header from "../header";
import RenderAssigningOrders from "./RenderAssigningOrders";
import AgentRoutes from "services/api/agents";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { REGION_QUERY_PARAM } from "apps/ops-dashboard/services/regions";
import { getWareHouses } from "apps/ops-dashboard/services/configs";
import { AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_AGENTS, SIDEBAR_TYPE_REORDER_ASSIGN_AGENT_QUEUE } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  sideBar: {
    width: 360,
  },
});

function AssignOrdersSideBar() {
  const query = useQueryParams();
  const history = useHistory();
  const classes = styles();
  const dispatch = useDispatch();
  const snackBar = useSnackbar();

  const [warehouses, setWarehouses] = useState<WHDetailsInterface[] | []>([]);
  const [openWhSelect, setOpenWhSelect] = useState(false);

  const allAgents: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );
  const skipWH: boolean = useSelector(
    (state: any) => state.ordersData.skipWhDrop
  );
  const pickedWarehouse: string = useSelector(
    (state: any) => state.ordersData.warehouseID
  )
  const orderAssignStat: OrderAssignStatInterface = useSelector(
    ordersAssignStatSlice
  );

  const selectedAgent = useQueryParams().get(AGENT_SELECTED_QUERY_PARAM);

  useEffect(() => {
    const regionQuery = getQueryParam(REGION_QUERY_PARAM);
    const enqueFailSnackBar = (message: string) => {
      snackBar.enqueueSnackbar(message, {
        persist: true,
        preventDuplicate: true,
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      })
    }
    getWareHouses(regionQuery, setWarehouses,  () => enqueFailSnackBar("Error loading warehouses"));

  }, []);

  const handleWhSelectChange = (event) => {
    dispatch(setWarehouseID(event.target.value));
  };

  const handleWhSelectClose = () => {
    setOpenWhSelect(false);
  };

  const handleWhSelectOpen = () => {
    setOpenWhSelect(true);
  };

  const deselectAgent = () => {
    history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM));
  };

  const switchToReorderQueueAgentMode = () => {
      history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_REORDER_ASSIGN_AGENT_QUEUE));
  };

  const onAssignEnd = () => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS));
    history.replace(unsetQueryParam(ORDERS_SELECTED_QUERY_PARAM));
    history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM));
    dispatch(resetStateOrderAssigning());
    dispatch(unsetSkipWHDrop());
  };

  const renderAgentInfo = () => {
    if (!(allAgents.length && selectedAgent)) {
      return <></>;
    }

    return (
      <>
        <AgentInfoAccordian
          agentData={allAgents.find((x) => x.agentID === selectedAgent)}
        />
      </>
    );
  };

  const renderActionButtons = () => {
    switch (orderAssignStat.assignMode) {
      case ASSIGN_MODE_NOT_STARTED:
        return (
          <>
          <div className="d-flex justify-center pv-2">
          <Select
          open={openWhSelect}
          onClose={handleWhSelectClose}
          onOpen={handleWhSelectOpen}
          value={pickedWarehouse? pickedWarehouse : " "}
          onChange={handleWhSelectChange}
        >
          <MenuItem value=" ">
            <em>select warehouse</em>
          </MenuItem>
          {warehouses.map(wareHouse => <MenuItem key={wareHouse.warehouseID} value={wareHouse.warehouseID}>{wareHouse.name}</MenuItem>)}
        </Select>
          </div>

          <div className="d-flex justify-right">
            {/* <div>
              <Switch
                checked={skipWH}
                onChange={() => dispatch(toggleSkipWHDrop())}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <strong>SKIP WH</strong>
            </div> */}
            <div className="t-right ph-2">
              <Button onClick={deselectAgent} style={{ color: "red" }}>
                Cancel
              </Button>
              {!!getQueryParamList(ORDERS_SELECTED_QUERY_PARAM).length && (
                <Button onClick={switchToReorderQueueAgentMode} color="secondary">
                  Assign
                </Button>
              )}
            </div>
          </div>

          </>
        );
      case ASSIGN_MODE_DONE:
        return (
          <div className="w-100 t-right ph-2">
            <Button onClick={onAssignEnd}>Done</Button>
          </div>
        );

      default:
        break;
    }
  };

  const renderOrderList = () => {
    switch (orderAssignStat.assignMode) {
      case ASSIGN_MODE_NOT_STARTED:
        return (
          <SelectedOrders
            defaultExpanded
            hideSelectOption={
              orderAssignStat.assignMode > ASSIGN_MODE_NOT_STARTED
            }
          />
        );

      case ASSIGN_MODE_ASSIGNING:
      case ASSIGN_MODE_DONE:
        return <RenderAssigningOrders />;
    }
  };

  if (!selectedAgent) {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS));
  }

  return (
    <div className={classes.sideBar}>
      <Header title="Assign orders" noClose />
      {renderAgentInfo()}

      {renderActionButtons()}

      {renderOrderList()}
    </div>
  );
}

export default AssignOrdersSideBar;

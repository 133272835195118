import { REGION_QUERY_PARAM } from 'apps/ops-dashboard/services/regions';
import { getQueryParam, getQueryParamJSONArray } from "utils/history";
import { get_order_quadrant } from 'utils/quadrant-select';
import {
  setOrdersData,
  updateOrdersData,
  OrdersInterface,
  BusinessDetailsInterface,
} from "../../../../../states/data/orders";
import { QUADRANT } from '../../configs';
import { setBusinessData } from '../businesses-details';
import { ORDER_SEARCH_KEYS, allowInvertFilters } from 'global-constants/orders/filters';
import { FilterInterface, FilterOption } from 'types/filter-types';
import { ORDER_SEARCH_QUERY_PARAM, ORDERS_SHOW_D2C_QUERY_PARAM, INVERT_FILTER_SELECTION, ORDER_FILTER_QUERY_PARAM, ORDERS_QUADRANT_QUERY_PARAM } from 'global-constants/orders/query_params';


export const orderFilters: { [propName: string]: FilterOption } = {
  "ship.type": {
    name: "Ship Type",
    key: "",
    items: [
      {
        name: "NOW",
        value: "NOW"
      },
      {
        name: "RUSH",
        value: "RUSH",
      },
      {
        name: "XPRESS",
        value: "XPRESS",
      },
      {
        name: "SAMEDAY",
        value: "SAMEDAY",
      },
      {
        name: "STANDARD",
        value: "STANDARD",
      },
      {
        name: "NOW_ENTERPRISE",
        value: "NOW_ENTERPRISE"
      },
      {
        name: "SAME_DAY_ENTERPRISE",
        value: "SAMEDAY_ENTERPRISE",
      },
      {
        name: "NEXTDAY_ENTERPRISE",
        value: "NEXTDAY_ENTERPRISE"
      },
      {
        name: "RUSH_ENTERPRISE",
        value: "RUSH_ENTERPRISE"
      },
      {
        name: "XPRESS_ENTERPRISE",
        value: "XPRESS_ENTERPRISE"
      },
      {
        name: "STANDARD_ENTERPRISE",
        value: "STANDARD_ENTERPRISE"
      }
    ],
    filterFunction: (dataValue, optionValue) => dataValue === optionValue,
    elementType: "checkbox",
  },
  "pack.type": {
    name: "Pack Size",
    key: "",
    items: [
      {
        value: "X-SMALL",
        name: "X-SMALL"
      },
      {
        value: "SMALL",
        name: "SMALL",
      },
      {
        value: "MEDIUM",
        name: "MEDIUM",
      },
      {
        value: "LARGE",
        name: "LARGE",
      },
      {
        value: "X-LARGE",
        name: "X-LARGE"
      },
      {
        value: "XX-LARGE",
        name: "XX-LARGE"
      },
      {
        value: "QUARTER-TRUCK-LOAD",
        name: "QUARTER TRUCK LOAD"
      },
      {
        value: "HALF-TRUCK-LOAD",
        name: "HALF TRUCK LOAD"
      },
      {
        value: "FULL-TRUCK-LOAD",
        name: "FULL TRUCK LOAD"
      }
    ],
    filterFunction: (dataValue, optionValue) => dataValue === optionValue,
    elementType: "checkbox",
  },
  slaDrop: {
    name: "Drop within",
    key: "",
    items: [
      {
        value: 0,
        name: "Breached",
      },
      {
        value: 1000 * 60 * 5,
        name: "5 mins",
      },
      {
        value: 1000 * 60 * 15,
        name: "15 mins",
      },
      {
        value: 1000 * 60 * 30,
        name: "30 mins",
      },
      {
        value: 1000 * 3600,
        name: "1 hour",
      },
      {
        value: 1000 * 3600 * 2,
        name: "2 hours",
      },
      {
        value: 1000 * 3600 * 4,
        name: "4 hours",
      },
      {
        value: 1000 * 3600 * 24,
        name: "24 hours",
      },
      {
        value: 1000 * 3600 * 24 * 2,
        name: "48 hours",
      },
      {
        value: 1000 * 3600 * 3600,
        name: "All time",
      }
    ],
    filterFunction: (dataValue, optionValue) => 
      dataValue <= (new Date().getTime() + optionValue),
    elementType: "radio",
  },
  orderStatus: {
    name: "Order Status",
    key: "",
    items: [
      {
        value: "NEW",
        name: "NEW",
      },
      {
        value: "ASSIGNED",
        name: "ASSIGNED",
      },
      {
        value: "STARTED",
        name: "STARTED",
      },
      {
        value: "WH_PICK_STARTED",
        name: "WH_PICK_STARTED",
      },
      {
        value: "PICKUPFAILED",
        name: "PICKUPFAILED",
      },
      {
        value: "PICKUPCOMPLETED",
        name: "PICKUPCOMPLETED",
      },
      {
        value: "DROPOFFFAILED",
        name: "DROPOFFFAILED",
      },
      {
        value: "WH_PICK",
        name: "WH_PICK",
      },
      {
        value: "WH_DROP",
        name: "WH_DROP",
      },
      {
        value: "DROPCOMPLETED",
        name: "DROPCOMPLETED",
      },
      {
        value: "RETURNED",
        name: "RETURNED",
      },
      {
        value: "CANCELLED",
        name: "CANCELLED",
      },
    ],
    filterFunction: (dataValue, optionValue) => dataValue === optionValue,
    elementType: "checkbox",
  },
  WhOrderStatus: {
    name: "Wh orders",
    key: "",
    items: [
      {
        value: "WH_ASSIGNED",
        name: "Ignore WH assigned"
      }
    ],
    filterFunction: (dataValue, optionValue) => dataValue !== optionValue,
    elementType: "checkbox"
  },
  business_code: {
    name: "Businesses",
    key: "",
    items: [],
    filterFunction: (dataValue, optionValue) => dataValue === optionValue,
    elementType: "checkbox"
  },
  requestedAtTimestamp: {
    name: "Scheduled Orders",
    key: "",
    items: [
      {
        value: 0,
        name: "Hide Scheduled Orders"
      },
      {
        value: 1000 * 60 * 15,
        name: "In 15 mins"
      },
      {
        value: 1000 * 60 * 30,
        name: "In 30 mins"
      },
      {
        value: 1000 * 3600 * 3600,
        name: "All"
      }
    ],
    filterFunction: (dataValue, optionValue) => 
      dataValue < (new Date().getTime() + optionValue),
    elementType: "radio",
    default: 0
  },
};


export const getBusinessFilters = (businesses_details: BusinessDetailsInterface[]) => {
  if(!businesses_details) return []
  return businesses_details.map(obj => ({name: obj.businessName as string, value: obj.businessCode}))
}

const filterOrders = (orderData: OrdersInterface[]) => {
  const searchQuery = getQueryParam(ORDER_SEARCH_QUERY_PARAM);
  const regionQuery = getQueryParam(REGION_QUERY_PARAM);
  const showD2cQuery = getQueryParam(ORDERS_SHOW_D2C_QUERY_PARAM);
  const invertedFilters = JSON.parse(
    getQueryParam(INVERT_FILTER_SELECTION) || "[]"
  );

  const activeFilters: FilterInterface[] = getQueryParamJSONArray(
    ORDER_FILTER_QUERY_PARAM
  );
  
  if (!activeFilters.length && !searchQuery && !regionQuery) {

    return orderData;
  }

  return orderData.filter((order) => {
    let filterMatch = true;
    let searchMatch = false;
    let regionMatch = false; 
    let excludedFilterMatch = false;
    let d2cMatch = false;

    const allFilters = activeFilters.reduce((acc, iter) => {
      if(allowInvertFilters.includes(iter.key) && invertedFilters.includes(iter.key)){
        acc.excludedActiveFilters.push(iter)
      } else {
        acc.includeActiveFilters.push(iter)
      }
      return acc
    }, {includeActiveFilters: [], excludedActiveFilters: []} as
    {includeActiveFilters: FilterInterface[], excludedActiveFilters: FilterInterface[]})

    for (let filter of allFilters.includeActiveFilters) {
      let dataAccessor: any = order;
      for (let k of filter.key.split(".")) {
        dataAccessor = dataAccessor[k];
      }
      filterMatch =
        filterMatch &&
        filter.values.findIndex((val) =>
          orderFilters[filter.key].filterFunction(dataAccessor, val)
        ) > -1;
    }

    for (let filter of allFilters.excludedActiveFilters) {
      let dataAccessor: any = order;
      for (let k of filter.key.split(".")) {
        dataAccessor = dataAccessor[k];
      }
      excludedFilterMatch =
        (filter.values.findIndex((val) =>
          orderFilters[filter.key].filterFunction(dataAccessor, val)
        ) > -1);
    }

    if (searchQuery) {
      for (let keys of ORDER_SEARCH_KEYS) {
        let dataAccessor: any = order;
        for (let k of keys.split(".")) {
          dataAccessor = dataAccessor[k];
        }
        if (!searchMatch) {
          searchMatch = dataAccessor
            .toLocaleLowerCase()
            .includes(searchQuery.toLocaleLowerCase());
        }
      }
    }

    if (regionQuery) {
      // const orderLatLng = { lat: order.pickLocation.lat, lng: order.pickLocation.lng }
      // if (presentInRegion(parseInt(regionQuery), orderLatLng)) {
      //   regionMatch = true;
      // }
      if (order.region.toString() === regionQuery) {
        regionMatch = true;
      }
    }

    if(+showD2cQuery! == 1) {
      d2cMatch = order.d2c || false
    } 

    return filterMatch && (!searchQuery || searchMatch) && (!regionQuery || regionMatch) && !excludedFilterMatch && (!+showD2cQuery! || d2cMatch);

  });
};

const applyExpensiveFilters = (orders) => {

  const quadrantQuery = getQueryParam(ORDERS_QUADRANT_QUERY_PARAM);
  if(!quadrantQuery) {
    return orders;
  }

  const filter_orders = orders.filter(order => {
    let quadrantMatch = false
    
    if(quadrantQuery) {
      const quadrantNumber = parseInt(quadrantQuery);
      if (quadrantNumber < 1 || quadrantNumber > 4) {
        quadrantMatch = true; // If quadrant is not in range, show all orders
      }
      const orderQuadrant = get_order_quadrant(order.pickLocation, order.dropLocation)
      if (QUADRANT[quadrantNumber] === orderQuadrant) {
        quadrantMatch = true;
      }
  }
  return quadrantMatch;
})

return filter_orders;
}


export const updateOrdersWithFilter = (ordersData?) => (dispatch, getState) => {
  const orders = ordersData || getState().ordersData.origOrdersData;
  if (ordersData) {
    dispatch(setOrdersData(ordersData));
    dispatch(setBusinessData(ordersData));
  }
  const filteredOrders = filterOrders(orders);

  dispatch(updateOrdersData(applyExpensiveFilters(filteredOrders)));
};

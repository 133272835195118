import moment from 'moment';

const fillTimeWithZero = (timeString) => {
  return timeString < 10 ? `0${timeString}` : `${timeString}`;
};

export const tsToMonthDayTime = (ts) => {
  const SLA = new Date(ts);
  const minutes = fillTimeWithZero(SLA.getMinutes());
  const hours = fillTimeWithZero(SLA.getHours());
  return `${SLA.getDate()}/${SLA.getMonth()} ${hours}:${minutes}`;
};

export const tsToTime = (ts) => {
  const date = new Date(ts);
  const minutes = fillTimeWithZero(date.getMinutes());
  const hours = fillTimeWithZero(date.getHours());
  const _date = fillTimeWithZero(date.getDate());
  const month = fillTimeWithZero(date.getMonth() + 1);
  
  return `${_date}/${month} ${hours}:${minutes}`;
};

export const timeAfterSecond = (seconds) => {
  const dt = new Date();
  const futureTime = new Date(dt.getTime() + seconds * 1000);
  return `${fillTimeWithZero(futureTime.getHours())}:${fillTimeWithZero(
    futureTime.getMinutes()
  )}`;
};

export const tsWeekdayTime = (ts) => {
  const date = new Date(ts);
  const minutes = fillTimeWithZero(date.getMinutes());
  const hours = fillTimeWithZero(date.getHours());
  const day = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'][date.getDay()]
  
  return `${day} ${hours}:${minutes}`;
}

export const calendarTime = (ts) => {
  const date = moment(ts)
  return date.format("Do MMM, hA"); 
}

export const timeFromNow = (ts) => {
  const date = moment(ts)
  return date.from(moment())
}
export const timeToX = (ts) => {
  return moment().to(moment(ts))
}

export const DDMMTime = (ts) => {
  const date = moment(ts)
  return date.format("DD/MM, hA"); 
}

export const DDMMYYTime = (ts) => {
  // also show seconds
  const date = moment(ts)
  return date.format("DD/MM/YY, h:mm:ssA");
}

export const DDMM = (ts) => {
  const date = moment(ts)
  return date.format("DD/MM"); 
}

export const timeTodayStart = () => {
  const date = moment().startOf('day')
  return date.toDate()
}

export const timeTomorrowStart = () => {
  const date = moment().add(1, 'day').startOf('day')
  return date.toDate()
}

export const msToDate = (ms: number) => {
  const date = new Date(ms);
  return date.toLocaleString();
};


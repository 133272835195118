import { createSlice } from "@reduxjs/toolkit";
import { FilterInterface } from "types/filter-types";

export const ASSIGN_MODE_NOT_STARTED = 0;
export const ASSIGN_MODE_ASSIGNING = 1;
export const ASSIGN_MODE_DONE = 2;

export const ORDER_ASSIGN_STATE_PENDING = 0;
export const ORDER_ASSIGN_STATE_ASSIGNING = 1;
export const ORDER_ASSIGN_STATE_SUCCESS = 2;
export const ORDER_ASSIGN_STATE_FAILED = -1;

interface Person {
  name: string;
  mobileNumber: string;
}

interface Location {
  lat: number;
  lng: number;
}

export interface OrdersInterface {
  agentID: string;
  orderID: string;
  ship: {
    type: string;
  };
  pack: {
    type: string;
  };
  sender: Person;
  receiver: Person;
  orderStatus: string;
  requestedAtTimestamp: number;
  slaDrop: number;
  slaPick: number;
  instructions: string;
  distance: number;
  pickLocation: Location;
  dropLocation: Location;
  cancellationReason: string;
  business_code?: string;
  region: number;
  d2c?: boolean;
  nucleusExcluded?: boolean;
}

export interface OrderAssignStatusInterface {
  status: number;
  orderID: string;
  error: string;
}

export interface OrderAssignStatInterface {
  assignMode: number;
  orderAssignStatuses: OrderAssignStatusInterface[];
}

export interface WHOTPInterface {
  whOTP: string,
  orderIDs: string[],
  agentID: string,
  taskType: "WH_DROP"|"WH_PICK"
}

export interface WHDetailsInterface {
  warehouseID : string,
  name : string,
  region : number,
  latitude : number, longitude: number,
  insertedAt :  number,
  active : boolean
}

export interface OrderOTPInterface {
  orderIDs: string[],
  phone: string,
  task: "PICK"|"DROP"
}
export interface FailOrderTaskInterface {
  orderStatus: "PICKUPFAILED"|"DROPOFFFAILED";
  orderID: string;
}

export interface OrderTimelineMediaInterface {
  mediaFrom: string;
  mediaUrl: string;
}

export interface RequestAddressUpdateDataInterface {
  orderID: string;
}

export interface BusinessDetailsInterface {
  businessCode: string;
  businessName: string;
}

const filters: FilterInterface[] = [];
const orders: OrdersInterface[] = [];
const origOrdersData: OrdersInterface[] = [];
const businessesDetail: BusinessDetailsInterface[] = []; 
const orderAssignStat: OrderAssignStatInterface = {
  assignMode: 0,
  orderAssignStatuses: [],
};
const WHOTP: WHOTPInterface = {
  whOTP: "",
  orderIDs: [],
  agentID: "",
  taskType: "WH_DROP",
};
const orderTimelineMedia: OrderTimelineMediaInterface = {
  mediaFrom: "",
  mediaUrl: "",
}
const requestAddressUpdateData: RequestAddressUpdateDataInterface = {
  orderID: ""
}

const failOrderTask: any = null;

const ordersData = createSlice({
  name: "ordersData",
  initialState: {
    ...{
      hoveredOrder: "",
      hoveredInfo: {},
    },
    origOrdersData,
    ordersData: orders,
    filters,
    orderAssignStat,
    initError: false,
    // If any order has been selected for rescheduling
    schedulingOrderID: "",
    skipWhDrop: false,
    warehouseID: "",
    // If WH OTP is generated
    WHOTP,
    // on media click in order timeline
    orderTimelineMedia,
    // For failing started/pickupcompleted/whpick orders
    failOrderTask,
    requestAddressUpdateData,
    businessesDetail
  },
  reducers: {
    setOrdersData: (state, action: { payload: OrdersInterface[] }) => {
      state.origOrdersData = action.payload;
    },
    updateOrdersData: (state, action: { payload: OrdersInterface[] }) => {
      state.ordersData = action.payload;
    },
    setHoveredOrder: (
      state,
      action: { payload: { orderID: string; hoverInfo: object } }
    ) => {
      state.hoveredOrder = action.payload.orderID;
      state.hoveredInfo = action.payload.hoverInfo;
    },
    unsetHoveredOrder: (state) => {
      state.hoveredOrder = "";
      state.hoveredInfo = {};
    },
    setFilter: (state, action: { payload: FilterInterface[] }) => {
      state.filters = action.payload;
    },
    resetStateOrderAssigning: (state) => {
      state.orderAssignStat.assignMode = 0;
    },
    setStateOrderAssigning: (state) => {
      state.orderAssignStat.assignMode = 1;
    },
    setStateOrderAssigningDone: (state) => {
      state.orderAssignStat.assignMode = 2;
    },
    setOrderAssignStatuses: (
      state,
      action: { payload: OrderAssignStatusInterface[] }
    ) => {
      state.orderAssignStat.orderAssignStatuses = action.payload;
    },
    setOrderInitError: (state) => {
      state.initError = true;
    },
    unsetOrderInitError: (state) => {
      state.initError = false;
    },
    setSchedulingOrder: (state, action: { payload: string }) => {
      state.schedulingOrderID = action.payload;
    },
    unsetSchedulingOrderID: (state) => {
      state.schedulingOrderID = "";
    },
    setSkipWHDrop: (state) => {
      state.skipWhDrop = true;
    },
    unsetSkipWHDrop: (state) => {
      state.skipWhDrop = false;
    },
    setWarehouseID: (state, action: {payload: string}) => {
      state.warehouseID = action.payload===" "?"":action.payload;
    },
    unsetWarehouseID: (state) => {
      state.warehouseID = "";
    },
    toggleSkipWHDrop: (state) => {
      state.skipWhDrop = !state.skipWhDrop;
    },
    resetWHOTP: (state) => {
      state.WHOTP = WHOTP;
    },
    setWHOTP: (
      state,
      action: { payload: WHOTPInterface }
    ) => {
      state.WHOTP = action.payload
    },
    resetFailOrderTask: (state) => {
      state.failOrderTask = null;
    },
    setFailOrderTask: (state, action: {payload: FailOrderTaskInterface}) => {
      state.failOrderTask = action.payload;
    },
    setOrderTimelineMedia: (state, action: { payload: OrderTimelineMediaInterface }) => {
      state.orderTimelineMedia = action.payload;
    },
    unsetOrderTimelineMedia: (state) => {
      state.orderTimelineMedia = orderTimelineMedia;
    },
    setRequestAddressUpdateData: (state, action: { payload: RequestAddressUpdateDataInterface }) => {
      state.requestAddressUpdateData = action.payload;
    },
    unsetRequestAddressUpdateData: (state) => {
      state.requestAddressUpdateData = requestAddressUpdateData;
    },
    setBusinessesDetails: (state, action: {payload: BusinessDetailsInterface[]}) => {
      state.businessesDetail = action.payload
    },
    unsetBusinessDetails: (state) => {
      state.businessesDetail = businessesDetail
    },
    updateBusinessDetails: (state, action: {payload: BusinessDetailsInterface[]}) => {
      state.businessesDetail = [...state.businessesDetail, ...action.payload]
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setOrdersData,
  setHoveredOrder,
  unsetHoveredOrder,
  setFilter,
  updateOrdersData,
  setStateOrderAssigning,
  resetStateOrderAssigning,
  setStateOrderAssigningDone,
  setOrderAssignStatuses,
  setOrderInitError,
  unsetOrderInitError,
  setSchedulingOrder,
  unsetSchedulingOrderID,
  setSkipWHDrop,
  unsetSkipWHDrop,
  toggleSkipWHDrop,
  resetWHOTP,
  setWHOTP,
  resetFailOrderTask,
  setFailOrderTask,
  setOrderTimelineMedia,
  unsetOrderTimelineMedia,
  setRequestAddressUpdateData,
  unsetRequestAddressUpdateData,
  setBusinessesDetails,
  unsetBusinessDetails,
  updateBusinessDetails,
  setWarehouseID,
  unsetWarehouseID
} = ordersData.actions;
export const ordersDataSlice = (state) => state.ordersData.ordersData;
export const ordersFilterSlice = (state) => state.ordersData.filters;
export const businessesDetailSlice = (state) => state.ordersData.businessesDetail
export const ordersAssignStatSlice = (state) =>
  state.ordersData.orderAssignStat;
export default ordersData.reducer;

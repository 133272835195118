import {
  AgentsInterface,
  setAgentsData,
  updateAgentsData,
} from "states/data/agents";
import { getQueryParam, getQueryParamJSONArray } from "utils/history";
import { REGION_QUERY_PARAM } from "../../regions";
import { presentInRegion } from "../../regions/check-region";
import { searchAgents, searchMatch } from "./agent-search";
import { AGENT_SEARCH_QUERY_PARAM, AGENT_SHOW_ALL_DUTY_MODE, AGENT_NUCLEUS_EXCLUDED, AGENT_FREE_AGENTS, AGENT_GENERAL_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { FilterInterface, FilterOption } from "types/filter-types";
import { filterAgentState, filterVehicleCategory } from "./agent-state-filter";

export const agentsFilters: { [propName: string]: FilterOption } = {
  "agentState": {
    name: "Agent State",
    key: "",
    items: [
      {
        name: "Free",
        value: "free",
      },
      {
        name: "Busy",
        value: "busy",
      },
      {
        name: "Fresh Assigned",
        value: "freshAssigned",
      },
      {
        name: "Assigned Update",
        value: "assignedUpdate",
      },
      {
        name: "Do not assign",
        value: "doNotAssign",
      },
      {
        name: "Go Home",
        value: "goHome",
      },
      {
        name: "Overloaded",
        value: "overloaded",
      }
      // {
      //   name: "On Duty with no region",
      //   value: "onDutyNoRegion",
      // }
    ],
    filterFunction: (dataValue, optionValue) => filterAgentState(dataValue, optionValue),
    elementType: "checkbox"
  },
  "vehicleCategory": {
    name: "Vehicle Category",
    key: "",
    items: [
      {
        name: "Light load vehicle",
        value: "lightLoadVehicle",
      },
      {
        name: "Heavy load vehicle",
        value: "heavyLoadVehicle",
      }
    ],
    filterFunction: (dataValue, optionValue) => filterVehicleCategory(dataValue, optionValue),
    elementType: "radio",
    default: "lightLoadVehicle"
  },

}


/**
 * 
 * @description adds key to filter options
 *
 * @returns FilterOption[] - Array of filter options
 */
export const prepareFilterAgents = (): FilterOption[] => {
  return Object.keys(agentsFilters).map((key) => {
    return {...agentsFilters[key], ...{key: key}}
  })
}

/**
 * 
 * @description filters agents based on filter options
 * @param agentsData - agents data
 * @returns AgentsInterface[] - Array of filtered agents
 * 
 */
const filterAgents = (agentsData: AgentsInterface[]) => {
  const searchQuery = getQueryParam(AGENT_SEARCH_QUERY_PARAM);
  const query = searchQuery?.toLocaleLowerCase();
  const regionQuery = getQueryParam(REGION_QUERY_PARAM);

  const allAgents = getQueryParam(AGENT_SHOW_ALL_DUTY_MODE);
  const nucleusExcludedAgent = getQueryParam(AGENT_NUCLEUS_EXCLUDED)

  const activeFilters: FilterInterface[] = getQueryParamJSONArray(
    AGENT_GENERAL_FILTER_QUERY_PARAM
  )

  if (!activeFilters.length && !query && !regionQuery && !allAgents && !nucleusExcludedAgent) {
    return agentsData;
  }

  return agentsData.filter((agentData: AgentsInterface) => {
    let keepItem = true;
    let regionMatch = false;

    if (query) {
      keepItem = keepItem && searchMatch(agentData, query)
    }

    // Filter default by on duty agents
    if (!allAgents) {
      keepItem = keepItem && agentData.onDutyMode
    }

    // Filter by nucleus_excluded_agent
    if (nucleusExcludedAgent) {
      let nucleusExcluded = agentData.nucleusExcluded || false
      keepItem = keepItem && nucleusExcluded
    }

    // const showAgentsWithNoRegionSet = (activeFilters['agentState'].values || []).includes('onDutyNoRegion')
    if (regionQuery) {
      // if (showAgentsWithNoRegionSet) {
      //   regionMatch = agentData.onDutyRegion === null || agentData.onDutyRegion === undefined
      // } else {
        const agentLatLng = { lat: agentData.agent_info.lastKnownLocation.lat, lng: agentData.agent_info.lastKnownLocation.lng }
        regionMatch = presentInRegion(parseInt(regionQuery), agentLatLng)
      // }
      // regionMatch = parseInt(regionQuery) === agentData.onDutyRegion
      keepItem = keepItem && regionMatch
    }

    if (activeFilters.length) {
      for (let filter of activeFilters) {
        keepItem = keepItem && agentsFilters[filter.key].filterFunction(agentData, filter.values)
      }
    }
    return keepItem && (!regionQuery || regionMatch)
  })
}


export const updateAgentsWithFilter = (agentsData?) => (dispatch, getState) => {
  const agents = agentsData || getState().agentsData.origAgentsData;
  if (agentsData) {
    dispatch(setAgentsData(agentsData));
  }


  dispatch(updateAgentsData(filterAgents(agents)));
};

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'rsuite/dist/styles/rsuite-default.css'
import "react-datepicker/dist/react-datepicker.css";
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const devMode = process.env.REACT_APP_DEV || 'false';

devMode === 'false' && Sentry.init({
  dsn: "https://017c4322fc3e4b23a38592e9e3bff3e0@o253776.ingest.sentry.io/5932474",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications'
  ]
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

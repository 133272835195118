export const mapPackageSizeToItsAbbreviation = {
    'X-SMALL': ' XS',
    'SMALL': ' S ',
    'MEDIUM': ' M ',
    'LARGE': ' L ',
    'X-LARGE': ' XL',
    'XX-LARGE': 'XXL',
    'QUARTER-TRUCK-LOAD': 'QTL',
    'HALF-TRUCK-LOAD': 'HTL',
    'FULL-TRUCK-LOAD': 'FTL'
}

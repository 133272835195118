import { useEffect, useState } from "react";
import { useQueryParams } from "utils/history";
import AgentRouteMap from "./AgentRouteMap";
import AgentsIconLayer from "./AgentsIconLayer";
import { AGENT_SELECTED_QUERY_PARAM } from "global-constants/agents/query_params";

const LayerRenderer = (props) => {
  const query = useQueryParams()

  const [selectedAgentID, setSelectedAgentID] = useState("")
  
  // Avoid non important query changes running this code
  const renderOnQueryChange = () => {
    const agentID = query.get(AGENT_SELECTED_QUERY_PARAM) || "";
    if (agentID === selectedAgentID) {
      return;
    }
    setSelectedAgentID(agentID);
  };
  useEffect(renderOnQueryChange, [query]);

  return (
    <>
      <AgentsIconLayer selectedAgentID={selectedAgentID}/>
      <AgentRouteMap selectedAgentID={selectedAgentID} />
    </>
  );
};

export default LayerRenderer;

import React from 'react'
import FailOrderTaskDialog from './FailOrderTaskDialog'
import OrderTimelineMediaDialog from './orderTimelineMediaDialog'
import RequestAddressUpdateDialog from './RequestAddressUpdateDialog'
import RescheduleOrder from './RescheduleOrder'
import WHOTPDialog from './WHOTPDialog'

function FullScreenOverlays() {
  return (
    <>
      <RescheduleOrder />
      <WHOTPDialog />
      <FailOrderTaskDialog />
      <OrderTimelineMediaDialog />
      <RequestAddressUpdateDialog />
    </>
  )
}

export default FullScreenOverlays

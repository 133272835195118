export const HEAVY_VEHICLES = [
    'truck',
    'open_cargo_truck',
    'pickup_truck',
    'closed_cargo_truck',
    'closed_truck',
    'three_wheeler',
    'open_cargo_rickshaw',
    'closed_cargo_rickshaw'
]


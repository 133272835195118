import auth from "./auth";
import agents from "./agents";
import orders from "./orders";
import configs from "./configs";
import general from "./general";
import nucleus from "./nucleus";
import nucleusApi from "./nucleusApi";
import OrderApis from "./ordersApi";
import AgentApis from "./agentApi";

const APIReq = {
  auth,
  agents,
  orders,
  configs,
  general,
  nucleus,
  nucleusApi,
  OrderApis,
  AgentApis
}

export default APIReq;
import { Chip } from "@material-ui/core";
import React, { useEffect } from "react";
import { Badge } from "rsuite";

function ToAckOrders({ toAckOrders, limit = 0 }) {
  let orders = toAckOrders ? [...toAckOrders].filter(order=> !(['WH_DROP', 'WH_PICK'].includes(order.type))).map(order=> order.orderID): [];
  orders = Array.from(new Set(orders))

  if (limit) {
    orders = orders.slice(0, limit);
  }


  return (
    <div>
      <div className="d-flex align-center">
        <div className={`font-md pr-1`}>To accept</div>
        <Badge style={{background: "gray"}} content={`${toAckOrders?.length} orders`} />
      </div>
      <div className={`mt-1`}>
        {orders.map((x, i) => (
          <Chip key={i} label={x.substr(8)} variant="outlined" />
        ))}{limit?" ...":""}
      </div>
      {!orders.length && <div className={`font-sm`}>No orders</div>}
    </div>
  );
}

export default ToAckOrders;

import { packSizeKeyToWeight } from "global-constants/agents/info_map";

export const getAgentCarryingLoad = (on_going_orders) => {
    if (on_going_orders.length === 0) {
        return "0";
    }
    let orders = {}
    on_going_orders.forEach(order => {
        if(["WH_DROP", "WH_PICK"].includes(order.type)) {
        order.orders.forEach(wh_order => {
            if (orders[wh_order.orderID]) {
            orders[wh_order.orderID].push(wh_order)
            } else {
            orders[wh_order.orderID] = [wh_order]
            }
        });
        } else {
        if (orders[order.orderID]) {
        orders[order.orderID].push(order)
        } else {
        orders[order.orderID] = [order]
        }
    }
    });
    let carryingWeight = 0;
    Object.keys(orders).forEach(ordersGroup => {
    if (orders[ordersGroup].length === 1) {
      if (orders[ordersGroup][0].type === "DROP") {
        carryingWeight += packSizeKeyToWeight[orders[ordersGroup][0]['packSize'] || 'MEDIUM']
      }
    }
  })
    return carryingWeight.toFixed(0).toString(); 
}
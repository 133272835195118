import { AGENT_SELECTED_QUERY_PARAM } from "global-constants/agents/query_params";
import { AGENT_ORDER_OTP_GENERATION_MODE_QUERY_PARAM, AGENT_ORDER_OTP_GENERATION_MODE_ORDER_PICK, AGENT_ORDER_OTP_GENERATION_MODE_ORDER_DROP, AGENT_ORDER_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM } from "global-constants/orders/query_params";
import APIReq from "services/api";
import { getQueryParam, getQueryParamJSONArray } from "utils/history";

export const SendOTP= (setGeneratingOTP?, snackbar?) => {
  const selectedAgent = getQueryParam(AGENT_SELECTED_QUERY_PARAM);
  if (!selectedAgent) {
    return;
  }
  setGeneratingOTP && setGeneratingOTP(true);
  const newPhoneNumber = window?.prompt('Enter new number to send OTP');
  var task: "DROP" | "PICK";
  switch (getQueryParam(AGENT_ORDER_OTP_GENERATION_MODE_QUERY_PARAM)) {
    case AGENT_ORDER_OTP_GENERATION_MODE_ORDER_PICK:
      task = "PICK";
      break;
    case AGENT_ORDER_OTP_GENERATION_MODE_ORDER_DROP:
      task = "DROP";
      break;
    default:
      return;
  }
  const orders = getQueryParamJSONArray(AGENT_ORDER_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM)
  if(!newPhoneNumber || !task || !orders){
    return
  }
  APIReq.orders.numberChangeOtp(task, orders, newPhoneNumber)
  .then(res => {
    if(res.status !== 200){
      return
    }
    snackbar && snackbar.enqueueSnackbar(`otp request sent to ${newPhoneNumber} for ${orders.length} orders`, {
        variant: "success",
      })
  })
  .catch((err) => {
    const errMessage = err?.response?.data?.message || err
    snackbar && snackbar.enqueueSnackbar(errMessage, {
      variant: "error",
    });
  })
  .finally(() => {
    setGeneratingOTP && setGeneratingOTP(false);
  });
}

export default SendOTP
import { HEAVY_VEHICLES } from "global-constants/agents/filters"
import { AgentsInterface } from "states/data/agents"
import { isAgentOverloaded } from "./agent-load"

export const filterAgentState = (dataValue, optionValue) => {
  let keepItem = true
    if (optionValue.includes("free")) {
      keepItem = dataValue.order_queue?.items?.length === 0 && dataValue.to_ack_order_queue?.items?.length === 0
    }if (optionValue.includes("busy")) {
      keepItem = keepItem && (dataValue.order_queue?.items?.length > 0 && dataValue.to_ack_order_queue?.items?.length === 0)
    }if (optionValue.includes("freshAssigned")) {
      keepItem = keepItem && (dataValue.order_queue?.items?.length === 0 && dataValue.to_ack_order_queue?.items?.length > 0)
    }if (optionValue.includes("assignedUpdate")) {
      keepItem = keepItem && (dataValue.order_queue?.items?.length > 0 && dataValue.to_ack_order_queue?.items?.length > 0)
    }if (optionValue.includes("doNotAssign")) {
      keepItem = keepItem && (dataValue.agent_info.returnHome || {dontAssign: false}).dontAssign
    }if (optionValue.includes("goHome")) {
      keepItem = keepItem && (dataValue.agent_info.returnHome || {enabled: false}).enabled
    }if (optionValue.includes("overloaded")) {
      keepItem = keepItem && isAgentOverloaded(dataValue)
    }
    return keepItem
  }

export const filterVehicleCategory = (dataValue: AgentsInterface, optionValue) => {

  // No filters should have light motor vehicles selected
  if (optionValue.length === 0) {
    if (!dataValue.agent_info.vehicleInfo?.type) {
      return true
    }
    return !HEAVY_VEHICLES.includes(dataValue.agent_info.vehicleInfo.type)
  }


  // we will add undefined vehicle types to light load vehicles
  if (!dataValue.agent_info.vehicleInfo?.type) {
    return optionValue.includes("lightLoadVehicle")
  }

  if (optionValue.includes("lightLoadVehicle")) {
    return !HEAVY_VEHICLES.includes(dataValue.agent_info.vehicleInfo.type)
  }

  if (optionValue.includes("heavyLoadVehicle")) {
    return HEAVY_VEHICLES.includes(dataValue.agent_info.vehicleInfo.type)
  }

}
import { useFetchAgentsFromRunID } from 'apps/nucleus/custom-hooks/useFetchAgentsFromRunID';
import { useFetchRunDetailsFromRunID } from 'apps/nucleus/custom-hooks/useFetchRunDetailsFromRunID';
import React, { useEffect } from 'react'
import allAgentIconLayerNucleus from './allAgentsIconLayer';
import { setNucleusAgentsLayers } from 'states/nucleus/deck.layers';
import { useDispatch } from 'react-redux';

function AgentIconLayer() {

    const{
        runDetails: runDetails, 
        loadingRunDetails: isRunDetailsLoading, 
        hasError: runDetailsError
        }=useFetchRunDetailsFromRunID();


        const {
            agents,
            isInitiating: isAgentsInitiating,
            hasError: agentsHasError,
            fetchedAgents,
            agentsFetchProgress,
            agentsFetchErrorRate
          } = useFetchAgentsFromRunID();

          const dispatch = useDispatch();

      useEffect(() => {
            if (!fetchedAgents.length) {
                return;
            }

            if(!agentsFetchProgress){
                return;
            }
            const allAgentLayer = allAgentIconLayerNucleus(
                fetchedAgents,
                // onOrderHover,
                // onOrderClick,
                // hidePackDetailsOnIcons || false,
                // shiftKeyPressed || !selectedOrders.length
              );
      
              dispatch(
                setNucleusAgentsLayers(allAgentLayer))
      }
        , [ fetchedAgents]);

  return (
    <div>AgentIconLayer</div>
  )
}

export default AgentIconLayer

import { AxiosResponse } from "axios";
import { RxHTTPPatch, HTTPatch, HTTPGet, HTTPPost } from "utils/http";

const PSERVER_URL = process.env.REACT_APP_ENDPOINT;

const requests = {
  getOrderRoute: (orderID) =>
    HTTPGet(`${PSERVER_URL}/m/admin/order_details/${orderID}/route`),
  cancelOrder: (orderID, message: string="") =>
    HTTPPost(`${PSERVER_URL}/m/admin/order/cancel`, {
      orderID: orderID,
      message: message
    }),
  rescheduleOrder: (orderID, timestamp: number, message="") =>
    HTTPPost(`${PSERVER_URL}/m/admin/order/reschedule`, {
      orderID: orderID,
      timestamp: timestamp,
      message: message
    }),
  resetOrder: (orderID) =>
    HTTPPost(`${PSERVER_URL}/m/admin/order/reset`, {
      orderID: orderID,
      message: ""
    }),
  orderFailed: ({
    orderID,
    status,
    reasonCode,
    timestamp = 0,
    message = "",
    action = "",
    agentComments
  }:{
    orderID: string,
    status: "PICKUPFAILED" | "DROPOFFFAILED",
    reasonCode: string,
    timestamp: number,
    message?: string,
    action?: string,
    agentComments: string
  }) =>HTTPPost  (`${PSERVER_URL}/m/admin/order/pick_drop_fail`, {
        orderID,
        status,
        reasonCode,
        timestamp,
        message,
        action, 
        agentComments
    }),
  returnOrder: (orderID, returnNow) =>
    HTTPatch(`${PSERVER_URL}/m/admin/order/return`, {
      orderID: orderID,
      returnNow: returnNow,
    }),
  reattemptOrder: (orderID, reattemptNow, chargeReattempt) =>
    HTTPatch(`${PSERVER_URL}/m/admin/order/reattempt`, {
      orderID: orderID,
      reattemptNow: reattemptNow,
      chargeReattempt: chargeReattempt,
    }),
  assignOrderAjax: (orderID, agentID, skipWarehouseDrop = false) =>
    RxHTTPPatch(`${PSERVER_URL}/m/admin/order`, {
      orderID: orderID,
      agentID: agentID,
      status: "ASSIGNED",
      skipWarehouseDrop,
    }),
  numberChangeOtp: (task, orders, phone) => 
    HTTPPost(`${PSERVER_URL}/m/admin/generate_order_otp`, {
      task: task,
      orders: orders,
      phone: phone
    }),
  getAuditLog: (orderId) =>
    HTTPGet(`${PSERVER_URL}/m/admin/order_details/${orderId}/audit_log`),
  requestAddressUpdate: (orderIds: Array<string>, locationType: 'pick' | 'drop' | '', phone?: string) =>
    HTTPPost(`${PSERVER_URL}/m/admin/orders/generateOrderAddressUpdate`, {
      orderIDs: orderIds,
      locationType: locationType,
      newPhone: phone
    }),
  getBusinessNames: (bizCodes: Array<string>) =>
    HTTPPost(`${PSERVER_URL}/m/admin/biz/names`, {
      businessCodes: bizCodes
    }),
    setNucleusExcluded: (orderID: string, nucleusExcluded: boolean) =>
      HTTPPost(`${PSERVER_URL}/m/admin/ops/orders/nucleus_excluded`, {
        orderID: orderID,
        nucleusExcluded: nucleusExcluded
      }),
};

export default requests;

import { createSlice } from '@reduxjs/toolkit'

const initState = {
  shiftKeyPressed: false
}

const keyPressStates = createSlice({
  name: 'keyPressStates',
  initialState: initState,
  reducers: {
    setShiftKeyPressed: (state) => {
      state.shiftKeyPressed = true
    },
    unsetShiftKeyPressed: (state) => {
      state.shiftKeyPressed = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { setShiftKeyPressed, unsetShiftKeyPressed } = keyPressStates.actions

export const authDataSlice = (state: {keyPressStates: typeof initState}) => state.keyPressStates

export default keyPressStates.reducer
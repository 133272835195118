import { makeStyles } from "@material-ui/core";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import RedoIcon from "@material-ui/icons/Redo";
import NoLuggageIcon from "@material-ui/icons/GridOff"
import DoneAllIcon from "@material-ui/icons/DoneAll";
import React, { useEffect, useState } from "react";
import { theme } from "styles/theme";
import { toggleQueryParam, unsetQueryParam, useQueryParams } from "utils/history";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShiftKeyPressed, unsetShiftKeyPressed } from "states/keypress";
import useCheckMobileScreen from "utils/customhooks";
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import { updateOrdersWithFilter } from "apps/ops-dashboard/services/orders/filters";
import { ORDERS_QUADRANT_QUERY_PARAM, ORDERS_SHOW_D2C_QUERY_PARAM, ORDER_ARC_LAYER_QUERY_PARAM } from "global-constants/orders/query_params";
import { DISABLE_WEBSOCKET_SUCCESS_NOTIFICATIONS, HIDE_PACK_DETAILS_ON_ICONS_PARAM } from "global-constants/general";

const style = makeStyles({
  nebulaContainer: {
    position: "fixed",
    left: theme.spacing(40),
    top: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      top: 'unset',
      bottom: theme.spacing(2),
      left: theme.spacing(2)
    }
  },
  speedDialActionSelected: {
    color: "white",
    backgroundColor: "black"
  }
});

function NebulaLayout() {
  const classes = style();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useCheckMobileScreen();
  const query = useQueryParams();
  const disableNotifications = query.get(DISABLE_WEBSOCKET_SUCCESS_NOTIFICATIONS)
  const showD2c = query.get(ORDERS_SHOW_D2C_QUERY_PARAM)
  const hidePackDetailsOnIcons = query.get(HIDE_PACK_DETAILS_ON_ICONS_PARAM)

  useEffect(() => {
    dispatch(updateOrdersWithFilter());
  } , [showD2c])

  const toggleOrderArcLayer = () => {
    const orderArcLayerQueryParam = query.get(ORDER_ARC_LAYER_QUERY_PARAM);
    if (+(orderArcLayerQueryParam||0) === 1) {
      history.replace(unsetQueryParam(ORDERS_QUADRANT_QUERY_PARAM))
    }
     history.replace(toggleQueryParam(ORDER_ARC_LAYER_QUERY_PARAM));
  };

  const shiftKeyPressed: boolean = useSelector(
    (state: any) => state.keyPressStates.shiftKeyPressed
  );

  const [open, setOpen] = useState(false);

  const toggleShiftKey = (e) => {
    shiftKeyPressed ? dispatch(unsetShiftKeyPressed()) : dispatch(setShiftKeyPressed())
  }

  const toggleWebsocketNotifications = (e) => {
    history.push(toggleQueryParam(DISABLE_WEBSOCKET_SUCCESS_NOTIFICATIONS))
  }

  const toggleShowD2c = (e) => {
    history.push(toggleQueryParam(ORDERS_SHOW_D2C_QUERY_PARAM))
  }

  const toggleShowPackDetailsOnIcons = (e) => {
    history.push(toggleQueryParam(HIDE_PACK_DETAILS_ON_ICONS_PARAM))
  }

  return (
    <>
      <div className={classes.nebulaContainer}>
        <SpeedDial
          ariaLabel="Nebula"
          icon={
            <SpeedDialIcon
              icon={<DonutLargeIcon />}
              openIcon={<RadioButtonUncheckedIcon />}
            />
          }
          direction={isMobile ? "up" : "down"}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
          color="white"
        >
          <SpeedDialAction
            icon={<RedoIcon />}
            tooltipTitle="Toggle order lines"
            onClick={toggleOrderArcLayer}
          />
          <SpeedDialAction
            icon={<DoneAllIcon />}
            tooltipTitle="Toggle shift key"
            onClick={toggleShiftKey}
            className={shiftKeyPressed ? classes.speedDialActionSelected : ''}
          />
          <SpeedDialAction
            icon={<NotificationsOffIcon />}
            tooltipTitle={(disableNotifications ? 'enable': 'disable')+"notifications"}
            onClick={toggleWebsocketNotifications}
            className={disableNotifications ? classes.speedDialActionSelected : ''}
          />
          <SpeedDialAction
            icon={<>D2C</>}
            tooltipTitle={(showD2c ? 'hide': 'show')+"D2C"}
            onClick={toggleShowD2c}
            className={showD2c ? classes.speedDialActionSelected : ''}
          />
          <SpeedDialAction
            icon={<NoLuggageIcon />}
            tooltipTitle={(hidePackDetailsOnIcons ? 'show': 'hide')+"pack details"}
            onClick={toggleShowPackDetailsOnIcons}
            className={hidePackDetailsOnIcons ? classes.speedDialActionSelected : ''}
          />
        </SpeedDial>
      </div>
    </>
  );
}

export default NebulaLayout;

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import polyline from "@mapbox/polyline";
import { setLayerOrderRoute } from "../../../../../states/deck.gl/layers";
import APIReq from "services/api";
import { TripsLayer } from "@deck.gl/geo-layers";

const hoveredPathLayer = (data) => {
  const layer = new TripsLayer({
    id: "order-hovered-path-layer",
    data,
    getPath: (d) => d.coordinates,
    // deduct start timestamp from each data point to avoid overflow
    // getTimestamps: d => d.coordinates.map(),
    getColor: [0, 0, 0],
    opacity: 0.8,
    widthMinPixels: 4,
    rounded: true,
    trailLength: 200,
  });
  return layer;
};

const HoveredOrderRouteMap = (props) => {
  const dispatch = useDispatch();

  const hoveredOrder = useSelector(
    (state: any) => state.ordersData.hoveredOrder
  );

  const setPolyline = (orderID, polylineString) => (dispatch, getState) => {
    const data = [polyline.toGeoJSON(polylineString)];
    const isHoveredOrder = getState().ordersData.hoveredOrder === orderID;
    if (isHoveredOrder) {
      dispatch(setLayerOrderRoute(hoveredPathLayer(data)));
    }
  };

  const renderOnOrderHover = () => {
    if (hoveredOrder === "") {
      dispatch(setLayerOrderRoute(null));
      return;
    }
    APIReq.orders
      .getOrderRoute(hoveredOrder)
      .then((res) => dispatch(setPolyline(hoveredOrder, res.data.data.routePolyline)))
      .catch((err) => console.log(err));
  };
  useEffect(renderOnOrderHover, [dispatch, hoveredOrder]);

  return <></>;
};

export default HoveredOrderRouteMap;

import {
  makeStyles,
} from "@material-ui/core";


import {
  ordersDataSlice,
  OrdersInterface,
} from "states/data/orders";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getQueryParamList } from "utils/history";


import OrdersSearch from "./OrdersSearch";
import { useEffect, useState } from "react";
import AllOrders from "./AllOrders";
import Header from "../header";
import { generateOrderSelectQueryParam } from "apps/ops-dashboard/services/orders/select-order";
import SelectedOrders from "apps/ops-dashboard/components/orders/SelectedOrders";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  ordersSideBar: {
    width: 360,
    height: "100vh",
    display: "block",
  },
  heading: {
    margin: "auto 0",
  }
});

const OrdersSideBar = () => {
  const classes = styles();
  const history = useHistory()
  const ordersData = useSelector(ordersDataSlice);

  const [nonSelectedOrders, setNonSelectedOrders] = useState<OrdersInterface[]>(
    []
  );

  useEffect(() => {
    const selectedOrderIDs = getQueryParamList(ORDERS_SELECTED_QUERY_PARAM)
    if (!selectedOrderIDs.length) {
      setNonSelectedOrders(ordersData);
      return
    }

    const tempNonSelectedOrders: any[] = [];

    for (let order of ordersData) {
      if (selectedOrderIDs.indexOf(order.orderID) === -1) {
        tempNonSelectedOrders.push(order);
      }
    }

    setNonSelectedOrders(tempNonSelectedOrders);
  }, [ordersData]);

  const onOrderSelect = (orderID) => {
    history.replace(
      generateOrderSelectQueryParam(orderID)
    );
  };

  return (
    <div className={classes.ordersSideBar}>
      <Header title="Active orders" />
      {getQueryParamList(ORDERS_SELECTED_QUERY_PARAM).length ? <SelectedOrders showActions/> : <></>}
      <AllOrders orders={nonSelectedOrders} onOrderSelect={onOrderSelect}/>
    </div>
  );
};

export default OrdersSideBar;

import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { setRequestAddressUpdateData } from 'states/data/orders';

import EditLocationIcon from '@material-ui/icons/EditLocation';
import TimelineIcon from '@material-ui/icons/Timeline';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';

import { updateQueryParam } from 'utils/history';
import { useHistory } from 'react-router-dom';
import APIReq from 'services/api';
import { ActiveOrderTimeline, SIDEBAR_TYPE_ORDER_TIMELINE } from 'global-constants/orders/query_params';
import { SIDEBAR_QUERY_PARAM } from 'global-constants/general';

function OrderQuickActions({ orderDetails }) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const orderStatus = orderDetails.orderStatus;
    const orderID = orderDetails.orderID;
    const nucleusExcluded = orderDetails.nucleusExcluded;
    const dispatch = useDispatch()
    const history = useHistory()

    const AddressUpdate = () => {

        const handleOpenDialog = () => dispatch(setRequestAddressUpdateData({ orderID: orderID }));

        return (
            <IconButton key={'addressUpdate'} onClick={handleOpenDialog} size="small"><EditLocationIcon color="primary"/></IconButton>
        )
    }

    const ViewTimline = () => {

        const handleViewTimeline = () =>{
             history.replace(updateQueryParam(ActiveOrderTimeline, orderID))
             history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_ORDER_TIMELINE))
            };

        return (
            <IconButton key={'viewTimeline'} onClick={handleViewTimeline} size="small"><TimelineIcon color="primary"/></IconButton>
        )
    }   

    const NucleusAction = (nucleusExcluded) => {

        const handleExcludeNucleus = () => {
            APIReq.orders.setNucleusExcluded(orderID, true).then((res) => {
                enqueueSnackbar('Nucleus Excluded' + orderID, { variant: 'success' })
            }
            ).catch((err) => {
                enqueueSnackbar('Nucleus Excluded' + orderID, { variant: 'error' })
            }
            )
        }

        const handleIncludeNucleus = () => {
            APIReq.orders.setNucleusExcluded(orderID, false).then((res) => {
                enqueueSnackbar('Nucleus Included' + orderID, { variant: 'success' })
            }
            ).catch((err) => {
                enqueueSnackbar('Nucleus Included' + orderID, { variant: 'error' })
            }
            )
        }

        return (
            <>{ nucleusExcluded === true ?
            (<IconButton onClick={handleIncludeNucleus} size="small"><BrightnessAutoIcon style={{color: "green"}}/></IconButton>) :
            (<IconButton onClick={handleExcludeNucleus} size="small"><BrightnessHighIcon style={{color: "red"}}/></IconButton>)
            }</>
        )
    }

    const CommonActions = () => {
        return (
            <>
            {NucleusAction(nucleusExcluded)}
            </>
        )
    }

    const QuickActions = () => {
        interface ActionEligibilityInterface {
            actionItem: any;
            forbiddenStatuses: Array<String>;
        }

        const actionsEligibility = [
            {
                actionItem: AddressUpdate,
                forbiddenStatuses: ['DROPCOMPLETED', 'CANCELLED', 'RETURNED'],
            },
            {
                actionItem: ViewTimline,
                forbiddenStatuses: []
            }
        ] as Array<ActionEligibilityInterface>

        const actionsQualifier = (action: ActionEligibilityInterface): boolean => {
            if (action.forbiddenStatuses.length && action.forbiddenStatuses.includes(orderStatus)) {
                return false;
            }
            return true;
        }
        const qualifiedActions = actionsEligibility.filter(
            action => actionsQualifier(action)
        ) as Array<ActionEligibilityInterface>;
        if (!qualifiedActions.length) {
            return <></>
        }
        return (
            <>
                {qualifiedActions.map(action => (<action.actionItem key={action.actionItem.name} />))}
            </>
        )
    }

    const CommonActionsCached = useCallback(CommonActions, [orderStatus, orderID, nucleusExcluded])
    const QuickActionsCached = useCallback(QuickActions, [orderStatus, orderID])

    return (
        <>
            <hr />
            <div className="d-flex justify-right">
                <CommonActionsCached />
                <QuickActionsCached />
            </div>
        </>
    )
}

export default OrderQuickActions

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { filterAgentQueueByType } from "apps/ops-dashboard/services/agents/filters/agent-filter";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import APIReq from "services/api";
import { AgentsInterface, OrderQueueItem } from "states/data/agents";
import { resetStateOrderAssigning, unsetSkipWHDrop, unsetWarehouseID } from "states/data/orders";
import { timeFromNow } from "utils/datetime";
import { updateQueryParam, unsetQueryParam } from "utils/history";
import AgentInfo from "./AgentInfo";
import OnGoingTimeline from "./OnGoingTimeline";
import ToAckOrders from "./ToAckOrders";
import WHOrders from "./WHOrders";
import { AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_AGENTS } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  accordianDetails: {
    display: "block",
    padding: 0,
  },
  accordianRoot: {
    boxShadow: "none",
  },
});

const AgentInfoAccordian = ({ agentData }) => {
  const classes = styles();
  const snackbar = useSnackbar()
  const dispatch = useDispatch();
  const history = useHistory();

  const onAssignEnd = () => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS));
    history.replace(unsetQueryParam(ORDERS_SELECTED_QUERY_PARAM));
    history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM));
    dispatch(resetStateOrderAssigning());
    dispatch(unsetSkipWHDrop());
    dispatch(unsetWarehouseID())
  };

  const removeOrderWhPick = (orderItem: OrderQueueItem) => {

    let agent_order_queue = agentData.order_queue.items
    const orderRef = orderItem.ref

    agent_order_queue = agent_order_queue.map(item => {
      if(item.type != "WH_PICK"){
          return item
      }
      if(!item.orders){
          return item
      }
      if(!(item.orders.findIndex(order=>order.ref===orderRef) > -1)){
          return item
      }
      return {...item, ["orders"]: item.orders.filter(order => order.ref!==orderRef)}
  }).filter(item => {
      if(item.type === "WH_PICK" && !(item.orders||[]).length){
          return false
      }
      if(item.ref === orderRef){
          return false
      }
      return true
  })

APIReq.agents.assignOrdersToAgent(agentData.agentID, {agentQueue: {items: agent_order_queue}})
.then(res => {
  if (res.status === 200) {
    snackbar.enqueueSnackbar("Orders assigned successfully", { variant: "success" })
    onAssignEnd()
  }
  else{
  snackbar.enqueueSnackbar("Error assigning orders", { variant: "error" })
  }
}).catch(err => {
  snackbar.enqueueSnackbar(err.message, { variant: "error" });
})

.finally(null);
  };

  return (
    <Accordion
      square
      classes={{ root: classes.accordianRoot, expanded: classes.accordianRoot }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AgentInfo agentData={agentData} />
      </AccordionSummary>
      <AccordionDetails className={`${classes.accordianDetails}`}>
        <div className="ph-2">
          <div className="mt-2 on-going-timeline">
            <OnGoingTimeline
              onGoingOrders={agentData.order_queue?.items || []}
            />
          </div>
          <hr />
          <div className="mt-1 on-going-timeline">
            <ToAckOrders toAckOrders={agentData.to_ack_order_queue?.items || []} />
          </div>
          <hr />
          <div className="mt-1 on-going-timeline">
            <WHOrders whOrders={filterAgentQueueByType(agentData.order_queue?.items || [], 'WH_DROP')} whDrop />
          </div>
          <hr />
          <div className="mt-1 on-going-timeline">
            <WHOrders
              whOrders={filterAgentQueueByType(agentData.order_queue?.items || [], 'WH_PICK')}
              whPick
              limit={6}
              removeOrderCallback={removeOrderWhPick}
            />
          </div>
          <div className="mb-2"></div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AgentInfoAccordian;

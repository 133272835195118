import { Paper } from "@material-ui/core";
import { filterAgentQueueByType } from "apps/ops-dashboard/services/agents/filters/agent-filter";
import { calendarTime, timeFromNow } from "utils/datetime";
import AgentInfo from "./AgentInfo";
import OnGoingTimeline from "./OnGoingTimeline";
import ToAckOrders from "./ToAckOrders";
import WHOrders from "./WHOrders";


function AgentInfoCard({ agentData }) {

  return (
    <div className="w-100 d-block">
      <Paper className="w-100 p-2">
        <AgentInfo agentData={agentData}/>
        <div className="mt-2 on-going-timeline">
          <OnGoingTimeline
            onGoingOrders={agentData.order_queue?.items || []}
            limit={4}
          />
        </div>
        <hr />
        <div className="mt-1 on-going-timeline">
          <ToAckOrders toAckOrders={agentData.to_ack_order_queue?.items || []} limit={6}/>
        </div>
        <hr />
        {/* TODO: come back here after all the changes. so that we globalise this part */}
        <div className="mt-1 on-going-timeline">
          <WHOrders whOrders={filterAgentQueueByType(agentData.order_queue?.items || [], 'WH_DROP')} whDrop limit={4}/>
        </div>
        <hr />
        <div className="mt-1 on-going-timeline">
          <WHOrders whOrders={filterAgentQueueByType(agentData.order_queue?.items || [], 'WH_PICK')} whPick limit={4}/>
        </div>
      </Paper>
    </div>
  );
}

export default AgentInfoCard;

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";
import BlockIcon from "@material-ui/icons/Block";
import ReorderIcon from "@material-ui/icons/Reorder";
import FiberPinIcon from "@material-ui/icons/FiberPin";
import { Virtuoso } from "react-virtuoso";
import { queryToList, updateQueryParam, useQueryParams } from "utils/history";
import { useHistory } from "react-router-dom";
import AgentInfoAccordian from "apps/ops-dashboard/components/agents/AgentInfoAccordian";
import { useDispatch, useSelector } from "react-redux";
import {  AgentsInterface, setHoveredAgent } from "states/data/agents";
import { updateLatLng } from "states/deck.gl/maps";
import { TurnOffDuty } from "apps/ops-dashboard/services/agents/turn-off-duty";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import { OrdersInterface } from "states/data/orders";
import { filterAgentQueueByType } from "apps/ops-dashboard/services/agents/filters/agent-filter";

import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import APIReq from "services/api";
import { Fragment, useState } from "react";
import HomeIcon from "@material-ui/icons/Home";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Button, DatePicker, Divider, Dropdown, Modal } from "rsuite";
import { useSnackbar } from "notistack";
import { AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_ASSIGN_ORDERS, SIDEBAR_TYPE_GENERATE_ORDER_OTP, SIDEBAR_TYPE_GENERATE_WH_OTP, SIDEBAR_TYPE_REORDER_AGENT_QUEUE } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
// import 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   KeyboardTimePicker,
//   MuiPickersUtilsProvider,
//   TimePicker
// } from '@material-ui/pickers';


const SelectAgent = ({ agentDetails }: { agentDetails: AgentsInterface }) => {
  const agentID = agentDetails.agentID;
  const query = useQueryParams();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [goHomePickedTime, setGoHomePickedTime] = useState(null);
  const [openGoHomeModel, setOpenGoHomeModel] = useState(false);
  const handleGoHomeModalOpen = () => setOpenGoHomeModel(true);
  const handleGoHomeModalClose = () => setOpenGoHomeModel(false);
  const cancelAgentReturnHome = () => {
    APIReq.agents.agentReturnHomeCancel(agentID, () => {
      snackbar.enqueueSnackbar("Agent return home cancel failed", {
        variant: "error",
        });
    });
  };
  const agentReturnHomeNow = () => {
    handleGoHomeModalClose();
    APIReq.agents.agentReturnHomeNow(agentID, () => {
      snackbar.enqueueSnackbar("Agent return home now failed", {
        variant: "error",
        });
    });
  }
  const agentReturnHomeLater = (date) => {
    handleGoHomeModalClose();
    APIReq.agents.agentReturnHomeLater(agentID, date.getTime(), () => {
      snackbar.enqueueSnackbar("Agent return home later failed", {
        variant: "error",
        });
    });
    setGoHomePickedTime(null);
  }
  const agentSetDoNotAssign = (reset: boolean) => {
    APIReq.agents.agentSetDoNotAssign(agentID, reset, () => {
      snackbar.enqueueSnackbar("Agent set do not assign failed", {
        variant: "error",
        });
    });
  }
  const agentCancelDoNotAssign = () => {
    APIReq.agents.agentSetDoNotAssignCancel(agentID, () => {
      snackbar.enqueueSnackbar("Agent cancel do not assign failed", {
        variant: "error",
        });
    });
  }

  const switchToSelectedAgentMode = (taskType?: string) => {
    history.replace(updateQueryParam(AGENT_SELECTED_QUERY_PARAM, agentID));
    if (taskType) {
      history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, taskType));
    }
  };


  return (
    <>
    <Dialog  open={openGoHomeModel} onClose={handleGoHomeModalClose}>
          <DialogTitle>Enable agent go home</DialogTitle>
        <DialogContent>
          <div className="d-flex justify-space-between">
            <Button style={{ width: 260 }} onClick={agentReturnHomeNow} appearance="primary">
              Now
            </Button>
            <Divider vertical />
            <DatePicker format="hh:mm aa"
             placeholder="Later"
            showMeridian 
            ranges={[]} 
            style={{ width: 260 }} 
            value={goHomePickedTime || undefined}
            onChange={agentReturnHomeLater}
            />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
          margin="dense"
          id="time-picker-go-home-later"
          label="Later"
          value={goHomePickedTime}
          onChange={agentReturnHomeLater}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
        </MuiPickersUtilsProvider> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGoHomeModalClose} appearance="subtle">
            Cancel
          </Button>
        </DialogActions>
      </Dialog >
    <div className="d-flex justify-space-between">
      <div>
      <Dropdown 
        title="Options"
        tigger="click" 
        // placement="leftStart"
      >
        <Dropdown.Item onSelect={() =>
              window.confirm(
                `Are you sure you want to turn ${agentDetails.agent_info.name}`
              ) && TurnOffDuty(agentID)
            }>
          Turn off duty
        </Dropdown.Item>
        {(agentDetails.nucleusExcluded === true) ? <Dropdown.Item onSelect={() =>
                window.confirm(
                  `Are you sure you want to include ${agentDetails.agent_info.name} in nucleus`
                ) && APIReq.agents.setNucleusExcluded(agentID, false)
              }>Enable Nucleus</Dropdown.Item> :
              <Dropdown.Item onSelect={() =>
                window.confirm(
                  `Are you sure you want to exclude ${agentDetails.agent_info.name} from nucleus`
                ) && APIReq.agents.setNucleusExcluded(agentID, true)
              }>Disable Nucleus</Dropdown.Item>}
        {!!agentDetails.agent_info.returnHome?.enabled ? (
          <Dropdown.Item onSelect={cancelAgentReturnHome}>
            Cancel Go Home
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onSelect={()=>handleGoHomeModalOpen()}> Go Home </Dropdown.Item>
        )}
        {!!!agentDetails.agent_info.returnHome?.dontAssign ? (
          <Dropdown.Menu title="Disable Assignment">
            <Dropdown.Item onSelect={() => agentSetDoNotAssign(true)}>Reset todays limit</Dropdown.Item>
            <Dropdown.Item onSelect={() => agentSetDoNotAssign(false)}>Just disable assignment</Dropdown.Item>
          </Dropdown.Menu>
        ): (
          <Dropdown.Item onSelect={()=>agentCancelDoNotAssign()}> Cancel don't assign </Dropdown.Item>
        )}
      </Dropdown>
        {/* <Tooltip title="Turn agent off duty">
          <IconButton
            style={{ color: "red" }}
            onClick={() =>
              window.confirm(
                `Are you sure you want to turn ${agentDetails.agent_info.name}`
              ) && TurnOffDuty(agentID)
            }
          >
            <BlockIcon />
          </IconButton>
        </Tooltip>
        {agentDetails.nucleusExcluded === true ?
        (
          <Tooltip title="Include agent in nucleus">
            <IconButton
              style={{ color: "green" }}
              onClick={() =>
                window.confirm(
                  `Are you sure you want to include ${agentDetails.agent_info.name} in nucleus`
                ) && APIReq.agents.setNucleusExcluded(agentID, false)
              }
            >
              <BrightnessAutoIcon  style={{color: "green"}}/>
            </IconButton>
          </Tooltip>)
         : (<Tooltip title="Exclude agent from nucleus">
          <IconButton
            style={{ color: "gold" }}
            onClick={() =>
              window.confirm(
                `Are you sure you want to exclude ${agentDetails.agent_info.name} from nucleus`
              ) && APIReq.agents.setNucleusExcluded(agentID, true)
            }
          >
            <BrightnessHighIcon style={{color: "red"}}/>
          </IconButton>
        </Tooltip>)} */}
      </div>
      <div className="d-flex justify-right ph-2">
      {!!(
          (agentDetails?.order_queue?.items || []).length &&
          agentDetails?.order_queue?.items.filter(
            on_going_order => ["PICK","DROP"].includes(on_going_order.type)
          ).length
        ) && (
          <Tooltip title="Generate Order OTP">
            <IconButton
              color="primary"
              onClick={() =>
                switchToSelectedAgentMode(SIDEBAR_TYPE_GENERATE_ORDER_OTP)
              }
            >
              <PermPhoneMsgIcon />
            </IconButton>
          </Tooltip>
        )}
        {!!(
          filterAgentQueueByType(agentDetails?.order_queue?.items||[], 'WH_DROP').length ||
          filterAgentQueueByType(agentDetails?.order_queue?.items||[], 'WH_PICK').length
        ) && (
          <Tooltip title="Generate WH OTP">
            <IconButton
              color="primary"
              onClick={() =>
                switchToSelectedAgentMode(SIDEBAR_TYPE_GENERATE_WH_OTP)
              }
            >
              <FiberPinIcon />
            </IconButton>
          </Tooltip>
        )}
        {!!(agentDetails?.order_queue?.items || []).length && (
          <Tooltip title="Rearrange queue">
            <IconButton
              color="primary"
              onClick={() =>
                switchToSelectedAgentMode(SIDEBAR_TYPE_REORDER_AGENT_QUEUE)
              }
            >
              <ReorderIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Assign orders">
          <IconButton
            color="primary"
            onClick={() =>
              switchToSelectedAgentMode(SIDEBAR_TYPE_ASSIGN_ORDERS)
            }
          >
            <QueuePlayNextIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
    </>
  );
};

const AllAgents = ({ agents, assignMode = false }) => {
  const dispatch = useDispatch();

  const onMouseEnter = (agentInfo: AgentsInterface) => {
    return () => {
      dispatch(
        updateLatLng({
          lat: agentInfo.agent_info.lastKnownLocation.lat,
          lng: agentInfo.agent_info.lastKnownLocation.lng,
        })
      );
      dispatch(setHoveredAgent({ object: agentInfo, sidebarHover: true }));
    };
  };

  return (
    <Virtuoso
      style={{ height: "80vh" }}
      totalCount={agents.length}
      itemContent={(index) => (
        <div
          onMouseEnter={() => dispatch(onMouseEnter(agents[index]))}
          onMouseLeave={() => dispatch(setHoveredAgent({ object: null }))}
        >
          <AgentInfoAccordian agentData={agents[index]} />
          
          { agents[index].onDutyMode && <SelectAgent agentDetails={agents[index]} />}
          <hr />
        </div>
      )}
    />
  );
};

export default AllAgents;

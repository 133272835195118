import { HTTPGet } from "utils/http";

const PSERVER_URL = process.env.REACT_APP_ENDPOINT;

const requests = {
  getPickDropFailConfig: () => HTTPGet(`${PSERVER_URL}/m/config/task_fail_reasons`),
  getFrontendBlockedConfig: () => HTTPGet(`${PSERVER_URL}/m/config/disabled_frontends`),
  getServiceableRegion: () => HTTPGet(`${PSERVER_URL}/m/user/serviceable_regions`),
  getWareHouses: (region_code) => HTTPGet(`${PSERVER_URL}/m/admin/ops/warehouses?region=${region_code}`),
}

export default requests;
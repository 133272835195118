import { createSlice } from "@reduxjs/toolkit";

export interface WebsocketConnectionStateInterface {
  connected: boolean,
  errored: boolean,
  connecting: boolean,
  errorMessage: string,
  initalizing: number,
}

const initialState: WebsocketConnectionStateInterface = {
  connected: false,
  errored: false,
  connecting: true,
  errorMessage: "",
  initalizing: 0,
};

const websocketConnection = createSlice({
  name: "websocketConnection",
  initialState: initialState,
  reducers: {
    setConnected: (state) => {
      state.connected = true;
      state.connecting = false;
    },
    setDisconnected: (state) => {
      state.connected = false;
      state.connecting = false;
    },
    setError: (state, action: { payload: string }) => {
      state.connecting = false;
      state.errored = true;
      state.errorMessage = action.payload;
    },
    setConnecting: (state) => {
      state.connecting = true;
    },
    resetInitializerState: (state) => {
      state.initalizing = 0;
    },
    incrementInitializerState: (state) => {
      state.initalizing += 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setConnected,
  setDisconnected,
  setConnecting,
  setError,
  resetInitializerState,
  incrementInitializerState,
} = websocketConnection.actions;
export const webSocketSlice = (state) => state.websocketConnection;
export default websocketConnection.reducer;

import { Button, makeStyles, IconButton } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { theme } from "styles/theme"
import { getQueryParamList, unsetQueryParam, updateQueryParam, useQueryParams } from "utils/history";
import { SIDEBAR_TYPE_AGENTS } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  centerTopParentContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: theme.spacing(3),
    margin: '0 auto',
    maxWidth: 360,
    background: '#fff',
    zIndex: 1,
    boxShadow: "1px 3px 3px #aaa",
    borderRadius: 4
  },
})
function CenterTopLayout() {
  const classes = styles();
  const history = useHistory();
  const query = useQueryParams();

  const [selectedOrderIDs, setSelectedOrderIDs] = useState<string[]>([])

  useEffect(() => {
    const queryselectedOrderIDs = getQueryParamList(ORDERS_SELECTED_QUERY_PARAM)
    if (queryselectedOrderIDs.toString() !== selectedOrderIDs.toString()) {
      setSelectedOrderIDs(getQueryParamList(ORDERS_SELECTED_QUERY_PARAM));
    }
  }, [query]);

  if (!selectedOrderIDs.length || query.get(SIDEBAR_QUERY_PARAM)) {
    return <></>
  }
  return (
    <div className={`${classes.centerTopParentContainer} text-center d-flex justify-space-between`}>
       <div className="font-lg mv-auto pl-2">{selectedOrderIDs.length} order{selectedOrderIDs.length > 1 ? 's' : ''} selected</div>
       <div>
          <Button onClick={() => history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS))}>Assign</Button>
          <IconButton style={{color: 'red'}} onClick={() => history.replace(unsetQueryParam(ORDERS_SELECTED_QUERY_PARAM))}><CancelIcon /></IconButton>
       </div>
    </div>
  )
}

export default CenterTopLayout

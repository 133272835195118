import { SnackbarProvider, useSnackbar } from "notistack";

import store from "../../states";

import { Provider, useDispatch } from "react-redux";
import DeckView from "./layouts/DeckView";
import RightSidebar from "./layouts/right-sidebar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import DeckLayers from "./layouts/deck-layers";

function Nucleus() {
  const dispatch = useDispatch();
  const snackBar = useSnackbar();
  const queryClient = new QueryClient()

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3} autoHideDuration={5000} dense>
          {<DeckView>
            <DeckLayers />
            <RightSidebar />
          </DeckView>}
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}

export default Nucleus;

import { ArcLayer } from "@deck.gl/layers";

const agentHomeArcLayer = (
  data,
  visible = true,
  id = "agent-home-arc-layer",
  prominent = true
) => {
  return new ArcLayer({
    id,
    data,
    pickable: true,
    getWidth: 7,
    getHeight: 0.5,
    visible: visible,
    autoHighlight: true,
    // TODO - When order is in WH_DROP, the pick location should be WH
    getSourcePosition: (d: any) => [d.lastKnownLocation.lng, d.lastKnownLocation.lat],
    getTargetPosition: (d: any) => [d.homeAddress.coordinates.lng, d.homeAddress.coordinates.lat],
    getSize: (d: any) => 5,
    getSourceColor: prominent ? [0, 0, 0, 0] : [0, 180, 0, 60],
    getTargetColor: prominent ? [0, 0, 0, 255] : [0, 100, 180, 60],
  });
};

export default agentHomeArcLayer;

export const REGION_QUERY_PARAM = 'region'

export const REGIONS = [
     {
        regionCode: 0,
        name: "BENGALURU",
        abbr: "BNG",
        latLng: { lat: 12.9085315, lng: 77.597675 }
    }, {
        regionCode: 4,
        name: "HYDERABAD",
        abbr: "HYD",
        latLng: { lat: 17.397821207326, lng: 78.475341796875 }
    }, {
        regionCode: 5,
        name: "CHENNAI",
        abbr: "CHN",
        latLng: { lat: 13.082680892944, lng: 80.270767211914 }
    }
] as Array<{
    regionCode: number;
    name: string;
    abbr: string;
    latLng: { lat: number, lng: number }
} >
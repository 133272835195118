import agentDoNotAssign from 'assets/new_icons/agents/state/status/do_not_assign.svg';
import normalIcon from 'assets/new_icons/agents/state/status/normal.svg';

const agentStatusIcon = (dontAssign) => {
    if (dontAssign) {
        return agentDoNotAssign;
    }
    return normalIcon;
};

export default agentStatusIcon;
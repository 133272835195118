import React from 'react'
import OrderIconLayer from './order-icon-layer'

function ordersLayer() {
  return (
    <>
    <OrderIconLayer/>
    </>
  )
}

export default ordersLayer
export const allowInvertFilters = ["business_code"]
export const allowMultiSelectOptions = ["business_code"]
export const sortedFilters = ["business_code"]
export const ORDER_SEARCH_KEYS = [
    "sender.name",
    "sender.mobileNumber",
    "receiver.name",
    "receiver.mobileNumber",
    "orderID",
  ];
  
import { InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { unsetQueryParam, updateQueryParam, useQueryParams } from "utils/history";
import { useHistory } from "react-router";
import { updateOrdersWithFilter } from "apps/ops-dashboard/services/orders/filters";
import { ORDER_SEARCH_QUERY_PARAM } from "global-constants/orders/query_params";


const OrdersSearch = () => {
  const dispatch = useDispatch();

  const query = useQueryParams();
  const history = useHistory();
  const [filterString, setFilterString] = useState("");

  const onSearchChange = (e) => {
    if (e.target.value !== "") {
      history.replace(
        updateQueryParam(ORDER_SEARCH_QUERY_PARAM, e.target.value)
      );
    } else {
      history.replace(unsetQueryParam(ORDER_SEARCH_QUERY_PARAM));
    }
  };

  const unsetSearchParam = () => {
    history.replace(unsetQueryParam(ORDER_SEARCH_QUERY_PARAM));
  };

  useEffect(() => {
    const searchFilterString = query.get(ORDER_SEARCH_QUERY_PARAM) || "";
    if (filterString === searchFilterString) {
      return;
    }
    setFilterString(searchFilterString);
    dispatch(updateOrdersWithFilter());
  }, [query]);

  return (
    <div className={"ph-2 w-100"}>
      <TextField
        id="search-orders"
        className={""}
        fullWidth
        size="small"
        placeholder="Search by id, name, phone"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: query.get(ORDER_SEARCH_QUERY_PARAM) ? (
            <InputAdornment
              onClick={unsetSearchParam}
              position="start"
              style={{ cursor: "pointer" }}
            >
              <CloseIcon />
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
        variant="outlined"
        onChange={onSearchChange}
        value={query.get(ORDER_SEARCH_QUERY_PARAM) || ""}
      />
    </div>
  );
};

export default OrdersSearch;

import { makeStyles } from "@material-ui/core";
import SelectedOrders from "apps/ops-dashboard/components/orders/SelectedOrders";
import { updateAgentsWithFilter } from "apps/ops-dashboard/services/agents/filters";
import { agentsDataSlice } from "states/data/agents";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQueryParamList, useQueryParams } from "utils/history";
import Header from "../header";
import AgentFilterOnDuty from "./AgentFilterOnDuty";
import AgentSearch from "./AgentSearch";
import AllAgents from "./AllAgents";
import { AGENT_SHOW_ALL_DUTY_MODE } from "global-constants/agents/query_params";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  sideBar: {
    width: 360,
  },
});

function AgentsSideBar() {
  const query = useQueryParams();
  const classes = styles();
  const dispatch = useDispatch()
  const agentsData = useSelector(agentsDataSlice);

  const [allAgents, setAllAgents] = useState(
    query.get(AGENT_SHOW_ALL_DUTY_MODE)
  );
  
  useEffect(() => {
    const mode = query.get(AGENT_SHOW_ALL_DUTY_MODE);
    if (allAgents === mode) return;
    setAllAgents(mode);
    dispatch(updateAgentsWithFilter())
  }, [query]);

  return (
    <div className={classes.sideBar}>
      <Header title="Agents" />
      {query.get(ORDERS_SELECTED_QUERY_PARAM) ? <SelectedOrders /> : ""}
      <div className="mt-2"></div>
      <AgentSearch />
      <AgentFilterOnDuty />
      <div className="mt-2" />
      <AllAgents agents={agentsData} />
    </div>
  );
}

export default AgentsSideBar;

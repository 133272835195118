import APIReq from 'services/api'
import { initAuth, setAuthenticated, unsetAuth } from 'states/auth'



const adminAuth = () => (dispatch) => {
  APIReq.auth.isAdmin()
    .then((response) => {
      if (response.data?.operations?.manager) {
        dispatch(setAuthenticated({ email: response.data.email }))
      }
      else {
        dispatch(unsetAuth())
      }
    })
    .catch((e) => {
      dispatch(unsetAuth())
    })
    .finally(() => dispatch(initAuth()))
}

export default adminAuth;
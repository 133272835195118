import { SnackbarProvider, useSnackbar } from "notistack";

import store from "../../states";

import "./index.scss";
import { Provider, useDispatch, useSelector } from "react-redux";
import DeckLayers from "./layouts/deck-layers";
import FilterBar from "./layouts/filter-bar";
import RightSidebar from "./layouts/right-sidebar";
import InfoWindow from "./layouts/info-window";
import ConnectToSockets from "./websockets";
import DeckView from "./layouts/DeckView";
import { setShiftKeyPressed, unsetShiftKeyPressed } from "../../states/keypress";
import { useEffect, useState } from "react";
import NebulaLayout from "./layouts/nebula";
import FullScreenOverlays from "./layouts/full-screen-overlays";
import CenterTopLayout from "./layouts/center-top";
import { getFrontendBlockedConfigData, getServiceableRegionConfig, getTaskFailConfig, getWareHousesConfig } from "./services/configs";
import UserAvatar from "./layouts/full-screen-overlays/UserAvatar";
import RegionSelect from "./layouts/select-region";
import { serviceableConfigSlice } from "states/data/configs";
import { updateOrdersWithFilter } from "./services/orders/filters";
import { Loader, Message } from "rsuite";
import QuadSelect from "./layouts/quad-select";
import { authDataSlice } from "states/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import NucleusStatus from "./layouts/nucleus-status";

const DocumentKeyPresses = () => {

  const dispatch = useDispatch()

  const keyPressed = (e) => {
    if (e.key === "Shift") {
      dispatch(setShiftKeyPressed())
    }
  };

  const keyRemoved = (e) => {
    if (e.key === "Shift") {
      dispatch(unsetShiftKeyPressed())
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPressed);
    document.addEventListener("keyup", keyRemoved);
    setInterval(() => {
      dispatch(updateOrdersWithFilter());
    }, 1000 * 60 * 1);
    return () => {
      document.removeEventListener("keydown", keyPressed);
      document.removeEventListener("keyup", keyRemoved);
    };
  }, []);

  return <></>
}

/* Load all the configs that will be required by
   this app and set it in the configs state.
   If any fails, there will be a persistent snackbar
*/
const LoadConfigs = () => {
  const dispatch = useDispatch();
  const snackBar = useSnackbar()

  const enqueFailSnackBar = (message: string) => {
    snackBar.enqueueSnackbar(message, {
      persist: true,
      preventDuplicate: true,
      variant: "error",
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    })
  }

  useEffect(() => {
    dispatch(getTaskFailConfig(null, () => enqueFailSnackBar("Error loading pick/drop failed reasons")))
  }, [])
  return <></>
}

function OPSDashboard() {
  const regionsConfig = useSelector(serviceableConfigSlice);
  const [preConfigLoaded, setPreConfigLoaded] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState("Loading configs...")
  const authData = useSelector(authDataSlice);
  const dispatch = useDispatch();
  const snackBar = useSnackbar();
  const queryClient = new QueryClient()
  const [frontendDisabled, setFrontendDisabled] = useState(false);
  const [frontendDisabledMessage, setFrontendDisabledMessage] = useState("")

  const enqueFailSnackBar = (message: string) => {
    snackBar.enqueueSnackbar(message, {
      persist: true,
      preventDuplicate: true,
      variant: "error",
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    })
  }

  const loadAllPreConfigs = () => {
    setLoadingInfo("Loading serviceable regions...")
    dispatch(getServiceableRegionConfig(null, () => console.error("Error loading serviceable regions")))
  }

  useEffect(() => {
    loadAllPreConfigs()
    getFrontendBlockedConfigData((data)=>{
      if (data.data && data.data[process.env.REACT_APP_ENVIRONMENT||""]) {
        const disabled = data.data[process.env.REACT_APP_ENVIRONMENT||""].disabled || false
        const message = data.data[process.env.REACT_APP_ENVIRONMENT||""].message || ""
        const exceptions = data.data[process.env.REACT_APP_ENVIRONMENT||""].exceptions || []
        if (disabled && !exceptions.includes(authData.adminEmail)) {
          setFrontendDisabled(true)
          setFrontendDisabledMessage(message)
        }
      }
    })
  }, [])

  useEffect(() => { 
    // add all the pre-configs that has to load over here
    let allPreConfigsLoaded = true
    if (!regionsConfig.length) {
      allPreConfigsLoaded = false
    }
    if (allPreConfigsLoaded) {
      const regionCodes = regionsConfig.map((region) => region.code)
      setLoadingInfo("Loading warehouses...")
      dispatch(getWareHousesConfig(regionCodes,
         ()=>setPreConfigLoaded(true),
         () => enqueFailSnackBar("Error loading warehouses")))
    }
    // allPreConfigsLoaded && setPreConfigLoaded(true)
  }, [regionsConfig])

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3} autoHideDuration={5000} dense>
          {!frontendDisabled ? <>{preConfigLoaded ? <DeckView>
            <DocumentKeyPresses />
            <ConnectToSockets />
            <LoadConfigs />
            <DeckLayers />
            <FilterBar />
            <RightSidebar />
            <CenterTopLayout />
            <NebulaLayout />
            <InfoWindow />
            <UserAvatar />
            <FullScreenOverlays />
            <RegionSelect />
            {/* <NucleusStatus /> */}
            <QuadSelect />
          </DeckView> : <Loader size="lg" backdrop content={loadingInfo} vertical />
          }</>:  <Message type="error" description={frontendDisabledMessage} showIcon header="Blocked"/>}
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}

export default OPSDashboard;

import {
  makeStyles,
} from "@material-ui/core";


import { theme } from "styles/theme";
import SearchBar from "./SearchBar";
import FilterSearch from "./FilterSearch";
import { updateOrdersWithFilter } from "apps/ops-dashboard/services/orders/filters";
import { useEffect, useRef } from "react";
import { getQueryParam, getQueryParamJSONArray, useQueryParams } from "utils/history";
import { ordersDataSlice } from "states/data/orders";
import { useDispatch, useSelector } from "react-redux";
import FilterChips from "./FilterChips";
import { FilterInterface } from "types/filter-types";
import { ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { AGENT_GENERAL_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { updateAgentsWithFilter } from "apps/ops-dashboard/services/agents/filters";

const styles = makeStyles({
  filterContainer: {
    position: "absolute",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "280px",

  },
})

export const FILTER_BAR_OPEN_QUERY_PARAM = 'sbOpen'

const FilterBar = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const query = useQueryParams()
  const orderFilterQuery = useRef<FilterInterface[]>([])
  const agentFilterQuery = useRef<FilterInterface[]>([])

  useEffect(() => {
    if (!(getQueryParam(ORDER_FILTER_QUERY_PARAM) === JSON.stringify(orderFilterQuery.current))) {
      orderFilterQuery.current = getQueryParamJSONArray(ORDER_FILTER_QUERY_PARAM)
      dispatch(updateOrdersWithFilter())
    }
    if (!(getQueryParam(AGENT_GENERAL_FILTER_QUERY_PARAM) === JSON.stringify(agentFilterQuery.current))) {
      agentFilterQuery.current = getQueryParamJSONArray(AGENT_GENERAL_FILTER_QUERY_PARAM)
      dispatch(updateAgentsWithFilter())
    }
  }, [query])

  return (
    <div className={classes.filterContainer}>
      <SearchBar />
      <FilterSearch />
      <FilterChips />
    </div>
  )
};

export default FilterBar;

import { Button, Divider, Paper } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { updateOrdersWithFilter } from 'apps/ops-dashboard/services/orders/filters'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateQueryParam, useQueryParams } from 'utils/history'
import { ORDERS_QUADRANT_QUERY_PARAM, ORDER_ARC_LAYER_QUERY_PARAM } from 'global-constants/orders/query_params'
import { SIDEBAR_QUERY_PARAM } from 'global-constants/general'

function QuadSelect() {

  const query = useQueryParams()
  const [quadrant, setQuadrant] = React.useState("0")
  const [hideQuadrant, setHideQuadrant] = React.useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const newQuadrant = query.get(ORDERS_QUADRANT_QUERY_PARAM) || "0"
    setHideQuadrant(!(+(query.get(ORDER_ARC_LAYER_QUERY_PARAM) || 0) === 1))
    setQuadrant(newQuadrant)

  }, [query])

  const handleQuadrantChange = (newQuadrant) => {
    if (newQuadrant === +quadrant) {
      newQuadrant = "0"
    }
    if (newQuadrant !== null) {
      history.replace(updateQueryParam(ORDERS_QUADRANT_QUERY_PARAM, newQuadrant))
      dispatch(updateOrdersWithFilter());
    }
  }

  const QuadrantButton = ({ quadrantNumber, quadrantName }) => {
    return (<IconButton 
      className="m-1"
      style={{
        borderRadius: 0,
        border: quadrant === quadrantNumber.toString() ? "2px solid white" : "none",
        padding: "0.5rem 1rem",
        fontSize: "1rem",
      }}
      onClick={() => handleQuadrantChange(quadrantNumber)}>
      <span>{quadrantName}</span>
    </IconButton>)
  }

  if (hideQuadrant) {
    return <></>
  }

  return (
    <Paper style={{
      position: "fixed",
      right: query.get(SIDEBAR_QUERY_PARAM) ? 368 : 8,
      bottom: 100,
      background: "rgba(0,0,0,0.1)",
      zIndex: 99,
    }}>
      <QuadrantButton quadrantNumber={4} quadrantName={"NW"}/>
      <QuadrantButton quadrantNumber={1} quadrantName={"NE"}/>
      <Divider orientation="vertical" flexItem />
      <QuadrantButton quadrantNumber={3} quadrantName={"SW"}/>
      <QuadrantButton quadrantNumber={2} quadrantName={"SE"}/>
    </Paper>
  )
}

export default QuadSelect

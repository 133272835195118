import { useLocation } from "react-router-dom";

const getParam = () => new URLSearchParams(window.location.search);

const getQuery = (queryName) => {
  let currentUrlParams = getParam()
  return currentUrlParams.get(queryName)
}

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
}

export const queryToList = (q: string) => {
  q = q.trimLeft()
  q =q.trimRight()
  if (q === "") {
    return []
  }
  return q.split(";")
}

export const updateQueryParam = (queryName: string, value: string) => {
  let currentUrlParams = getParam()
  currentUrlParams.set(queryName, value);
  return `${window.location.pathname}?${currentUrlParams.toString()}`
}

export const unsetQueryParam = (queryName: string) => {
  let currentUrlParams = getParam()
  currentUrlParams.delete(queryName);
  return `${window.location.pathname}?${currentUrlParams.toString()}`
}

export const setQueryParam = (queryName: string) => {
  return updateQueryParam(queryName, "1")
}

export const getQueryParam = (queryName: string) => {
  return getQuery(queryName)
}

export const getQueryParamList = (queryName: string, hook=false) => {
  let q = (getQuery(queryName) || "")
  return queryToList(q)
}

export const getQueryParamJSONObject = (queryName: string) => {
  let q = (getQuery(queryName) || "{}")
  return JSON.parse(q)
}

export const getQueryParamJSONArray = (queryName: string): Array<any> => {
  let q = (getQuery(queryName) || "[]")
  return JSON.parse(q)
}

export const updateQueryParamJSONObject = (queryName: string, jsonObject: Object) => {
  return updateQueryParam(queryName, JSON.stringify(jsonObject))
}

export const updateQueryParamJSONList = (queryName: string, jsonObject: Array<any>) => {
  return updateQueryParam(queryName, JSON.stringify(jsonObject))
}


export const toggleQueryParam = (queryName: string) => {
  const query = getQuery(queryName)
  return query ? unsetQueryParam(queryName) : updateQueryParam(queryName, "1")
}
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  makeStyles,
  Switch,
} from "@material-ui/core";
import AgentInfo from "apps/ops-dashboard/components/agents/AgentInfo";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import APIReq from "services/api";
import { AgentsInterface } from "states/data/agents";
import {
  getQueryParam,
  unsetQueryParam,
  updateQueryParam,
  useQueryParams,
} from "utils/history";
import DatePicker from "react-datepicker";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Header from "../header";
import {
  DDMM,
  DDMMTime,
  DDMMYYTime,
  timeTodayStart,
  timeTomorrowStart,
} from "utils/datetime";
import { OrdersInterface } from "states/data/orders";
import {
  AgentTaskLogItemInterface,
  resetTaskLogData,
  setOrderDetailsList,
  setPolyline,
  setTaskLog,
} from "states/data/task-logs";
import { useDispatch, useSelector } from "react-redux";
import { AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_AGENT_DATA } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";

const styles = makeStyles({
  accordianDetails: {
    display: "block",
    padding: 0,
  },
  accordianRoot: {
    boxShadow: "none",
    padding: 0,
  },
  taskLogContainer: {},
});

const QUERY_START_TS = "ts_0";
const QUERY_END_TS = "ts_1";

function AgentTaskLogHistory() {
  const classes = styles();
  const selectedAgent = useQueryParams().get(AGENT_SELECTED_QUERY_PARAM);

  const history = useHistory();
  const dispatch = useDispatch();

  const agentTaskLogs = useSelector(
    (state: any) => state.taskLogData.agentTaskLogs
  );
  const orderDetailsList = useSelector(
    (state: any) => state.taskLogData.orderDetailsList
  );
  // const polyline = useSelector((state: any) => state.taskLogData.polyline)

  const setResponse = (data: {
    taskLogs: AgentTaskLogItemInterface[];
    orderDetailList: OrdersInterface[];
    polyline: string;
  }) => {
    dispatch(setTaskLog(data.taskLogs));
    dispatch(setOrderDetailsList(data.orderDetailList));
    dispatch(setPolyline(data.polyline));
  };

  const [selectedAgentData, setSelectedAgentData] =
    useState<AgentsInterface | null>(null);

  const [startDate, setStartDate] = useState<Date>(new Date(0));
  const [endDate, setEndDate] = useState<Date>(new Date(0));
  const [showEarningsOnly, setShowEarningsOnly] = useState(false);

  const getTaskLogHistory = () => {
    if (!(startDate && endDate)) {
      return;
    }
    selectedAgent &&
      APIReq.agents
        .agentTaskLogHistory(
          selectedAgent,
          startDate.getTime(),
          endDate.getTime()
        )
        .then((res) => {
          const urlSearchParams = new URLSearchParams(
            res.config.url?.split("?")[1]
          );
          const params = Object.fromEntries(urlSearchParams.entries());
          if (
            parseInt(params.start) === startDate.getTime() &&
            parseInt(params.end) === endDate.getTime()
          ) {
            setResponse(res.data.data);
          }
        })
        .catch(console.log);
  };

  useEffect(() => {
    selectedAgent &&
      APIReq.agents
        .agentDetails(selectedAgent)
        .then((res) => {
          setSelectedAgentData(res.data.data);
        })
        .catch(console.log);

    // Set date from the query parameter
    let start =
      parseInt(getQueryParam(QUERY_START_TS) || "0") || timeTodayStart();
    let end =
      parseInt(getQueryParam(QUERY_END_TS) || "0") || timeTomorrowStart();

    if (
      start &&
      start !== startDate.getTime() &&
      end &&
      end !== endDate.getTime()
    ) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    }

  }, []);

  useEffect(() => {
    getTaskLogHistory();
  }, [startDate, endDate]);

  const cancel = () => {
    history.replace(
      updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENT_DATA)
    );
    history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM));
    history.replace(unsetQueryParam(QUERY_START_TS));
    history.replace(unsetQueryParam(QUERY_END_TS));
  };

  const renderActionButtons = () => {
    return (
      <>
        <div className="d-flex justify-right">
          <div className="t-right ph-2">
            <Button onClick={cancel} style={{ color: "red" }}>
              Cancel
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderDateRange = () => {
    return (
      <div
        className="d-flex justify-space-between m-2 ph-2"
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "#eaeaea",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={showEarningsOnly}
              onChange={(e) => setShowEarningsOnly(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Earnings only"
        />

        <DatePicker
          selected={startDate}
          onChange={(date) => {
            if (date[0] && date[1]) {
              history.replace(
                updateQueryParam(QUERY_START_TS, date[0].getTime())
              );
              history.replace(
                updateQueryParam(QUERY_END_TS, date[1].getTime())
              );
            }

            setStartDate(date[0]);
            setEndDate(date[1]);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          customInput={
            <Button className="w-100">
              {DDMM(startDate?.getTime())} - {DDMM(endDate?.getTime())}
            </Button>
          }
        />
      </div>
    );
  };

  const logElement = (taskLog, i) => (
    <div className={`${classes.taskLogContainer} ph-2 mv-1`} key={i}>
      <div className="d-flex justify-space-between pv-1">
        <h5 className="m-0">{taskLog.orderStatus}</h5>
        {DDMMYYTime(taskLog.timestamp)}
      </div>

      {taskLog.orderID.length > 1 ? (
        <Accordion
          square
          classes={{
            root: classes.accordianRoot,
            expanded: classes.accordianRoot,
          }}
          disabled={taskLog.orderID.length < 2}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              root: classes.accordianRoot,
            }}
          >
            {taskLog.orderID[0]}
          </AccordionSummary>
          <AccordionDetails className={`${classes.accordianDetails}`}>
            {taskLog.orderID.map((x, i) => (
              <div key={i}>{i !== 0 && x}</div>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <p>Order ID - {taskLog.orderID[0]}</p>
      )}
      <div className="d-flex justify-space-between pv-1">
        <div>
          <b className="m-0">Earnings - {taskLog.earnings || 0}</b>
        </div>
        <div>
          <b className="m-0">Distance - {taskLog.distance || "Not measured"}</b>
        </div>
      </div>
      <hr />
    </div>
  );

  const renderLogs = () => {
    if (!agentTaskLogs.length) {
      return <h4 className="t-center">No logs found</h4>;
    }

    return agentTaskLogs.map(
      (taskLog, i) =>
        (showEarningsOnly && taskLog.earnings && logElement(taskLog, i)) ||
        (!showEarningsOnly && logElement(taskLog, i))
    );
  };

  return (
    <div>
      <Header title="Task Log history" noClose />
      <div className="ph-2">
        {selectedAgentData && <AgentInfo agentData={selectedAgentData} />}
        {renderActionButtons()}
      </div>
      {renderDateRange()}
      {renderLogs()}
    </div>
  );
}

export default AgentTaskLogHistory;

import { IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory } from "react-router-dom";
import { unsetQueryParam } from "utils/history";

const styles = makeStyles({
  heading: {
    margin: "auto 0",
  },
});

const Header = ({ title, noClose = false }) => {
  const classes = styles();

  const history = useHistory();

  return (
    <div className="d-flex justify-space-between pv-1 ph-2">
      <Typography variant="h5" className={classes.heading}>
        {title}
      </Typography>
      {!noClose && (
        <IconButton
          aria-label="delete"
          onClick={() => history.replace(unsetQueryParam("rsidebar"))}
        >
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Header;

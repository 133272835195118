import { Button } from "@material-ui/core";
import { unsetSchedulingOrderID } from "states/data/orders";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import APIReq from "services/api";

function RescheduleOrder() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch()

  const [startDate, setStartDate] = useState<Date>(new Date());

  const schedulingOrderID = useSelector(
    (state: any) => state.ordersData.schedulingOrderID
  );

  const ref = useRef<DatePicker>();

  const closeDatePicker = () => {
    dispatch(unsetSchedulingOrderID())
  };

  const rescheduleOrder = () => {
    if (
      !window.confirm(
        `Reschedule order ${schedulingOrderID} on ${startDate.toLocaleString()}`
      )
    ) {
      return;
    }
    APIReq.orders
      .rescheduleOrder(schedulingOrderID, startDate.getTime())
      .then((res) => {
        dispatch(unsetSchedulingOrderID())
        enqueueSnackbar(res.data.data, {
          variant: "success",
        })
      })
      .catch((err) =>
        enqueueSnackbar(`Error rescheduling order ${schedulingOrderID}`, {
          variant: "error",
        })
      );
  };

  if (schedulingOrderID !== "") {
    return (
      <DatePicker
        ref={ref}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        inline
        showTimeInput
        withPortal
        shouldCloseOnSelect={false}
        customInput={<Button>Reschedule</Button>}
      >
        <div className="w-100 t-right">
          <Button onClick={closeDatePicker} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button onClick={rescheduleOrder}>OK</Button>
        </div>
      </DatePicker>
    );
  }

  return <></>;
}

export default RescheduleOrder;

import { IconLayer } from "@deck.gl/layers";
import { shipTypeIcons } from "../utils/icons";

const selectedOrderIconLayer = (
  data,
  hoveredOrder,
  onOrderClick,
  visible = false
) => {

  return new IconLayer({
    id: "all-order-icon-layer-selected",
    data,
    pickable: true,
    getIcon: (d: any) => shipTypeIcons(d.ship.type),
    visible: visible,
    sizeScale: 50,
    sizeUnits: "meters",
    sizeMinPixels: 32 ,
    sizeMaxPixels: 54,
    // TODO - When order is in WH_DROP, the pick location should be WH
    getPosition: (d: any) => [d.pickLocation.lng, d.pickLocation.lat],
    getSize: (d: any) => 5,
    getColor: [255, 255, 255, 255],
    onHover: hoveredOrder,
    onClick: onOrderClick,
  });
};


export default selectedOrderIconLayer;

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles,
  Badge,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Virtuoso } from "react-virtuoso";
import React from "react";
import OrdersCard from "apps/ops-dashboard/components/orders/OrdersCard";
import OrdersSearch from "./OrdersSearch";

const styles = makeStyles({
  accordianDetails: {
    display: "block",
    padding: 0,
  },
});

const AllOrders = ({ orders, onOrderSelect }) => {
  const classes = styles();

  return (
    <Accordion square defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Badge badgeContent={orders.length} max={9999} color="primary">
          <Typography variant="subtitle1">All orders</Typography>
        </Badge>
      </AccordionSummary>
      <AccordionDetails className={classes.accordianDetails}>
        <OrdersSearch />
        <div className="mb-2"></div>
        <Virtuoso
          style={{ height: "80vh" }}
          totalCount={orders.length}
          itemContent={(index) => {
            return (
              <>
                <OrdersCard
                  orderDetails={orders[index]}
                  onOrderSelect={onOrderSelect}
                  actions
                />
              </>
            );
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AllOrders;

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { authDataSlice } from 'states/auth'
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
    userAvatar: {
        position: "absolute",
        right: 0,
        top: 16,
    },
})

function UserAvatar() {
    const classes = styles();
    const authData = useSelector(authDataSlice)
    const [showEmail, setShowEmail] = useState(false)
    if(authData){
    return (
            <Button className={classes.userAvatar} variant="contained" color="primary" onClick={()=>setShowEmail(!showEmail)}>
            {showEmail ? authData?.adminEmail : authData?.adminEmail[0]}
            </Button>
        )
    } else {
      return (<></>)
    }
}

export default UserAvatar

import { AGENT_GENERAL_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { FilterOption, FilterInterface, FilterOptionWithFilterType } from "types/filter-types";
import { getQueryParam } from "utils/history";
import { getBusinessFilters, orderFilters } from "../orders/filters";
import { agentsFilters } from "../agents/filters";

/**
 *
 * @param processedFilter Current set of filter options
 * @param query Search filters using this query
 * @returns new set of filter options
 */
export const performFilterSearch = (
    processedFilter: FilterOptionWithFilterType[],
    query: string
  ) => {
    const lCQuery = query.toLocaleLowerCase();
  
    if (lCQuery === "") {
      return processedFilter;
    }
  
    const newFilter = processedFilter.map((filterOption) => {
      const f = { ...filterOption };
      f.items = f.items.filter((x) => {
        return x.name.toLocaleLowerCase().indexOf(lCQuery) > -1;
      });
      return f;
    });
  
    return newFilter;
  }
  
/**
 * 
 * @param key Key of the filter option to be removed
 * @returns stringified version of the new filter options
 * 
 * @description Removes the filter option with the given key from the filter options
 */
export const clearOrderFilterQuery = (key): string => {
    const orderFilters: FilterInterface[] = JSON.parse(
      getQueryParam(ORDER_FILTER_QUERY_PARAM) || "[]"
    ).filter(x => x.key !== key)

    return JSON.stringify([...orderFilters]);
  }

/**
 * 
 * @param key Key of the filter option to be removed
 * @returns stringified version of the new filter options
 * 
 * @description Removes the filter option with the given key from the filter options
 */
export const clearAgentFilterQuery = (key): string => {
  
  const agentFilters: FilterInterface[] = JSON.parse(
      getQueryParam(AGENT_GENERAL_FILTER_QUERY_PARAM) || "[]"
  ).filter((f) => f.key !== key);

  return JSON.stringify([...agentFilters]);
}

export const prepareFilter = (businesses_details?): FilterOptionWithFilterType[] => {
    const updatedOrderFilters = orderFilters;
    updatedOrderFilters.business_code.items = getBusinessFilters(businesses_details);
    const updatedOrderFiltersList = Object.keys(updatedOrderFilters).map((key) => {
      return { ...updatedOrderFilters[key], ...{key: key, filterOn: 'order'} } as FilterOptionWithFilterType;
    });
    const updatedAgentFilters = agentsFilters;
    const updatedAgentFiltersList = Object.keys(updatedAgentFilters).map((key) => {
      return { ...updatedAgentFilters[key], ...{key: key, filterOn: 'agent'} } as FilterOptionWithFilterType;
    });

    return [...updatedOrderFiltersList, ...updatedAgentFiltersList];

  }


/**
 * Thunk - Filters can be applied in the form of ['key', 'value'] pair on orders data, this function
 * takes care of setting the state of filter and performing the filter if updateOrdersState
 * is true
 * @param key filter on key dotnotated
 * @param value value to filter on
 * @param properties Extra set of properties to apply to the filter
 * @param updateOrdersState should the order list state be updated
 * @returns null
 */
export const generateFilterQuery = (key, value, filterOn: 'order'|'agent'): string => {
  const filterQueryParam = filterOn === 'order' ? ORDER_FILTER_QUERY_PARAM : AGENT_GENERAL_FILTER_QUERY_PARAM;
  const filterData = filterOn === 'order' ? orderFilters : agentsFilters;
  const filters: FilterInterface[] = JSON.parse(
    getQueryParam(filterQueryParam) || "[]"
  );
  // Find if the filter key exists
  const i = filters.findIndex((x) => x.key === key);

  // When no object with the given key exist
  if (i === -1) {
    filters.push({
      key: key,
      values: [value],
    });
  } else {
    // When object with key exists
    // Create a deep copy 
    const filterItem: FilterInterface = filters[i];

    // Only one option in radio can be selected, set to empty array if value is null
    if (filterData[key].elementType === "radio") {
      filterItem.values = value ? [value] : []
    } else {
      // If the value exists in the values key, remove it or else append it
      const filterValueIndex = filterItem.values.indexOf(value);
      if (filterValueIndex > -1) {
        filterItem.values.splice(filterValueIndex, 1);
      } else {
        filterItem.values.push(value);
      }
    }

    // Filters should not have an object with empty values
    if (!filterItem.values.length) {
      filters.splice(i, 1);
    } else {
      filters[i] = filterItem;
    }
  }
  return JSON.stringify(filters);
};
  

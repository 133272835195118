import { Chip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Badge, Panel } from "rsuite";
import { WhOrderQueueItem } from "states/data/agents";
import { warehousesConfigSlice } from "states/data/configs";

function WHOrders({
  whOrders,
  limit = 0,
  whDrop = false,
  whPick = false,
  removeOrderCallback = undefined,
}: {
  whOrders: Array<WhOrderQueueItem>;
  limit?: number;
  whDrop?: boolean;
  whPick?: boolean;
  removeOrderCallback?: (event) => any;
}) {
  let orders = whOrders ? [...whOrders].map(element => element.orders) : [];
  const whConfig = useSelector(warehousesConfigSlice);

  const getWhName = (whID: string) => {
    const wh = whConfig.find(x => x.warehouseID === whID);
    return wh ? wh.name : whID;
  };


  return (
    <div>
      <div className="d-flex align-center">
        <div className={`font-md pr-1`}>
          {whDrop ? "Warehouse Drops" : ""}
          {whPick ? "Warehouse Picks" : ""}
        </div>
        <Badge
          style={{ background: "gray" }}
          content={`${whOrders?.length} warehouses`}
        />
      </div>
      {orders.length ? (
        whOrders.map(whOrder => (
          // restrict hight using rsuite css
        <Panel bordered bodyFill  key={whOrder.warehouseID}>
        <div className={`m-1 d-flex flex-column`}>
          <div className={`font-sm pr-1`}>
            {getWhName(whOrder.warehouseID)}{"  "} <Badge
            style={{ background: "gray" }}
            content={`${whOrder.orders?.length} orders`}
          />
          </div>
          {whOrder.orders?.length ? (
            <div className={`mt-1 d-flex flex-wrap`}>
              {(whOrder.orders?.length?(limit?whOrder.orders.slice(0, limit): whOrder.orders): []).map((x, i) => (
                <Chip
                  key={i}
                  label={x.orderID.slice(-7)}
                  variant="outlined"
                  onDelete={removeOrderCallback ? () => removeOrderCallback(x) : undefined}
                />
              ))}{limit?" ...":""}
            </div>
          ) : (
            ""
          )}
          </div>
  </Panel>))
          ):( <div className={`font-sm`}>No orders</div>)}
    </div>
  );
}

export default WHOrders;

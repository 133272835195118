import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";
import { getQueryParam, updateQueryParam } from "utils/history";

export const generateOrderSelectQueryParam = (orderID) => {
  const selected = getQueryParam(ORDERS_SELECTED_QUERY_PARAM);
  let orderList = !selected || selected === "" ? [] : selected?.split(";");

  const selectedIndex = orderList.indexOf(orderID);

  if (selectedIndex > -1) {
    orderList.splice(selectedIndex, 1);
  } else {
    orderList.push(orderID);
  }

  return updateQueryParam(ORDERS_SELECTED_QUERY_PARAM, orderList.join(";"))
};

import { Button, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";
import { unsetQueryParam, updateQueryParam } from "utils/history";
import { SIDEBAR_TYPE_AGENTS } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const OrderAssignActions = () => {
  const history = useHistory()

  const onClearPressed = () => {
    history.replace(unsetQueryParam(ORDERS_SELECTED_QUERY_PARAM))
  }

  const onAssignClick = () => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS))
  }

  return (
    <div className={`d-flex justify-right ph-2`}>
      <Button onClick={onAssignClick}>Assign </Button>
      <IconButton onClick={onClearPressed} style={{color: 'red'}} aria-label="delete">
        <CloseIcon />
      </IconButton>
    </div>
    
  );
};

export default OrderAssignActions;

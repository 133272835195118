import { generateOrderSelectQueryParam } from "apps/ops-dashboard/services/orders/select-order";
import {
  OrderAssignStatInterface,
  ordersAssignStatSlice,
  ordersDataSlice,
  setHoveredOrder,
} from "states/data/orders";
import { setLayerOrders } from "states/deck.gl/layers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getQueryParam, getQueryParamList, useQueryParams } from "utils/history";
import allOrderIconLayer from "./layers/allOrderIconLayer";
import orderArcLayer from "./layers/orderArcLayer";
import selectedOrderIconLayer from "./layers/selectedOrderIconLayer";
import { HIDE_PACK_DETAILS_ON_ICONS_PARAM } from "global-constants/general";
import { ORDERS_SELECTED_QUERY_PARAM, ORDER_ARC_LAYER_QUERY_PARAM } from "global-constants/orders/query_params";

const OrdersIconLayer = (props) => {
  const history = useHistory();
  const query = useQueryParams();
  const dispatch = useDispatch();

  const ordersData = useSelector(ordersDataSlice);
  const origOrdersData = useSelector(
    (state: any) => state.ordersData.origOrdersData
  );
  const orderAssignStat: OrderAssignStatInterface = useSelector(
    ordersAssignStatSlice
  );

  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [showOrderArcLayer, setShowOrderArcLayer] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState<string[]>([]);
  const [inclSelectedBusiness, setinclSelectedBusiness] = useState(false)

  const shiftKeyPressed: boolean = useSelector(
    (state: any) => state.keyPressStates.shiftKeyPressed
  );

  const hidePackDetailsOnIcons = query.get(HIDE_PACK_DETAILS_ON_ICONS_PARAM)

  const onOrderClick = (e, f) => {
    if (orderAssignStat.assignMode) {
      return;
    }
    if (e?.object?.orderID) {
      history.replace(generateOrderSelectQueryParam(e?.object?.orderID));
    }
    dispatch(setHoveredOrder({ orderID: "", hoverInfo: {} }));
  };

  const onOrderHover = (info) => {
    dispatch(
      setHoveredOrder({
        orderID: info?.object?.orderID || "",
        hoverInfo: info,
      })
    );
  };

  // On any query change, make sure only selected orders change trigger causes changes
  useEffect(() => {
    const newSelectedOrders = getQueryParamList(ORDERS_SELECTED_QUERY_PARAM);
    const showArcLayer = !!getQueryParam(ORDER_ARC_LAYER_QUERY_PARAM);
    if (selectedOrders.toString() !== newSelectedOrders.toString()) {
      setSelectedOrders(newSelectedOrders);
    }
    if (showOrderArcLayer !== showArcLayer) {
      setShowOrderArcLayer(showArcLayer)
    }
  }, [query]);

  useEffect(() => {
    const ordersNotSelected = ordersData.filter(
      (x) => !selectedOrders.includes(x.orderID)
    );
    const ordersSelected = origOrdersData.filter((x) =>
      selectedOrders.includes(x.orderID)
    );

    const allLayer = allOrderIconLayer(
      ordersNotSelected,
      onOrderHover,
      onOrderClick,
      hidePackDetailsOnIcons || false,
      shiftKeyPressed || !selectedOrders.length
    );
    const allOrderArcLayer = orderArcLayer(
      ordersNotSelected,
      onOrderHover,
      onOrderClick,
      showOrderArcLayer && (shiftKeyPressed || !selectedOrders.length)
    );
    let orderArcLayerSelected: any;
    let selectedLayer: any;

    if (selectedOrders.length) {
      selectedLayer = selectedOrderIconLayer(
        ordersSelected,
        onOrderHover,
        onOrderClick,
        !shiftKeyPressed
      );
      orderArcLayerSelected = orderArcLayer(
        ordersSelected,
        onOrderHover,
        onOrderClick,
        showOrderArcLayer && !shiftKeyPressed,
        "order-arc-layer-selected"
      );
    }

    dispatch(
      setLayerOrders({
        allOrdersLayer: allLayer,
        selectedOrdersLayer: selectedLayer,
        arcOrderAll: allOrderArcLayer,
        arcOrderselected: orderArcLayerSelected
      })
    );
  }, [selectedOrders, ordersData, shiftKeyPressed, showOrderArcLayer, selectedBusinesses, inclSelectedBusiness]);

  return <></>;
};

export default OrdersIconLayer;

export const INVERT_FILTER_SELECTION = "inv_flt_sel"
export const ActiveOrderTimeline = "act_order_tm_line"
export const ORDER_ARC_LAYER_QUERY_PARAM = "view_o_arc"
export const SIDEBAR_TYPE_ORDERS = "orders";
export const SIDEBAR_TYPE_ORDER_TIMELINE = "view_order_tm_line"

export const AGENT_ORDER_OTP_GENERATION_MODE_QUERY_PARAM = "order_o";
export const AGENT_ORDER_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM = "orders_o";
export const AGENT_ORDER_OTP_GENERATION_MODE_ORDER_PICK = "order_p";
export const AGENT_ORDER_OTP_GENERATION_MODE_ORDER_DROP = "order_d";

export const AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM = "wh_o";
export const AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM = "whs_o";
export const AGENT_WH_OTP_GENERATION_MODE_WH_PICK = "wh_p";
export const AGENT_WH_OTP_GENERATION_MODE_WH_DROP = "wh_d";

export const ORDER_SEARCH_QUERY_PARAM = 'o_search'
export const ORDER_FILTER_QUERY_PARAM = 'o_filter'
export const ORDER_FILTER_SEARCH_QUERY_PARAM = 'o_filter'
export const ORDERS_SELECTED_QUERY_PARAM = 'o_selected'
export const ORDERS_SHOW_D2C_QUERY_PARAM = 'o_show_d2c'
export const ORDERS_QUADRANT_QUERY_PARAM = 'o_quad'


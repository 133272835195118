import { Checkbox, List } from '@material-ui/core'
import { updateAgentsWithFilter } from 'apps/ops-dashboard/services/agents/filters'
import { AGENT_SHOW_ALL_DUTY_MODE, AGENT_NUCLEUS_EXCLUDED, AGENT_FREE_AGENTS } from 'global-constants/agents/query_params'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CheckPicker, Popover } from 'rsuite'
import { setQueryParam, toggleQueryParam, unsetQueryParam, useQueryParams } from 'utils/history'

function AgentFilterOnDuty() {
  const query = useQueryParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const allAgentMode = query.get(AGENT_SHOW_ALL_DUTY_MODE)
  // const goHome = query.get(AGENT_GO_HOME)
  // const agentDoNotAssign = query.get(AGENT_DO_NOT_ASSIGN)
  const agentNucleusExcluded = query.get(AGENT_NUCLEUS_EXCLUDED)
  const freeAgents = query.get(AGENT_FREE_AGENTS)
  const [filterPickerValue, setFilterPickerValue] = useState([] as string[])

  const agentFilterData = [
    {
      label: 'Show All Agents',
      value: AGENT_SHOW_ALL_DUTY_MODE,
      checked: allAgentMode === '1',
    },
    // {
    //   label: 'Go Home',
    //   value: AGENT_GO_HOME,
    //   checked: goHome === '1',
    // },
    // {
    //   label: 'Do Not Assign',
    //   value: AGENT_DO_NOT_ASSIGN,
    //   checked: agentDoNotAssign === '1',
    // },
    {
      label: 'Nucleus Excluded Agents',
      value: AGENT_NUCLEUS_EXCLUDED,
      checked: agentNucleusExcluded === '1',
    },
    {
      label: 'Free Agents',
      value: AGENT_FREE_AGENTS,
      checked: freeAgents === '1',
    }
  ]

  useEffect(() => {
    if (allAgentMode === '1') {
      setFilterPickerValue(currentValue => [...currentValue, AGENT_SHOW_ALL_DUTY_MODE])
    }
    else {
      setFilterPickerValue(currentValue => currentValue.filter(value => value !== AGENT_SHOW_ALL_DUTY_MODE))
    }
    // if (goHome === '1') {
    //   setFilterPickerValue(currentValue => [...currentValue, AGENT_GO_HOME])
    // }
    // else {
    //   setFilterPickerValue(currentValue => currentValue.filter(value => value !== AGENT_GO_HOME))
    // }
    // if (agentDoNotAssign === '1') {
    //   setFilterPickerValue(currentValue => [...currentValue, AGENT_DO_NOT_ASSIGN])
    // }
    // else {
    //   setFilterPickerValue(currentValue => currentValue.filter(value => value !== AGENT_DO_NOT_ASSIGN))
    // }
    if (agentNucleusExcluded === '1') {
      setFilterPickerValue(currentValue => [...currentValue, AGENT_NUCLEUS_EXCLUDED])
    }
    else {
      setFilterPickerValue(currentValue => currentValue.filter(value => value !== AGENT_NUCLEUS_EXCLUDED))
    }
    if (freeAgents === '1') {
      setFilterPickerValue(currentValue => [...currentValue, AGENT_FREE_AGENTS])
    }
    else {
      setFilterPickerValue(currentValue => currentValue.filter(value => value !== AGENT_FREE_AGENTS))
    }

    dispatch(updateAgentsWithFilter())

  }, [allAgentMode
    // , goHome, agentDoNotAssign
    , agentNucleusExcluded, freeAgents])

  const setAllAgents = () => {
    history.replace(setQueryParam(AGENT_SHOW_ALL_DUTY_MODE))
  }

  const unsetAllAgents = () => {
    history.replace(unsetQueryParam(AGENT_SHOW_ALL_DUTY_MODE))
  }

  // const setGoHome = () => {
  //   history.replace(setQueryParam(AGENT_GO_HOME))
  // }

  // const unsetGoHome = () => {
  //   history.replace(unsetQueryParam(AGENT_GO_HOME))
  // }

  // const setDoNotAssign = () => {
  //   history.replace(setQueryParam(AGENT_DO_NOT_ASSIGN))
  // }

  // const unsetDoNotAssign = () => {
  //   history.replace(unsetQueryParam(AGENT_DO_NOT_ASSIGN))
  // }

  const setNucleusExcluded = () => {
    history.replace(setQueryParam(AGENT_NUCLEUS_EXCLUDED))
  }

  const unsetNucleusExcluded = () => {
    history.replace(unsetQueryParam(AGENT_NUCLEUS_EXCLUDED))
  }

  const setFreeAgents = () => {
    history.replace(setQueryParam(AGENT_FREE_AGENTS))
  }

  const unsetFreeAgents = () => {
    history.replace(unsetQueryParam(AGENT_FREE_AGENTS))
  }


  const onFilterChange = (value, event) => {
    if (value.includes(AGENT_SHOW_ALL_DUTY_MODE)) {
      setAllAgents()
    }
    else {
      unsetAllAgents()
    }
    // if (value.includes(AGENT_GO_HOME)) {
    //   setGoHome()
    // }
    // else {
    //   unsetGoHome()
    // }
    // if (value.includes(AGENT_DO_NOT_ASSIGN)) {
    //   setDoNotAssign()
    // }
    // else {
    //   unsetDoNotAssign()
    // }
    if (value.includes(AGENT_NUCLEUS_EXCLUDED)) {
      setNucleusExcluded()
    }
    else {
      unsetNucleusExcluded()
    }
    if (value.includes(AGENT_FREE_AGENTS)) {
      setFreeAgents()
    }
    else {
      unsetFreeAgents()
    }

  }

  return (
    <div className="mh-2 mv-1 t-right font-md c-pointer">
      <CheckPicker data={agentFilterData} appearance="subtle" style={{width: 200}} placeholder="Filter Agents"
       placement="bottomEnd" searchable={false} onChange={onFilterChange} value={filterPickerValue} />
    </div>
  )
}

export default AgentFilterOnDuty

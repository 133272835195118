import { AGENT_SEARCH_QUERY_PARAM } from "global-constants/agents/query_params";
import { AgentsInterface } from "states/data/agents";
import { getQueryParam } from "utils/history";

export const searchAgents = (
  agentsData: AgentsInterface[]
): AgentsInterface[] => {
  const searchQuery = getQueryParam(AGENT_SEARCH_QUERY_PARAM);
  if (!searchQuery) {
    return agentsData;
  }
  const query = searchQuery.toLocaleLowerCase();
  return [...agentsData].filter((x) => {
    return (
      x.agent_info.name.toLocaleLowerCase().indexOf(query) > -1 ||
      x.agent_info.phone.toLocaleLowerCase().indexOf(query) > -1 ||
      x.agentID.toLocaleLowerCase().indexOf(query) > -1
    );
  });
};

export const searchMatch = (
  agentData: AgentsInterface,
  query: string
): boolean => {
  return (
    agentData.agent_info.name.toLocaleLowerCase().indexOf(query) > -1 ||
    agentData.agent_info.phone.toLocaleLowerCase().indexOf(query) > -1 ||
    agentData.agentID.toLocaleLowerCase().indexOf(query) > -1
  );
};

import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Checkbox, FormControlLabel, Switch, Typography } from "@material-ui/core";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { HexColorList } from "global-constants/colors";
import { tsToMonthDayTime, timeAfterSecond } from "utils/datetime";
import { AgentsInterface, HoveredAgentRouteData, updateHoveredAgentReorderedQueue } from "states/data/agents";
import { setAgentRouteMapWithCostRevenue, setHoveredAgentRouteMap } from "apps/ops-dashboard/services/agents/routemap";
import { getQueryParam, setQueryParam, toggleQueryParam, unsetQueryParam, useQueryParams } from "utils/history";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AGENT_SELECTED_QUERY_PARAM, AGENT_IGNORE_POLYLINE } from "global-constants/agents/query_params";

function OnGoingCard({ onGoingData, index, onGoingMeta }) {

  return (
    <>
      <div className={`d-flex align-center`}>
        <div
          className={`mr-1 wx-1 h-10`}
          style={{background: HexColorList[index] }}
        ></div>
        <div>
          <div className={`d-flex align-center`}>
            <Typography variant="subtitle1" align="left">
              {onGoingData.orderID || onGoingData.type}
            </Typography>
          </div>
          <Typography variant="caption">
            {onGoingData.type} | SLA - {tsToMonthDayTime(onGoingData.SLA)}
          </Typography>
          <br />
          {onGoingData.type !== "WH_DROP" && (
            <Typography variant="caption">
              {onGoingData.shipType} - {onGoingData.packSize} |
            </Typography>
          )}
          &nbsp;
            <Typography variant="caption">
              ETA - {timeAfterSecond(onGoingMeta?.cumulativeTravelTime)}
            </Typography>
        </div>
        <div className={`ml-auto`}>
          <DragHandleIcon />
        </div>
      </div>
      <hr />
    </>
  );
}

function EditOnGoing({getAssignQueue}) {
  
  const hoveredAgentRouteMap: HoveredAgentRouteData = useSelector(
    (state: any) => state.agentsData.hoveredAgentRouteMap
  );
  const reorderQueue = useSelector(
    (state: any) => state.agentsData.hoveredAgentRouteMap.reorderQueue
  );
 
  const query = useQueryParams();
  const history = useHistory();
  
  const dispatch = useDispatch();

  const selectedAgent = useQueryParams().get(AGENT_SELECTED_QUERY_PARAM);

  const getHideRouteQuery = useMemo(
    () => query.get(AGENT_IGNORE_POLYLINE) === '1',
    [query],
  )

  const origAgentsData: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );
  const activeAgentID = getQueryParam(AGENT_SELECTED_QUERY_PARAM)
  const agentData = origAgentsData.find((x) => x.agentID === activeAgentID);


  useEffect(() => {
    if(agentData?.agent_info.lastKnownLocation)
    // dispatch(setHoveredAgentRouteMap(activeAgentID, reorderQueue, agentData?.agent_info.lastKnownLocation, getHideRouteQuery))
    dispatch(setAgentRouteMapWithCostRevenue(activeAgentID, reorderQueue))
  }, [reorderQueue]);
  
  const onDragEnd = (e) => {
    const onGoingOrders = [...hoveredAgentRouteMap.reorderQueue]

    const sourceIndex = e.source.index
    const destIndex = e.destination.index
    
    const destItem = onGoingOrders[destIndex]
    onGoingOrders[destIndex] = onGoingOrders[sourceIndex]
    onGoingOrders[sourceIndex] = destItem

    dispatch(updateHoveredAgentReorderedQueue(onGoingOrders))
  };

  const onHideRoutesChanged = (e) => {
    const onGoingOrders = [...hoveredAgentRouteMap.requestedQueue]
    if (e.target.checked) history.push(setQueryParam(AGENT_IGNORE_POLYLINE));
    else history.replace(unsetQueryParam(AGENT_IGNORE_POLYLINE));
    if(agentData?.agent_info.lastKnownLocation)
    // dispatch(setHoveredAgentRouteMap(activeAgentID, onGoingOrders, agentData?.agent_info.lastKnownLocation, !getHideRouteQuery))
    dispatch(setAgentRouteMapWithCostRevenue(activeAgentID, onGoingOrders))
  }

  const profit = useMemo(() => (hoveredAgentRouteMap?.routeRevenue as number ) - (hoveredAgentRouteMap?.routeCost as number), [hoveredAgentRouteMap])

  const AutoArrangeBtn = useCallback(() => {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => getAssignQueue(selectedAgent, true)}
      >
        Auto Arrange
      </Button>
    );
  }
  , [selectedAgent])


  return (
    <>
      <div className={`mt-4 ph-2`}>
        <Typography variant="h6">Active going orders</Typography>
        <hr/>
        <div className={`d-flex align-left`}>
          <p>
          Cost: <span className={`text-${profit < 0 ? 'danger' : 'success'}`}>{hoveredAgentRouteMap?.routeCost}</span>
          {" • "}
          Revenue: <span className={`text-info`}>{hoveredAgentRouteMap?.routeRevenue}</span>
          {" | "}
          {hoveredAgentRouteMap.routeCost as number>0 &&  <span className={
            `text-${profit < 0 ? 'danger' : 'success'}`
          }>{Math.abs(Math.round((profit/(hoveredAgentRouteMap?.routeCost as number))*100))} % 
          <samp>{profit < 0 ? " loss":" profit"}</samp>
          </span>}
          </p>
        </div>
        <hr/>
        <div className="d-flex justify-space-between">
        <div className="">
        <FormControlLabel
          control={
            <Switch
              checked={getHideRouteQuery}
              onChange={onHideRoutesChanged}
              name="show-routes-toggle"
              color="primary"
            />
          }
          label="hide route"
        />
        </div>
        <div className="">
        <AutoArrangeBtn/>
        </div>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className={`ph-2`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {hoveredAgentRouteMap && hoveredAgentRouteMap.reorderQueue.map((order, index) => (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`mv-2`}
                    >
                      <OnGoingCard onGoingData={order} 
                      onGoingMeta={hoveredAgentRouteMap && hoveredAgentRouteMap.routeData![index]} 
                      index={index} key={index} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default EditOnGoing;
import APIReq from "services/api"
import { BusinessDetailsInterface, OrdersInterface, updateBusinessDetails } from "states/data/orders"

export const setBusinessData = (ordersData: OrdersInterface[]) => (dispatch, getState) => {
    const current_businesses_code = getState().ordersData.businessesDetail.map(biz => biz.businessCode)
    const businesses_code = Array.from(new Set(ordersData.filter(order => !!order.business_code)
        .map(order => order.business_code) as string[])).filter(bizCode => current_businesses_code.indexOf(bizCode) === -1)
    if(businesses_code.length > 0){
    APIReq.orders.getBusinessNames(businesses_code)
        .then((res: { data: { biz_names: BusinessDetailsInterface[] } }) => {
            const biz_names = res.data.biz_names;
            const unfounded_bizs = businesses_code.filter(biz => biz_names.findIndex(biz_name => biz_name.businessCode === biz) === -1)
            unfounded_bizs.forEach(unfoundedBiz => biz_names.push({ 'businessCode': unfoundedBiz, 'businessName': unfoundedBiz }))
            // const biz_names_sorted = biz_names.sort((biz1, biz2)=> biz1.businessName.toLowerCase().localeCompare(biz2.businessName.toLowerCase()))
            dispatch(updateBusinessDetails(biz_names))
        })
    .catch(e => {})}
}
import { PathLayer } from "@deck.gl/layers";
import React, { useEffect } from "react";
import { HexColorList } from "global-constants/colors";
import Color from "color";
import { layersSlice, setLayerTaskLogRoute } from "states/deck.gl/layers";
import { useDispatch, useSelector } from "react-redux";
import polyline from "@mapbox/polyline";

function pathLayer(data) {
  /**
   * Data format:
   * [
   *   {
   *     coords: [[-122.4, 37.7], [-122.5, 37.8], [-122.6, 37.85]],
   *     name: 'Order id - status - distance - earnings',
   *   },
   *   ...
   * ]
   */
  if (!data || !data.length) {
    return;
  }
  const layer = new PathLayer({
    id: "agent-task-log-path-layer",
    data,
    pickable: true,
    getPath: (d: any) => d.coords.coordinates,
    widthScale: 2,
    rounded: true,
    widthMinPixels: 4,
    // @ts-ignore
    getColor: (d, i) => {
      return Color(HexColorList[i.index]).rgb().array();
    },
    getWidth: (d) => 5,
  });
  return layer;
}

function AgentTaskLogLayer() {
  const dispatch = useDispatch();

  const taskLogData = useSelector((state: any) => state.taskLogData);
  
  useEffect(() => {
    const taskLogs = taskLogData?.agentTaskLogs || [];
    const pathLayerData: any[] = [
      /**
       *
       */
    ];

    for (let i = 0; i < taskLogs.length; i++) {
      const log = taskLogs[i];
      if (!log.polyline) {
        continue;
      }

      const toolTipText = `${log["orderID"][0].substr(-6)} - ${
        log.orderStatus
      } | ${log.distance.toFixed(2)} Kms | Rs. ${log.earnings.toFixed(
        2
      )}`;
      pathLayerData.push({
        coords: polyline.toGeoJSON(log.polyline),
        name: toolTipText,
      });
    }

    dispatch(setLayerTaskLogRoute(pathLayer(pathLayerData)));
  }, [taskLogData]);

  return <></>;
}

export default AgentTaskLogLayer;

import { CircularProgress, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import OrdersCard from "apps/ops-dashboard/components/orders/OrdersCard";
import {
  OrderAssignStatInterface,
  ordersAssignStatSlice,
  OrdersInterface,
  ORDER_ASSIGN_STATE_FAILED,
  ORDER_ASSIGN_STATE_SUCCESS,
  setOrderAssignStatuses,
} from "states/data/orders";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQueryParamList } from "utils/history";

function RenderAssigningOrders() {
  const dispatch = useDispatch();

  const orderAssignStat: OrderAssignStatInterface = useSelector(
    ordersAssignStatSlice
  );
  const allOrders: OrdersInterface[] = useSelector(
    (state: any) => state.ordersData.origOrdersData
  );

  useEffect(() => {
    return () => {
      dispatch(setOrderAssignStatuses([]));
    };
  }, []);

  const selectedOrderDetails = allOrders.filter(
    (x) =>
      orderAssignStat.orderAssignStatuses.findIndex(
        (orderStatus) => orderStatus.orderID === x.orderID
      ) > -1
  );

  const renderIconStatus = (orderDetails: OrdersInterface) => {
    const assigned = orderAssignStat.orderAssignStatuses.find(
      (x) => x.orderID === orderDetails.orderID
    );
    switch (assigned?.status) {
      case ORDER_ASSIGN_STATE_SUCCESS:
        return <CheckIcon style={{ color: "green" }} />;
      case ORDER_ASSIGN_STATE_FAILED:
        return <ErrorIcon style={{ color: "red" }} />;
      default:
        return <CircularProgress size={20} />;
    }
  };

  return (
    <div className="mt-2">
      <div className="mh-2 mb-1 d-flex justify-space-between">
        <Typography variant="subtitle1">
          Assigning {orderAssignStat.orderAssignStatuses.length} order(s)
        </Typography>
        <div className="d-flex align-center font-md">
          <CheckIcon style={{ color: "green" }} /> &nbsp;&nbsp;
          <strong>{
            orderAssignStat.orderAssignStatuses.filter(
              (x) => x.status === ORDER_ASSIGN_STATE_SUCCESS
            ).length
          }&nbsp;&nbsp;&nbsp;</strong>
          <ErrorIcon style={{ color: "red" }} /> &nbsp;&nbsp;
          <strong>{
            orderAssignStat.orderAssignStatuses.filter(
              (x) => x.status === ORDER_ASSIGN_STATE_FAILED
            ).length
          }</strong>
        </div>
      </div>
      {selectedOrderDetails.map((x, i) => (
        <OrdersCard
          key={i}
          orderDetails={x}
          topRightIcon={renderIconStatus(x)}
        />
      ))}
    </div>
  );
}

export default RenderAssigningOrders;


// Render a component that displays the details of a run.

import { Card, IconButton, makeStyles } from "@material-ui/core";
import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import APIReq from "services/api";
import { RunDetailsType } from "types/nucleus-types";
import { getQueryParam, unsetQueryParam, useQueryParams } from "utils/history";
import ClearIcon from '@material-ui/icons/Clear';
import { RUN_ID_QUERY_PARAM } from "global-constants/nucleus/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { OrdersInterface } from "states/data/orders";
import { AgentsInterface } from "states/data/agents";
import { useFetchOrdersFromRunID } from "apps/nucleus/custom-hooks/useFetchOrdersFromRunID";
import { useFetchRunDetailsFromRunID } from "apps/nucleus/custom-hooks/useFetchRunDetailsFromRunID";
import { useFetchAgentsFromRunID } from "apps/nucleus/custom-hooks/useFetchAgentsFromRunID";

const RunDetails = () => {

  const history = useHistory();
  
  const{
    runDetails: runDetails, 
    loadingRunDetails: isRunDetailsLoading, 
    hasError: runDetailsError
    }=useFetchRunDetailsFromRunID();

  // const { 
  //   orders, 
  //   isInitiating, 
  //   hasError, 
  //   fetchedOrders, 
  //   ordersFetchProgress, 
  //   ordersFetchErrorRate 
  // } = useFetchOrdersFromRunID();

  // const {
  //   agents,
  //   isInitiating: isAgentsInitiating,
  //   hasError: agentsHasError,
  //   fetchedAgents,
  //   agentsFetchProgress,
  //   agentsFetchErrorRate
  // } = useFetchAgentsFromRunID();

  // useEffect(() => {
  //   if (runDetails) {
  //     console.log("orders", fetchedOrders);
  //     console.log("agents", fetchedAgents);
  //   }
  // }, [ fetchedOrders, fetchedAgents]);

  const goBack = () => {
    history.replace(unsetQueryParam(RUN_ID_QUERY_PARAM));
    history.replace(unsetQueryParam(SIDEBAR_QUERY_PARAM));
  }

  if (isRunDetailsLoading) {
    return <div>Loading...</div>;
  }

  if (runDetailsError) {
    return <div>Error: {runDetailsError}</div>;
  }

  return (
    <Card style={{ padding: "16px" }}>
      <div className="d-flex justify-space-between">
        <h3>Run Details</h3>
        <IconButton onClick={goBack}>
          <ClearIcon />
        </IconButton>
      </div>
      <p>Run ID: {runDetails?.run_id}{" "}
      {runDetails?.solution_found ? "✅" : "❌"}</p>
      <p>Region: {runDetails?.region}</p>
      <p>Number of agents: {runDetails?.num_agents}</p>
      <p>Number of orders: {runDetails?.order_ids.length}</p>
      <p>Number of routes: {runDetails?.solution_routes.length}</p>
      <p>Number of orders per route: {runDetails?.solution_routes.map(route => route.length).join(", ")}</p>
      <p>Number of agents per route: {runDetails?.solution_routes.map(route => route.filter(agent => agent !== -1).length).join(", ")}</p>
    </Card>
  );
}

export default RunDetails;
import { Checkbox, makeStyles, Paper, Typography } from "@material-ui/core";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { DDMMTime, timeToX } from "utils/datetime";
import { useQueryParams } from "utils/history";
import { ShipTypeColors } from "global-constants/colors";
import { OrdersInterface } from "states/data/orders";
import OrderActions from "./OrderActions";
import { useSelector } from "react-redux";
import { AgentsInterface } from "states/data/agents";
import AgentInfo from "../agents/AgentInfo";
import OrderQuickActions from "./OrderQuickActions";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  titleTop: {},
  subtitle: {
    marginTop: -4,
    display: "flex",
    justifyContent: "space-between",
  },
});

interface OrdersCardInterface {
  orderDetails: OrdersInterface;
  noPadding?: boolean;
  onOrderSelect?: Function | null;
  actions?: boolean;
  topRightIcon?: JSX.Element | null;
  showAgentDetails?: boolean;
  showAgentLastUpdated?: boolean;
}

const OrdersCard = ({
  orderDetails,
  noPadding = false,
  onOrderSelect = null,
  actions = false,
  topRightIcon = null,
  showAgentDetails = true,
  showAgentLastUpdated = false,
}: OrdersCardInterface) => {
  const classes = styles();
  const query = useQueryParams();

  const agentsData: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );
  const agentsDetail =
    orderDetails.agentID && orderDetails.agentID !== ""
      ? agentsData.find((x) => x.agentID === orderDetails.agentID)
      : null;

  const topRightAction = () => {
    if (topRightIcon) {
      return topRightIcon;
    }
    if (onOrderSelect) {
      return (
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          name={orderDetails.orderID}
          // classes={{ label: classes.filterOptionText }}
          onClick={() => onOrderSelect(orderDetails.orderID)}
          checked={
            (query.get(ORDERS_SELECTED_QUERY_PARAM) || "")
              .split(";")
              ?.indexOf(orderDetails.orderID) > -1
          }
        />
      );
    }
  };

  return (
    <div
      className={`d-block`}
      style={{
        padding: noPadding ? "none" : "8px 16px",
      }}
    >
      <Paper className="w-100 p-2">
        <div className={"d-flex justify-space-between align-center mb-1"}>
          <Typography variant="subtitle1" component="div">
            <b>
              {orderDetails.orderID}{" "}
              <FileCopyIcon
                style={{ cursor: "pointer", fontSize: "12px" }}
                onClick={() => {
                  navigator.clipboard.writeText(orderDetails.orderID);
                }}
              />
            </b>
          </Typography>
          {topRightAction()}
        </div>
        <div className={classes.subtitle} style={{ fontSize: 12 }}>
          <div>
            <b style={{ color: ShipTypeColors[orderDetails.ship.type] }}>
              {orderDetails.ship.type}
            </b>{" "}
            | {orderDetails.pack.type} | {orderDetails.orderStatus}
          </div>
          <div>{timeToX(orderDetails.requestedAtTimestamp)}</div>
        </div>
        <hr className={"mv-1"} />
        {showAgentDetails && agentsDetail ? (
          <>
            <AgentInfo
              agentData={agentsDetail}
              showLastUpdated={showAgentLastUpdated}
            />
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="d-flex justify-space-between mb-1">
          <span className="d-flex align-center">
            <ArrowRightIcon htmlColor="green" fontSize="small" />
            {orderDetails.sender.name}{" "}
          </span>
          <span style={{ fontSize: 13 }}>
            {" "}
            {orderDetails.sender.mobileNumber}
          </span>
        </div>
        {/* <div style={{ fontSize: 13 }}>{orderDetails.pickLocation.address}</div> */}

        <div className="d-flex justify-space-between align-center">
          <span className="d-flex align-center">
            <ArrowRightIcon htmlColor="red" fontSize="small" />
            {orderDetails.receiver.name}{" "}
          </span>
          <span style={{ fontSize: 13 }}>
            {" "}
            {orderDetails.receiver.mobileNumber}
          </span>
        </div>
        {/* <div style={{ fontSize: 13 }}>{orderDetails.pickLocation.address}</div> */}

        <hr />
        <div className="d-flex font-sm align-center justify-space-between">
          <span>Distance: {orderDetails.distance.toFixed(2)} Kms</span>
          <span>
            SLA: <b>{timeToX(orderDetails.slaDrop)}</b>
          </span>
        </div>
        {orderDetails.instructions !== "" && (
          <div className="font-sm mt-1">
            Instructions: <b>{orderDetails.instructions}</b>
          </div>
        )}
        <hr />
        {orderDetails.cancellationReason &&
          orderDetails.cancellationReason !== "" && (
            <div className="font-sm mt-1">
              Last failure: <b>{orderDetails.cancellationReason}</b>
            </div>
          )}
        {<OrderQuickActions orderDetails={orderDetails}/>}
        {actions ? <OrderActions orderDetails={orderDetails} /> : ""}
      </Paper>
    </div>
  );
};

export default OrdersCard;

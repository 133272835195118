import { ApiClientGet } from "utils/api"


const NucleusApis = {
    getRuns: (startTs, endTs, page = 0, count=10) => ApiClientGet(`/nucleus/l1/runs`, {page, count, start_ts: startTs, end_ts: endTs}),
    getRunDetails: (runID: string) => ApiClientGet(`/nucleus/l1/run/${runID}`),
    getNucleusStatus: (regionCode: string) => ApiClientGet(`/nucleus/l1/enabled/${regionCode}`),
    getNucleusLatestRun: (regionCode: string) => ApiClientGet(`/nucleus/l1/run/latest/${regionCode}`),
}

export default NucleusApis
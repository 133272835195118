import Container from "@material-ui/core/Container";

export default function Loading() {

  return (
    <div className={`loading-center-container`}>
      <Container component="main" maxWidth="xs" className="t-center">
        <img
          src="/images/logo-text.png"
          className="m-auto"
          width={180}
          height={30}
        />
        <div className="mt-4"></div>
      </Container>
    </div>
  );
}

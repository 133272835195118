import axios from "axios"
import { ajax } from "rxjs/ajax"

export const HTTPatch = (url: string, body: any) => {
  return axios.patch(url, body, { withCredentials: true })
}

export const HTTPPost = (url: string, body: any) => {
  return axios.post(url, body, { withCredentials: true })
}

export const HTTPPut = (url: string, body: any) => {
  return axios.put(url, body, { withCredentials: true })
}

export const HTTPGet = (url: string) => {
  return axios.get(url, { withCredentials: true })
}

export const HTTPDelete = (url: string) => {
  return axios.delete(url, { withCredentials: true })
}

export const RxHTTPPatch = (url: string, body: any) => {
  return ajax({
    url: url,
    method: "PATCH",
    body: body,
    crossDomain: true,
    withCredentials: true
  })
}
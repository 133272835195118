import bikeIcon from 'assets/new_icons/agents/vehicles/bike.svg';
import mopedIcon from 'assets/new_icons/agents/vehicles/moped.svg';
import closedRickshawIcon from 'assets/new_icons/agents/vehicles/closed_rickshaw.svg';
import closedTruckIcon from 'assets/new_icons/agents/vehicles/closed_truck.svg';
import openTruckIcon from 'assets/new_icons/agents/vehicles/open_truck.svg';
import unknownVehicleIcon from 'assets/new_icons/agents/vehicles/unknown_vehicle.svg';
import openRickshawIcon from 'assets/new_icons/agents/vehicles/open_rickshaw.svg';

const getAgentVehicleIcon = (vehicleType: string) => {
    const vehicleTypeIconMap = {
        'bike': bikeIcon,
        'moped': mopedIcon,
        'truck': closedTruckIcon,
        'open_cargo_truck': openTruckIcon,
        'pickup_truck': openTruckIcon,
        'closed_cargo_truck': closedTruckIcon,
        'closed_truck': closedTruckIcon,
        'three_wheeler': closedRickshawIcon,
        'absent_vehicle': unknownVehicleIcon,
        'open_cargo_rickshaw': openRickshawIcon,
        'closed_cargo_rickshaw': closedRickshawIcon,
    }
    const vehicleTypeToLoadCapacityMap = {
        'bike': 20,
        'moped': 25,
        'truck': 750,
        'open_cargo_truck': 750,
        'pickup_truck': 750,
        'closed_cargo_truck': 750,
        'closed_truck': 750,
        'three_wheeler': 600,
        'open_cargo_rickshaw': 600,
        'closed_cargo_rickshaw': 600,
        'absent_vehicle': 0
    }

    if (!vehicleTypeIconMap[vehicleType]) {
        return unknownVehicleIcon
    }
    return vehicleTypeIconMap[vehicleType]
}

export default getAgentVehicleIcon;
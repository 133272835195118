import { IconLayer } from "@deck.gl/layers";
import { shipTypeIcons } from "../utils/icons";
import { orderIcon } from "utils/custom_svg/orders/order_icon";
import { TextLayer } from "@deck.gl/layers";
import { mapPackageSizeToItsAbbreviation } from "global-constants/orders/info_map";

const allOrderIconLayer = (data, hoveredOrder, onOrderClick, hidePackDetailsOnIcons, visible = true) => {
  return [new IconLayer({
    id: "all-order-icon-layer",
    data,
    pickable: true,
    getIcon: (d: any) => shipTypeIcons(d.ship.type),
    // getIcon: (d: any) => ({
    //   url: orderIcon(d.ship.type, d.pack.type, hidePackDetailsOnIcons),
    //   width: 600,
    //   height: 380,
    //   anchorY: 150,
    //   mask: false,
    // }),
    visible: visible,
    sizeScale: 50,
    sizeUnits: "meters",
    sizeMinPixels: 18,
    sizeMaxPixels: 38,
    autoHighlight: true,
    // TODO - When order is in WH_DROP, the pick location should be WH
    getPosition: (d: any) => [d.pickLocation.lng, d.pickLocation.lat],
    getSize: (d: any) => 5,
    opacity: 0.6,
    getColor: [255, 255, 255, 160],
    onHover: hoveredOrder,
    onClick: onOrderClick,
  }),
  new TextLayer({
    id: "all-order-pack-text-layer",
    data,
    pickable: true,
    getPosition: (d: any) => [d.pickLocation.lng, d.pickLocation.lat],
    getText: (d: any) => mapPackageSizeToItsAbbreviation[d.pack.type],
    getSize: 10,
    visible: visible && !hidePackDetailsOnIcons,
    getColor: [0, 0, 0, 160],
    getAngle: 0,
    getTextAnchor: "start",
    getAlignmentBaseline: "center",
    getPixelOffset: [0, -10],
    onHover: hoveredOrder,
    onClick: onOrderClick,
    backgroundColor: [255, 255, 255, 160],
    borderRadius: 50,
  })
];
};

export default allOrderIconLayer;

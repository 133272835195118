import { ThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Switch, BrowserRouter, Route } from "react-router-dom";

import store from "states/index";
import { authDataSlice } from "states/auth";

import OpsDashboard from 'apps/ops-dashboard'
import Nucleus from "apps/nucleus";

import Auth from "components/auth";

import { theme } from "./styles/theme";
import "styles/globals.scss";

// A wrapper component to check if user authenticated or not
const ProtectedAuth = (props: any) => {
  const authData = useSelector(authDataSlice);

  if (!authData.isAuthenticated) {
    return <Auth />;
  }

  return <>{props.children}</>;
};

const Apps = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={OpsDashboard} />
        <Route exact path="/nucleus" component={Nucleus} />
      </Switch>
    </BrowserRouter>
  );
};

export default function () {
  useEffect(() => {
    window.addEventListener('error', e => {
      if(e.message === 'ResizeObserver loop completed with undelivered notifications.' || 
          e.message === 'ResizeObserver loop limit exceeded') {
          e.stopImmediatePropagation();
      }
  })
  }, [])
  
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ProtectedAuth>
          <Apps />
        </ProtectedAuth>
      </ThemeProvider>
    </Provider>
  );
}

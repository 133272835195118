import HoveredOrderRouteMap from "./HoveredOrderRouteMap";
import OrdersIconLayer from "./OrdersIconLayer";

const OrdersLayer = (props) => {
  return (
    <>
      <OrdersIconLayer />
      <HoveredOrderRouteMap />
    </>
  );
};

export default OrdersLayer;

import { Button, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AgentInfo from "apps/ops-dashboard/components/agents/AgentInfo";
import { AxiosResponse } from "axios";
import React, { createRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import APIReq from "services/api";
import { AgentsInterface } from "states/data/agents";
import { updateQueryParam, useQueryParams } from "utils/history";
import Header from "../header";
import AgentSearch from "./AgentSearch";
import AllAgents from "./AllAgents";
import { AGENT_SEARCH_QUERY_PARAM, AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_AGENT_TASK_LOG_HISTORY } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";

function AllAgentsDB() {
  const query = useQueryParams();
  const history = useHistory()

  const [agentsData, setAgentsData] = useState<AgentsInterface[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    const q = query.get(AGENT_SEARCH_QUERY_PARAM);

    if (q && q !== searchQuery) {
      setSearchQuery(q);
    }
  }, [query]);

  useEffect(() => {
    APIReq.agents
      .allAgents(page, 10, searchQuery)
      .then(
        (
          res: AxiosResponse<{
            data: {
              meta: {
                count: number;
                query: string;
                page: number;
                size: number;
              };
              results: AgentsInterface[];
            };
          }>
        ) => {
          setAgentsData(res.data.data.results);
        }
      )
      .catch(console.log);
  }, [page, searchQuery]);

  const switchToSelectedAgentMode = (agentID: string, taskType?: string) => {
    history.replace(updateQueryParam(AGENT_SELECTED_QUERY_PARAM, agentID));
    if (taskType) {
      history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, taskType));
    }
  };

  return (
    <div>
      <Header title="All agents" />
      <AgentSearch />
      <div className="mb-3" />
      {agentsData.map((x, i) => (
        <div className="ph-2" key={i}>
          {" "}
          <AgentInfo agentData={x} />
          <div className="d-flex justify-right">
            <Tooltip title="Task history">
              <IconButton onClick={() => switchToSelectedAgentMode(x.agentID, SIDEBAR_TYPE_AGENT_TASK_LOG_HISTORY)}>
                <ListAltOutlinedIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
}

export default AllAgentsDB;

import { AGENT_SELECTED_QUERY_PARAM } from "global-constants/agents/query_params";
import { AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM, AGENT_WH_OTP_GENERATION_MODE_WH_PICK, AGENT_WH_OTP_GENERATION_MODE_WH_DROP, AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM } from "global-constants/orders/query_params";
import APIReq from "services/api";
import { setWHOTP, WHOTPInterface } from "states/data/orders";
import { getQueryParam, getQueryParamJSONArray } from "utils/history";

export const GenerateWarehouseOTP = (warehouseID, setGeneratingOTP?, snackbar?) => (dispatch, getState) => {
  const selectedAgent = getQueryParam(AGENT_SELECTED_QUERY_PARAM);
  if (!selectedAgent) {
    return;
  }
  var mode: "WH_DROP" | "WH_PICK";
  switch (getQueryParam(AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM)) {
    case AGENT_WH_OTP_GENERATION_MODE_WH_PICK:
      mode = "WH_PICK";
      break;
    case AGENT_WH_OTP_GENERATION_MODE_WH_DROP:
      mode = "WH_DROP";
      break;
    default:
      return;
  }
  setGeneratingOTP && setGeneratingOTP(true);
  APIReq.agents
    .generateWHOTP(selectedAgent, {
      orderIDs: getQueryParamJSONArray(
        AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM
      ),
      taskType: mode,
      warehouseID: warehouseID
    })
    .then((res: { data: { data: WHOTPInterface } }) => {
      dispatch(setWHOTP(res.data.data));
    })
    .catch((err) => {
      snackbar && snackbar.enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
    })
    .finally(() => {
      setGeneratingOTP && setGeneratingOTP(false);
    });
};
import axios from 'axios';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
})


export const ApiClientGet = (url: string, queryParams?: {[key: string]: string|number}) => axiosClient.get(url, {params: queryParams})

export const ApiClientPost = (url: string, body: any) => axiosClient.post(url, body)

export const ApiClientPut = (url: string, body: any) => axiosClient.put(url, body)

export const ApiClientPatch = (url: string, body: any) => axiosClient.patch(url, body)

export const ApiClientDelete = (url: string) => axiosClient.delete(url)
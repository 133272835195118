import { useFetchOrdersFromRunID } from 'apps/nucleus/custom-hooks/useFetchOrdersFromRunID';
import { useFetchRunDetailsFromRunID } from 'apps/nucleus/custom-hooks/useFetchRunDetailsFromRunID';
import { generateOrderSelectQueryParam } from 'apps/ops-dashboard/services/orders/select-order';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setHoveredOrder } from 'states/data/orders';
import allOrderIconLayerNucleus from './allOrderIconLayer';
import { resetNucleusLayers, setNucleusOrdersLayers } from 'states/nucleus/deck.layers';
import { Progress } from 'rsuite';

function OrderIconLayer() {

    const history = useHistory();

    const{
        runDetails: runDetails, 
        loadingRunDetails: isRunDetailsLoading, 
        hasError: runDetailsError
        }=useFetchRunDetailsFromRunID();

    const { 
        orders, 
        isInitiating, 
        hasError, 
        fetchedOrders, 
        ordersFetchProgress, 
        ordersFetchErrorRate 
      } = useFetchOrdersFromRunID();

      const dispatch = useDispatch();

      useEffect(() => {
          if (!fetchedOrders.length) {
            dispatch(resetNucleusLayers())
            return;
          }

          if(!ordersFetchProgress){
              return;
        }
          const allOrderLayer = allOrderIconLayerNucleus(
            fetchedOrders,
            // onOrderHover,
            // onOrderClick,
            // hidePackDetailsOnIcons || false,
            // shiftKeyPressed || !selectedOrders.length
          );

          dispatch(
            setNucleusOrdersLayers(allOrderLayer))

      }
        , [ fetchedOrders]);
  return (
    <></>
    // <div>loading orders <Progress.Circle percent={Math.round(ordersFetchProgress*100)} strokeColor="#ffc107" /></div>
  )
}

export default OrderIconLayer
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Checkbox } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import APIReq from 'services/api';
import { RequestAddressUpdateDataInterface, unsetRequestAddressUpdateData } from 'states/data/orders';

function RequestAddressUpdateDialog() {

    const [locationType, setLocationType] = React.useState<'PICK' | 'DROP' | ''>('');

    const [phone, setPhone] = React.useState('');
    const [phoneEnabled, setPhoneEnabled] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false)

    const [detailsConfirmed, setDetailsConfirmed] = React.useState(false);


    const dispatch = useDispatch()

    const snackbar = useSnackbar();

    const requestAddressUpdateData: string = useSelector(
        (state: any) => state.ordersData.requestAddressUpdateData.orderID
    );

    useEffect(() => {
        if (!requestAddressUpdateData) {
            setLocationType('')
            setPhone('')
            setPhoneEnabled(false)
            setDetailsConfirmed(false)
            setPhoneError(false)
        }
    }, [requestAddressUpdateData])
    const handleClose = () => dispatch(unsetRequestAddressUpdateData());
    const handlePickSelect = () => {
        setLocationType(currentLocationType => {
            if (currentLocationType === "PICK") {
                setPhoneEnabled(false)
                return ""
            }
            return "PICK"
        })
    }
    const handleDropSelect = () => {
        setLocationType(currentLocationType => {
            if (currentLocationType === "DROP") {
                setPhoneEnabled(false)
                return ""
            }
            return "DROP"
        })
    }
    const onMobileNumberChange = (event) => {
        setPhone(event.target.value)
        if (event.target.value.length !== 10 || isNaN(event.target.value)) {
            setPhoneError(true)
            return
        }
        setPhoneError(false)
    }
    const onPhoneChecked = (e) => {
        setPhoneEnabled(e.target.checked)
        if (!e.target.checked) {
            setPhone('');
            setPhoneError(false);
        }
    }
    const onRequest = () => {
        APIReq.orders.requestAddressUpdate(
            [requestAddressUpdateData],
            locationType.toLowerCase() as 'pick' | 'drop',
            phone,
        ).then(response => {
            if (response.status === 201) {
                snackbar.enqueueSnackbar(`Address Update Request for ${requestAddressUpdateData
                    } Successful`, {
                    variant: "success",
                    autoHideDuration: 2000
                });
            }
        }).catch(response => {
            snackbar.enqueueSnackbar(`Address Update Request for ${requestAddressUpdateData
                } failed`, {
                variant: "error",
                autoHideDuration: 2000
            });
        }).finally(() => {
            dispatch(unsetRequestAddressUpdateData())
        })
    }

    const showDialog = () => (
        <Dialog open={true} onClose={handleClose} >
            <DialogTitle>
                Request Address Update for {requestAddressUpdateData}
            </DialogTitle>
            <DialogContent style={{ minWidth: '600px', minHeight: '300px' }}>
                <DialogContentText>
                    Please select location type to continue.
                </DialogContentText>
                <div className="d-block">
                    <Button color="primary"
                        variant={locationType === 'PICK' ? "contained" : "outlined"} onClick={handlePickSelect}>PICK</Button>
                    {" "}
                    <Button color="primary"
                        variant={locationType === 'DROP' ? "contained" : "outlined"} onClick={handleDropSelect}>DROP</Button>
                </div>
                <hr />
                {/* <DialogContentText> */}
                <div>
                    <Checkbox onChange={onPhoneChecked} disabled={locationType === ''} color="primary"
                    />Change phone number.</div>
                {/* </DialogContentText> */}
                <TextField
                    autoFocus
                    margin="dense"
                    id="phone"
                    label="Phone number"
                    type="text"
                    fullWidth
                    required
                    onChange={onMobileNumberChange}
                    error={phoneError}
                    disabled={!phoneEnabled}
                    value={phone}
                    variant="outlined"
                />
                <hr></hr>
                {(locationType !== '') && (<DialogContentText>
                    <Checkbox onChange={(e) => setDetailsConfirmed(e.target.checked)} disabled={(phoneError && phoneEnabled)} color="primary" />
                    I want to request {locationType} address update for <b>{requestAddressUpdateData}</b> {(phone && phoneEnabled) && `via sms to ${phone}`}
                </DialogContentText>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} style={{ color: 'red' }}>Cancel</Button>
                <Button onClick={onRequest} disabled={!detailsConfirmed || (phoneError && phoneEnabled)}>Request</Button>
            </DialogActions>
        </Dialog>
    )

    return (
        <>
            {(requestAddressUpdateData === '') ? <></> : showDialog()}
        </>
    )
}

export default RequestAddressUpdateDialog


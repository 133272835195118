import { createSlice } from "@reduxjs/toolkit";
import { AgentCoordinateInterface,  AgentOrderQueue} from "./agents";
import { OrdersInterface } from "./orders";

export interface AgentTaskLogItemInterface {
    agentID: string,
    agentLocation: AgentCoordinateInterface,
    currentQueue: AgentOrderQueue,
    locationUpdatedAt: string,
    orderID: string[],
    orderStatus: string,
    timestamp: number,
    earnings: number,
    distance: number
  }


const initialState: {
  agentTaskLogs: AgentTaskLogItemInterface[],
  orderDetailsList: OrdersInterface[],
  polyline: string
} = {
  agentTaskLogs: [],
  orderDetailsList: [],
  polyline: ""
};

const taskLogData = createSlice({
  name: "taskLogData",
  initialState,
  reducers: {
    setTaskLog: (state, action: {payload: AgentTaskLogItemInterface[]}) => {
      state.agentTaskLogs = action.payload || [];
    },
    setOrderDetailsList: (state, action: {payload: OrdersInterface[]}) => {
      state.orderDetailsList = action.payload || [];
    },
    setPolyline: (state, action: {payload: string}) => {
      state.polyline = action.payload || ""
    },
    resetTaskLogData: (state) => {
      state = initialState
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setTaskLog,
  setOrderDetailsList,
  setPolyline,
  resetTaskLogData
} = taskLogData.actions;

export default taskLogData.reducer;

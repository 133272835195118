import APIReq from "services/api"
import { setServiceableRegionConfig, setTaskFailConfig, setWarehousesConfig, unsetWarehousesConfig } from "states/data/configs"

export const getTaskFailConfig = (onSuccess?, onFailure?, onComplete?) => (dispatch, getState) => {
  APIReq.configs.getPickDropFailConfig()
    .then((res) => {
      dispatch(setTaskFailConfig(res.data.data.data))
      onSuccess && onSuccess()
    })
    .catch(() => onFailure && onFailure())
    .finally(() => onComplete && onComplete())
}

export const getFrontendBlockedConfigData = (onSuccess?, onFailure?, onComplete?) => {
  APIReq.configs.getFrontendBlockedConfig()
  .then((res) => {
    onSuccess && onSuccess(res.data.data)
  })
  .catch(() => onFailure && onFailure())
  .finally(() => onComplete && onComplete())
}


export const getServiceableRegionConfig = (onSuccess?, onFailure?, onComplete?) => (dispatch, getState) => {
  APIReq.configs.getServiceableRegion()
    .then((res) => {
      dispatch(setServiceableRegionConfig(res.data.data))
      onSuccess && onSuccess()
    })
    .catch(() => onFailure && onFailure())
    .finally(() => onComplete && onComplete())
}

export const getWareHousesConfig = (region_codes, onSuccess?, onFailure?, onComplete?) => (dispatch, getState) => {
  dispatch(unsetWarehousesConfig())
  region_codes.forEach(region_code => {
    APIReq.configs.getWareHouses(region_code)
      .then((res) => {
        dispatch(setWarehousesConfig(res.data.data))
        onSuccess && onSuccess()
      })
      .catch(() => onFailure && onFailure())
      .finally(() => onComplete && onComplete())
  });

}

export const getWareHouses = (region_code, onSuccess?, onFailure?, onComplete?) => {
  APIReq.configs.getWareHouses(region_code)
    .then((res) => {
      onSuccess && onSuccess(res.data.data)
    })
    .catch(() => onFailure && onFailure())
    .finally(() => onComplete && onComplete())
}

/*
 4 | 1 |
--------
 3 | 2 |
*/
export const QUADRANT = {
  1: "PXPY",
  2: "NXPY",
  3: "NXNY",
  4: "PXNY",
} 

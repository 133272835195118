import { Button, makeStyles } from "@material-ui/core";
import AgentInfoAccordian from "apps/ops-dashboard/components/agents/AgentInfoAccordian";
import { AgentsInterface, HoveredAgentRouteData, updateHoveredAgentReorderedQueue } from "states/data/agents";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import APIReq from "services/api";
import { getQueryParamList, unsetQueryParam, updateQueryParam, useQueryParams } from "utils/history";
import Header from "../header";
import EditOnGoing from "./on-going-queue";
import { resetStateOrderAssigning, unsetSkipWHDrop, unsetWarehouseID } from "states/data/orders";
import API from "services/api";
import { AGENT_SELECTED_QUERY_PARAM, AGENT_IGNORE_POLYLINE, SIDEBAR_TYPE_AGENTS } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  sideBar: {
    width: 360,
  },
});

function ReorderAgentQueue(props: {preAssign: boolean}) {
  const classes = styles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const selectedAgent = useQueryParams().get(AGENT_SELECTED_QUERY_PARAM);
  const hoveredAgentRouteMap: HoveredAgentRouteData = useSelector(
    (state: any) => state.agentsData.hoveredAgentRouteMap
  );

  const allAgents: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );

  const skipWH: boolean = useSelector(
    (state: any) => state.ordersData.skipWhDrop
  );

  const pickedWarehouse: string = useSelector(
    (state: any) => state.ordersData.warehouseID
  )

  const getAssignQueue = (selectedAgent, autoRouteArrange=false) => {
    if (selectedAgent) {
      const selectedOrderIDs = getQueryParamList(ORDERS_SELECTED_QUERY_PARAM);
      if (selectedOrderIDs.length > 0 && props.preAssign) {
        startAssigning(selectedOrderIDs, true)
      }
      else{
        startAssigning([], autoRouteArrange)
      }
    }
  }

  useEffect(() => {
    getAssignQueue(selectedAgent)
  }
  , [selectedAgent])

  const deselectAgent = () => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS));
    history.replace(unsetQueryParam(AGENT_IGNORE_POLYLINE));
    history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM));
    dispatch(unsetWarehouseID());
  };

  const startAssigning = (selectedOrderIDs: string[], autoRouteArrange=false) => {
    const ordersAssignData = {orders: selectedOrderIDs.map(orderID => {
      return {
        orderID: orderID,
        dropAtWH: pickedWarehouse ? true : false,
        warehouseID: pickedWarehouse
      }})}
    if (selectedAgent){
    API.agents.getAgentsPreassignOrderQueue(selectedAgent, ordersAssignData, autoRouteArrange)
    .then(res => res.data).then(data => {
      dispatch(updateHoveredAgentReorderedQueue(data.data.agentQueue.items))
    })
    .catch(err => {
      snackbar.enqueueSnackbar(err.message+" retrying with auto route off", { variant: "error" });
      API.agents.getAgentsPreassignOrderQueue(selectedAgent, ordersAssignData, false)
      .then(res => res.data).then(data => {
        dispatch(updateHoveredAgentReorderedQueue(data.data.agentQueue.items))
      })
      .catch(err => {
        snackbar.enqueueSnackbar(err.message, { variant: "error" });
      })
      
    })
  }
  };

  const renderAgentInfo = () => {
    if (!(allAgents.length && selectedAgent)) {
      return <></>;
    }

    return (
      <>
        <AgentInfoAccordian
          agentData={allAgents.find((x) => x.agentID === selectedAgent)}
        />
      </>
    );
  };

  const renderActionButtons = () => {
    return (
      <div className="d-flex justify-space-between">
        <div className="t-left ph-2">

        </div>
        <div className="t-right ph-2">
          <Button onClick={() => deselectAgent()} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button onClick={assignOrders} color="secondary">
            {props.preAssign? 'Assign': 'Reorder Queue'}
          </Button>
        </div>
      </div>
    );
  };

  const assignOrders = () => {
    const updatedQueue = hoveredAgentRouteMap.requestedQueue
    .map(({SLA, ...r}) => {return r});
    if (!selectedAgent) {
      return
    }
    API.agents.assignOrdersToAgent(selectedAgent, {agentQueue: {items: updatedQueue}})
    .then(res => {
      if (res.status == 200) {
        snackbar.enqueueSnackbar("Orders assigned successfully", { variant: "success" })
        onAssignEnd()
      }
      else{
      snackbar.enqueueSnackbar("Error assigning orders", { variant: "error" })
      }
    }).catch(err => {
      snackbar.enqueueSnackbar(err.message, { variant: "error" });
    })
  }

  const onAssignEnd = () => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS));
    history.replace(unsetQueryParam(ORDERS_SELECTED_QUERY_PARAM));
    history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM));
    dispatch(resetStateOrderAssigning());
    dispatch(unsetSkipWHDrop());
    dispatch(unsetWarehouseID());
  };

  const updateAgentRouteMap = () => {
    const hoveredAgentQueue = hoveredAgentRouteMap.requestedQueue
    const currentQueue = hoveredAgentQueue.map(({SLA, ...order_with_no_sla}) => order_with_no_sla)
    if (!selectedAgent) {
      return
    }
    APIReq.agents.setRearrangedRoutes(selectedAgent, currentQueue)
      .then((res) => {
        snackbar.enqueueSnackbar(res.data?.data, {
          variant: "success",
          autoHideDuration: 2000
        });
        deselectAgent()
      })
      .catch((err) => {
        snackbar.enqueueSnackbar(err.response?.data?.message, {
          variant: "error",
          autoHideDuration: 5000
        });
      })
  }
  
  if (!selectedAgent) {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS));
  }

  const AutoArrangeBtn = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => getAssignQueue(selectedAgent, true)}
      >
        Auto Arrange
      </Button>
    );
  };

  return (
    <div className={classes.sideBar}>
      <Header title="Reorder agent queue" noClose />
      {renderAgentInfo()}
      {renderActionButtons()}
      <EditOnGoing getAssignQueue={getAssignQueue}/>
    </div>
  );
}

export default ReorderAgentQueue;

import { AGENT_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { AgentOrderQueueCategory, AgentsInterface } from "states/data/agents";
import { getQueryParamList } from "utils/history";

export const filterAgent = (agentsData: AgentsInterface[]) => {

  const filterKeys = getQueryParamList(AGENT_FILTER_QUERY_PARAM)

  return agentsData.filter((agentData) => {
    let keepItems = true;
    for (let filterKey of filterKeys) {
      const [key, v] = filterKey.split("=")
      let dataAccessor = agentData;
      for (let k of key.split(".")) {
        dataAccessor = dataAccessor[k]
      }
      // keepItems = keepItems && v.includes(dataAccessor)
    }
  })
}

export const filterAgentQueueByType = (orderQueue, type: AgentOrderQueueCategory) => {
  return orderQueue.filter((order) => {
    return order.type === type
  })
}

export const getOrderIDSFromWHQueue = (orderQueue) => {
  return orderQueue.map(queue=>queue.orders).flat().map(order=>order.orderID)
}

import { HTTPPost } from "utils/http";

const PSERVER_URL = process.env.REACT_APP_ENDPOINT;

const requests = {
  getRouteMap: (coordsQueue: Array<{lat: number, lng: number}>) => HTTPPost(`${PSERVER_URL}/admin/route_map`, {
    coordinatesQueue: coordsQueue,
  }),
  getRouteData: (coordsQueue: Array<{lat: number, lng: number}>) => HTTPPost(`${PSERVER_URL}/admin/route_data`, {
    coordinatesQueue: coordsQueue,
  }),
}

export default requests;
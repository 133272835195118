import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";
import APIReq from "services/api";
import { OrdersInterface } from "states/data/orders";
import { useFetchRunDetailsFromRunID } from "./useFetchRunDetailsFromRunID";


export function useFetchOrdersFromRunID() {

    const{
        runDetails: runDetails, 
        loadingRunDetails: isInitiating, 
        hasError: hasError
    }=useFetchRunDetailsFromRunID();

    const orders = useQueries(
        runDetails ? {queries: runDetails?.order_ids.map((order_id) => {
          return {
            queryKey: ['order', order_id],
            queryFn: () => APIReq.OrderApis.getOrder(order_id),
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: Infinity,
            enabled: !!runDetails.order_ids.length
          }
        })} : {queries: []}
    
      );

    const fetchedOrders: Array<OrdersInterface> = useMemo(() => orders.filter(order => order.isFetched && order.isSuccess && order.data).map(order => order.data ? order.data.data.data: null), [orders]);
    const ordersFetchProgress = useMemo(() => orders.filter(order => order.isFetched && order.isSuccess).length/orders.length, [orders]);
    const ordersFetchErrorRate = useMemo(() => orders.filter(order => order.isFetched && order.isError).length/orders.length, [orders]);

    return { orders, isInitiating, hasError, fetchedOrders, ordersFetchProgress, ordersFetchErrorRate };
}
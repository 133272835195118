export const AGENT_SEARCH_QUERY_PARAM = 'a_search'
export const AGENT_SELECTED_QUERY_PARAM = 'a_selected'
export const AGENT_FILTER_QUERY_PARAM = 'a_filter'
export const AGENT_SHOW_ALL_DUTY_MODE = 'a_all'
// export const AGENT_GO_HOME = 'a_home'
// export const AGENT_DO_NOT_ASSIGN = 'a_no_assign'
export const AGENT_IGNORE_POLYLINE = 'a_ignr_route'
export const AGENT_NUCLEUS_EXCLUDED = 'a_nucleus_excluded'
export const AGENT_FREE_AGENTS = 'a_free_agents'
export const SIDEBAR_TYPE_AGENTS = "agents";
export const SIDEBAR_TYPE_AGENT_DATA = "agent_data";
export const SIDEBAR_TYPE_REORDER_AGENT_QUEUE = "reorder_aq";
export const SIDEBAR_TYPE_REORDER_ASSIGN_AGENT_QUEUE = "reorder_a_aq";
export const SIDEBAR_TYPE_ASSIGN_ORDERS = "assign_o";
export const SIDEBAR_TYPE_GENERATE_WH_OTP = "g_wh_o";
export const SIDEBAR_TYPE_AGENT_TASK_LOG_HISTORY = "a_tlh";
export const SIDEBAR_TYPE_GENERATE_ORDER_OTP = "g_order_o";
export const AGENT_GENERAL_FILTER_QUERY_PARAM = "a_g_filter";

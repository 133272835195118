import { HoveredAgentRouteData } from "states/data/agents";
import { useSelector } from "react-redux";
import { Badge, Icon, Timeline } from "rsuite";
import { timeToX, tsWeekdayTime } from "utils/datetime";
import "./OnGoingTimeline.scss";
import { mapPackageSizeToItsAbbreviation } from "global-constants/orders/info_map";

const OnGoingTimelineDot = ({ onGoingOrder }) => {
  switch (onGoingOrder.type) {
    case "PICK":
      return (
        <Icon
          icon="circle"
          style={{ color: "green", left: "-1px", top: "2px" }}
        />
      );
    case "DROP":
      return (
        <Icon
          icon="circle"
          style={{ color: "red", left: "-1px", top: "2px" }}
        />
      );
    case "WH_PICK":
    case "WH_DROP":
    default:
      return (
        <Icon
          icon="circle"
          style={{ color: "yellow", left: "-1px", top: "2px" }}
        />
      );
  }
};

function OnGoingTimeline({ onGoingOrders, limit = 0 }) {
  let orders = onGoingOrders ? [...onGoingOrders] : [];
  const hoveredAgentRouteMap: HoveredAgentRouteData = useSelector(
    (state: any) => state.agentsData.hoveredAgentRouteMap
  );

  if (limit) {
    orders = orders?.slice(0, limit);
  }

  return (
    <>
      <div className="d-flex align-left flex-column">
        <div className="d-flex align-center">
        <div className={`font-md pr-1`}>Active orders</div>
        <Badge style={{ background: "gray" }} content={`${new Set((onGoingOrders || [])?.map(x => x?.orderID).filter((x => !!x))).size} order(s)`} />
        </div>
      </div>
      <Timeline>
        {orders.map((x, i) => (
          <Timeline.Item
            dot={<OnGoingTimelineDot onGoingOrder={x} />}
            className={`pb-1`}
            key={i}
          >
            <strong>{x.orderID.substr(8) || x.type}</strong>
            {x.packSize ? ` | ${mapPackageSizeToItsAbbreviation[x.packSize]}` : ""}
            {x.shipType ? ` | ${x.shipType[0]}` : ""}
            {x.SLA ? ` | ${timeToX(x.SLA)}` : ""}
            {" | " + (hoveredAgentRouteMap && hoveredAgentRouteMap?.routeData![i]?.distance / 1000)?.toFixed(2) + " Km"}
          </Timeline.Item>
        ))}
      </Timeline>{limit?" ...":""}
      {!orders.length && <div className={`font-sm`}>No active orders</div>}
    </>
  );
}

export default OnGoingTimeline;

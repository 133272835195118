import {
  Button,
  Checkbox,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AgentInfoAccordian from "apps/ops-dashboard/components/agents/AgentInfoAccordian";
import OrdersCard from "apps/ops-dashboard/components/orders/OrdersCard";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AgentsInterface } from "states/data/agents";
import { OrdersInterface, WHDetailsInterface, WHOTPInterface } from "states/data/orders";
import {
  getQueryParam,
  getQueryParamJSONArray,
  unsetQueryParam,
  updateQueryParam,
  updateQueryParamJSONList,
  useQueryParams,
} from "utils/history";
import Header from "../header";
import { useSnackbar } from "notistack";
import { GenerateWarehouseOTP } from "apps/ops-dashboard/services/orders/wh-otp";
import { REGION_QUERY_PARAM } from "apps/ops-dashboard/services/regions";
import { getWareHouses } from "apps/ops-dashboard/services/configs";
import { filterAgentQueueByType, getOrderIDSFromWHQueue } from "apps/ops-dashboard/services/agents/filters/agent-filter";
import { AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_AGENTS } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM, AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM, AGENT_WH_OTP_GENERATION_MODE_WH_DROP, AGENT_WH_OTP_GENERATION_MODE_WH_PICK } from "global-constants/orders/query_params";

const styles = makeStyles({
  simpleOrderView: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 16px",
  },
});

function GenerateWHOTP() {
  const history = useHistory();
  const classes = styles();
  const dispatch = useDispatch();
  const snackBar = useSnackbar();

  const [showOrderDetails, setshowOrderDetails] = useState(false);
  const [generatingOTP, setGeneratingOTP] = useState(false);
  const [warehouses, setWarehouses] = useState<WHDetailsInterface[] | []>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<string>(" ");
  const [openWhSelect, setOpenWhSelect] = React.useState(false);

  const snackbar = useSnackbar();

  const clearSelectedWHOrders = () => {
    history.replace(
      unsetQueryParam(AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM)
    );
  };

  const handleTabChange = (event, newValue) => {
    history.replace(
      updateQueryParam(AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM, newValue)
    );
    clearSelectedWHOrders();
  };

  const onOrderSelect = (orderID: string) => {
    const selectedOrderList = getQueryParamJSONArray(
      AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM
    );

    const selectedOrderIndex = selectedOrderList.indexOf(orderID);
    if (selectedOrderIndex > -1) {
      selectedOrderList.splice(selectedOrderIndex, 1);
    } else {
      selectedOrderList.push(orderID);
    }

    history.replace(
      updateQueryParamJSONList(
        AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM,
        selectedOrderList
      )
    );
  };

  const cancel = () => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS));
    history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM));
    clearSelectedWHOrders();
  };

  const selectAll = () => {
    let selectedOrders: string[];
    switch (getQueryParam(AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM)) {
      case AGENT_WH_OTP_GENERATION_MODE_WH_DROP:
        selectedOrders = whDropOrderDetailsList.map((x) => x.orderID);
        break;
      case AGENT_WH_OTP_GENERATION_MODE_WH_PICK:
        selectedOrders = whPickOrderDetailsList.map((x) => x.orderID);
        break;
      default:
        selectedOrders = [];
    }
    history.replace(
      updateQueryParamJSONList(
        AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM,
        selectedOrders
      )
    );
  };

  const deselectAll = () => {
    history.replace(
      updateQueryParamJSONList(
        AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM,
        []
      )
    );
  };

  const allAgents: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );
  const allOrders: OrdersInterface[] = useSelector(
    (state: any) => state.ordersData.origOrdersData
  );
  const whOTP: WHOTPInterface = useSelector(
    (state: any) => state.ordersData.WHOTP
  );

  const selectedAgent = useQueryParams().get(AGENT_SELECTED_QUERY_PARAM);
  const selectedAgentData = allAgents.find((x) => x.agentID === selectedAgent);

  // Find active orders for wh drop and customer and store their details in the arrays below
  let whDropOrderDetailsList: OrdersInterface[] = [];
  let whPickOrderDetailsList: OrdersInterface[] = [];

  allOrders.forEach((orderData, i) => {
    const indexWHDrop = (getOrderIDSFromWHQueue(filterAgentQueueByType(selectedAgentData?.order_queue?.items || [], "WH_DROP"))).indexOf(
      orderData.orderID
    );
    const indexWHPick =
      (getOrderIDSFromWHQueue(filterAgentQueueByType(selectedAgentData?.order_queue?.items || [], "WH_PICK"))).indexOf(
        orderData.orderID
      );
    if (typeof indexWHDrop === "number" && indexWHDrop > -1) {
      whDropOrderDetailsList.push(orderData);
    }
    if (typeof indexWHPick === "number" && indexWHPick > -1) {
      whPickOrderDetailsList.push(orderData);
    }
  });

  const handleWhSelectChange = (event) => {
    setSelectedWarehouse(event.target.value);
  };

  const handleWhSelectClose = () => {
    setOpenWhSelect(false);
  };

  const handleWhSelectOpen = () => {
    setOpenWhSelect(true);
  };

  // Set the tab value if no tab is selected
  React.useEffect(() => {
    if (!getQueryParam(AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM)) {
      history.replace(
        updateQueryParam(
          AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM,
          AGENT_WH_OTP_GENERATION_MODE_WH_DROP
        )
      );
    }
    const regionQuery = getQueryParam(REGION_QUERY_PARAM);
    const enqueFailSnackBar = (message: string) => {
      snackBar.enqueueSnackbar(message, {
        persist: true,
        preventDuplicate: true,
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      })
    }
    getWareHouses(regionQuery, setWarehouses,  () => enqueFailSnackBar("Error loading warehouses"));
  }, []);

  const renderAgentInfo = () => {
    if (!selectedAgentData) {
      return <></>;
    }

    return (
      <>
        <AgentInfoAccordian agentData={selectedAgentData} />
      </>
    );
  };

  const renderActionButtons = () => {
    const selectedLength = getQueryParamJSONArray(
      AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM
    ).length;
    return (
      <>
              <div className="d-flex justify-center pv-2">
          <Select
          open={openWhSelect}
          onClose={handleWhSelectClose}
          onOpen={handleWhSelectOpen}
          value={selectedWarehouse}
          onChange={handleWhSelectChange}
        >
          <MenuItem value=" ">
            <em>select warehouse</em>
          </MenuItem>
          {warehouses.map(wareHouse => <MenuItem value={wareHouse.warehouseID}>{wareHouse.name}</MenuItem>)}
        </Select>
          </div>
        <div className="d-flex justify-right">
          <div className="t-right ph-2">
            <Button onClick={cancel} style={{ color: "red" }}>
              Cancel
            </Button>
            {(!!selectedLength && selectedWarehouse!==' ') && (
              <Button
                onClick={() =>
                  dispatch(GenerateWarehouseOTP(selectedWarehouse, setGeneratingOTP, snackbar))
                }
                disabled={generatingOTP}
              >
                Generate(
                {selectedLength})
              </Button>
            )}
          </div>
        </div>

        <div className="d-flex ph-2">
          <div>
            <Checkbox
              checked={showOrderDetails}
              onChange={() => setshowOrderDetails(!showOrderDetails)}
            />
            Details
          </div>
          <div>
            <Checkbox
              checked={selectedLength > 0}
              onChange={() => (selectedLength ? deselectAll() : selectAll())}
            />
            Select all
          </div>
        </div>
      </>
    );
  };

  const renderTabContainers = () => {
    const renderCheckbox = (orderDetails: OrdersInterface) => (
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        name={orderDetails.orderID}
        // classes={{ label: classes.filterOptionText }}
        onClick={() => onOrderSelect(orderDetails.orderID)}
        checked={
          getQueryParamJSONArray(
            AGENT_WH_OTP_GENERATION_SELECTED_ORDERS_QUERY_PARAM
          ).indexOf(orderDetails.orderID) > -1
        }
      />
    );

    const renderOrders = (orderDetails: OrdersInterface) =>
      showOrderDetails ? (
        <OrdersCard
          orderDetails={orderDetails}
          showAgentDetails={false}
          key={orderDetails.orderID}
          topRightIcon={renderCheckbox(orderDetails)}
        />
      ) : (
        <Paper square className={classes.simpleOrderView}>
          <div className={`mv-auto`}>{orderDetails.orderID}</div>{" "}
          <div>{renderCheckbox(orderDetails)}</div>
        </Paper>
      );

    switch (getQueryParam(AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM)) {
      case AGENT_WH_OTP_GENERATION_MODE_WH_DROP:
        return whDropOrderDetailsList.map((x, i) => renderOrders(x));
      case AGENT_WH_OTP_GENERATION_MODE_WH_PICK:
        return whPickOrderDetailsList.map((x, i) => renderOrders(x));
      default:
        return <></>;
    }
  };

  return (
    <>
      <Header title="Warehouse OTP" noClose />
      {renderAgentInfo()}
      {renderActionButtons()}

      <Tabs
        value={getQueryParam(AGENT_WH_OTP_GENERATION_MODE_QUERY_PARAM)}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant="fullWidth"
      >
        <Tab value={AGENT_WH_OTP_GENERATION_MODE_WH_DROP} label="WH DROP" />
        <Tab value={AGENT_WH_OTP_GENERATION_MODE_WH_PICK} label="WH PICK" />
      </Tabs>

      {renderTabContainers()}
    </>
  );
}

export default GenerateWHOTP;

import { ORDER_ACTIVE_INIT, ORDER_ACTIVE_INIT_ERROR, ORDER_INSERT, ORDER_UPDATE, AGENT_ACTIVE_INIT, AGENT_ACTIVE_INIT_ERROR, AGENT_INSERT, AGENT_UPDATE } from "global-constants/websocet";
import {
  AgentsInterface,
  setAgentErrorInit,
  unsetAgentErrorInit,
} from "../../../states/data/agents";
import {
  OrdersInterface,
  setOrderInitError,
  unsetOrderInitError,
} from "../../../states/data/orders";
import {
  incrementInitializerState,
  resetInitializerState,
  setConnected,
  setConnecting,
  setDisconnected,
} from "../../../states/websockets/connection";
import { updateAgentDocument } from "./agents";
import { updateAgentsWithFilter } from "./agents/filters";
import { updateOrdersWithFilter } from "./orders/filters";
import { pushNewOrderCreated, pushOrderUpdate } from "./orders/notifications";

const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT?.toString() || "";

let connection: WebSocket;

export interface WebsocketUpdateDescriptionOrder {
  removedFields: string[];
  updatedFields: {
    orderStatus?: string;
  };
}

export interface WebsocketUpdateDescriptionAgent {
  removedFields: string[];
  updatedFields: object;
}

interface WebsocketMessageInterface {
  code: number;
  responseType: string;
  data: {
    fullDocument: any;
    updateDescription:
      | WebsocketUpdateDescriptionOrder
      | WebsocketUpdateDescriptionAgent;
  };
}

const onClose = (dispatch) => (e) => {
  if (connection.readyState === connection.CLOSED) {
    dispatch(setDisconnected());
    setTimeout(() => {
      dispatch(WebsocketService());
    }, 3000);
  }
};

const onOpen = (dispatch) => (e) => {
  dispatch(setConnected());
};

const onMessage = (dispatch, getState) => (e) => {
  const data: WebsocketMessageInterface = JSON.parse(e.data);
  const ordersDataOrig: OrdersInterface[] =
    getState().ordersData.origOrdersData;
  const agentsDataOrig: AgentsInterface[] =
    getState().agentsData.origAgentsData;
  try {
    switch (data.responseType) {
      case ORDER_ACTIVE_INIT:
        dispatch(incrementInitializerState());
        dispatch(updateOrdersWithFilter(data.data));
        break;
      case ORDER_ACTIVE_INIT_ERROR:
        dispatch(setOrderInitError());
        break;
      case ORDER_INSERT:
        const newOrderData: OrdersInterface = data.data.fullDocument;
        pushNewOrderCreated(newOrderData);
        if (
          ordersDataOrig.findIndex(
            (x) => x.orderID === newOrderData.orderID
          ) === -1
        ) {
          const newData = [...ordersDataOrig];
          newData.push(newOrderData);
          dispatch(updateOrdersWithFilter(newData));
        }
        break;
      case ORDER_UPDATE:
        const orderData: OrdersInterface = data.data.fullDocument;
        pushOrderUpdate(orderData, data.data.updateDescription);
        dispatch(
          updateOrdersWithFilter(
            ordersDataOrig.map((x) =>
              x.orderID === orderData.orderID ? orderData : x
            )
          )
        );
        break;
      case AGENT_ACTIVE_INIT:
        dispatch(incrementInitializerState());
        dispatch(updateAgentsWithFilter(data.data));
        break;
      case AGENT_ACTIVE_INIT_ERROR:
        dispatch(setAgentErrorInit());
        break;
      case AGENT_INSERT:
        const newAgentData: AgentsInterface = data.data.fullDocument;
        if (
          agentsDataOrig.findIndex(
            (x) => x.agentID === newAgentData.agentID
          ) === -1
        ) {
          const newData = [...agentsDataOrig];
          newData.push(newAgentData);
          dispatch(updateAgentsWithFilter(newData));
        }
        break;
      case AGENT_UPDATE:
        const agentData: AgentsInterface = data.data.fullDocument;
        dispatch(
          updateAgentsWithFilter(
            agentsDataOrig.map((x) =>
              x.agentID === agentData.agentID ? agentData : x
            )
          )
        );
        break;
      default:
        // console.log("new response", data);
        break;
    }
  } catch (err) {
    console.error({
      "something went wrong in websocket data transformation": err,
    });
  }
};

const onError = (dispatch) => (e) => {
  console.log(`Error with live data source - ${e}`);
};

export const closeWebsocketService = () => (dispatch) => {
  try {
    connection.close()
    dispatch(setDisconnected());
  } catch {}
};

const WebsocketService = () => (dispatch) => {
  dispatch(closeWebsocketService());
  dispatch(setConnecting());

  connection = new WebSocket(WS_ENDPOINT + "?order_init=true&agent_init=true");

  dispatch(unsetAgentErrorInit());
  dispatch(unsetOrderInitError());
  dispatch(resetInitializerState());

  connection.addEventListener("open", dispatch(onOpen));
  connection.addEventListener("close", dispatch(onClose));
  connection.addEventListener("message", dispatch(onMessage));
  // connection.addEventListener("error", dispatch(onError));
};

export default WebsocketService;

import { packSizeKeyToWeight, vehicleTypeToLoadCapacityMap } from "global-constants/agents/info_map";
import { AgentsInterface } from "states/data/agents";

export const getAgentCarryingLoad = (onGoingOrders) => {
    if (onGoingOrders.length === 0) {
        return "0";
    }
    let orders = {}
    onGoingOrders.forEach(order => {
        if(["WH_DROP", "WH_PICK"].includes(order.type)) {
        order.orders.forEach(wh_order => {
            if (orders[wh_order.orderID]) {
            orders[wh_order.orderID].push(wh_order)
            } else {
            orders[wh_order.orderID] = [wh_order]
            }
        });
        } else {
        if (orders[order.orderID]) {
        orders[order.orderID].push(order)
        } else {
        orders[order.orderID] = [order]
        }
    }
    });
    let carryingWeight = 0;
    Object.keys(orders).forEach(ordersGroup => {
    if (orders[ordersGroup].length === 1) {
      if (orders[ordersGroup][0].type === "DROP") {
        carryingWeight += packSizeKeyToWeight[orders[ordersGroup][0]['packSize'] || 'MEDIUM']
      }
    }
  })
    return carryingWeight.toFixed(0); 
}

export const isAgentOverloaded = (agent_data: AgentsInterface) => {
  const agentCarryingLoad = getAgentCarryingLoad(agent_data.order_queue?.items || [])
  const agentLoadCapacity = vehicleTypeToLoadCapacityMap[agent_data.agent_info.vehicleInfo?.type || "absent_vehicle"]
  if (agentLoadCapacity === 0) {
    return false
  }
  return agentCarryingLoad > agentLoadCapacity
}


import React from 'react'
import OrdersLayer from './orders'
import AgentsLayer from './agents'

function DeckLayers() {
  return (
    <>
    <OrdersLayer />
    <AgentsLayer />
    </>
  )
}

export default DeckLayers
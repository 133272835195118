import APIReq from "services/api";

export const FailTask = (
  requestBody: {
    orderID: string;
    orderStatus: "PICKUPFAILED" | "DROPOFFFAILED";
    reasonCode: string;
    timestamp: number;
    agentComments: string;
  },
  onSuccess?,
  onFailure?,
  onComplete?
) => {
  APIReq.orders
    .orderFailed({
      orderID: requestBody.orderID,
      status: requestBody.orderStatus,
      reasonCode: requestBody.reasonCode,
      timestamp: requestBody.timestamp,
      agentComments: requestBody.agentComments
    })
    .then((res) => {
      onSuccess && onSuccess(res);
    })
    .catch((err) => {
      onFailure && onFailure(err?.response?.data?.message);
    })
    .finally(() => {
      onComplete && onComplete();
    });
};

import { PathLayer, TextLayer } from "deck.gl";
import Color from "color";
import polyline from "@mapbox/polyline";
import { HexColorList } from "global-constants/colors";
import { timeAfterSecond } from "utils/datetime";

function pathLayer(data) {
  const layer = new PathLayer({
    id: "agent-hovered-path-layer",
    data,
    pickable: true,
    getPath: (d: any) => d.coordinates,
    widthScale: 1,
    rounded: true,
    widthMinPixels: 4,
    // @ts-ignore
    getColor: (d, i) => {
      return Color(HexColorList[i.index]).rgb().array();
    },
    getWidth: (d) => 5,
  });
  return layer;
}

function textLayer(onGoing, routeMap) {

  const getText = (d, m?: any) => {
    let returnString: string = ''
    if (typeof m !== 'undefined') {
      returnString = `${timeAfterSecond(
        routeMap[m.index]?.cumulativeTravelTime
      )}`
    }
    return d.ref ? d.ref.slice(-12) + " " + returnString: d.type + " " + returnString;
  };

  const layer = new TextLayer({
    id: "agent-hovered-text-layer",
    data: onGoing,
    pickable: true,
    getPosition: (d: any) => [d.latlng.lng, d.latlng.lat],
    getText: getText,
    getSize: 20,
    getAngle: 0,
    getTextAnchor: "middle",
    getAlignmentBaseline: "center",
    backgroundColor: [0, 0, 0],
    getColor: [255, 255, 255, 130],
  });
  return layer;
}

export const renderonGoingPathLayer = (onGoingQueue, routeMap) => {
  const layers: any = [];
  const routeMapCoordinates = routeMap.map((x) =>
    polyline.toGeoJSON(x.geometry)
  );
  layers.push(pathLayer(routeMapCoordinates));
  layers.push(textLayer(onGoingQueue, routeMap));
  return layers;
};

import { Badge, Drawer, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Nav } from "rsuite";
import { agentsDataSlice } from "states/data/agents";
import { updateQueryParam, useQueryParams } from "utils/history";
import AgentTaskLogHistory from "./agent-task-log-history";
import AgentsSideBar from "./agents";
import AllAgentsDB from "./agents/AllAgentsDB";
import AssignOrdersSideBar from "./assign-order";
import GenerateWHOTP from "./generate-wh-otp";
import GenerateOrderOTP from "./generate-order-otp";
import OrdersSideBar from "./orders";
import ReorderAgentQueue from "./reorder-queue";
import { useEffect, useState } from "react";
import { ordersDataSlice } from "states/data/orders";
import OrderAuditLog from "apps/ops-dashboard/components/orders/OrderAuditLog";
import { AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_AGENT_DATA, SIDEBAR_TYPE_AGENT_TASK_LOG_HISTORY, SIDEBAR_TYPE_AGENTS, SIDEBAR_TYPE_ASSIGN_ORDERS, SIDEBAR_TYPE_GENERATE_ORDER_OTP, SIDEBAR_TYPE_GENERATE_WH_OTP, SIDEBAR_TYPE_REORDER_AGENT_QUEUE, SIDEBAR_TYPE_REORDER_ASSIGN_AGENT_QUEUE } from "global-constants/agents/query_params";
import { SIDEBAR_TYPE_ORDER_TIMELINE, SIDEBAR_TYPE_ORDERS } from "global-constants/orders/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";

const styles = makeStyles({
  buttonContainer: {
    position: "absolute",
    right: 32,
    top: 16,
  },
  navMenu: {
    position: "absolute",
    right: 0,
    top: 64,
  },
  navLeftSidebar: {
    right: 360,
  },
  navItem: {
    fontSize: 16,
    background: "rgba(255,255,255, 0.95)",
    padding: "0 !important",
    borderRadius: 0,
  },
  drawer: {
    background: "rgba(255,255,255, 0.93)",
    width: 360
  },
});

const RightSidebar = () => {
  const query = useQueryParams();
  const history = useHistory();
  const agentsData = useSelector(agentsDataSlice);
  const ordersData = useSelector(ordersDataSlice);

  const openSideBar = (sidebarType: string) => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, sidebarType));
  };

  const [sidebarQueryParam, setSidebarQueryParam] = useState<string|null>("")

  useEffect(() => {
    const sidebarQuery = query.get(SIDEBAR_QUERY_PARAM)
    if (sidebarQuery !== sidebarQueryParam) {
      setSidebarQueryParam(sidebarQuery)
    }
  }, [query])

  const renderSideBar = () => {
    switch (sidebarQueryParam) {
      case SIDEBAR_TYPE_ORDERS:
        return <OrdersSideBar />;
      case SIDEBAR_TYPE_AGENTS:
        return <AgentsSideBar />
      case SIDEBAR_TYPE_ASSIGN_ORDERS:
        return <AssignOrdersSideBar />
      case SIDEBAR_TYPE_REORDER_AGENT_QUEUE:
        return <ReorderAgentQueue preAssign={false}/>
      case SIDEBAR_TYPE_REORDER_ASSIGN_AGENT_QUEUE:
        return <ReorderAgentQueue preAssign={true}/>
      case SIDEBAR_TYPE_GENERATE_WH_OTP:
        return <GenerateWHOTP />
      case SIDEBAR_TYPE_AGENT_DATA:
        return <AllAgentsDB />
      case SIDEBAR_TYPE_AGENT_TASK_LOG_HISTORY:
        return <AgentTaskLogHistory />
      case SIDEBAR_TYPE_GENERATE_ORDER_OTP:
        return <GenerateOrderOTP />
      case SIDEBAR_TYPE_ORDER_TIMELINE:
        return <OrderAuditLog />
    }
  };

  const renderNav = () => {
    // If an agent is selected do not render the nav
    // The user may end up moving out of the task being performed
    // For example, when assigning orders, it should not switch to some other
    // sidebar
    if (query.get(AGENT_SELECTED_QUERY_PARAM)) {
      return;
    }

    return (
      <Nav
        vertical
        onSelect={openSideBar}
        activeKey={sidebarQueryParam}
        // style={styles}
        className={`${classes.navMenu} ${
          sidebarQueryParam ? classes.navLeftSidebar : ""
        }`}
      >
        <Nav.Item eventKey={SIDEBAR_TYPE_ORDERS} className={classes.navItem}>
          <div className={"font-lg m-1"}>
            <Badge badgeContent={ordersData ? ordersData.length : 0} color="primary" max={9999}>
            <strong>Orders</strong>
            </Badge>
          </div>
        </Nav.Item>
        <Nav.Item eventKey={SIDEBAR_TYPE_AGENTS} className={classes.navItem}>
          <div className={"font-lg m-1"}>
            <Badge badgeContent={agentsData ? agentsData.length : 0} color="primary" max={9999}>
              <Typography variant="subtitle1"><strong>Agents</strong>&nbsp;</Typography>
            </Badge>
          </div>
        </Nav.Item>
        <Nav.Item eventKey={SIDEBAR_TYPE_AGENT_DATA} className={classes.navItem}>
          <div className={"font-lg m-1"}>
            <strong>Agent Data</strong>
          </div>
        </Nav.Item>
      </Nav>
    );
  };

  const classes = styles();
  return (
    <>
      {renderNav()}
      <Drawer
        variant="persistent"
        anchor="right"
        open={!!sidebarQueryParam}

      >
        <div className={classes.drawer}>
        {renderSideBar()}
        </div>
      </Drawer>
    </>
  );
};

export default RightSidebar;

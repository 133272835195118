import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OrdersCard from "apps/ops-dashboard/components/orders/OrdersCard";
import { generateOrderSelectQueryParam } from "apps/ops-dashboard/services/orders/select-order";
import { OrdersInterface } from "states/data/orders";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getQueryParamList, useQueryParams } from "utils/history";
import OrderAssignActions from "./OrderAssignActions";
import { ORDERS_SELECTED_QUERY_PARAM } from "global-constants/orders/query_params";

const styles = makeStyles({
  accordianDetails: {
    display: "block",
    padding: 0,
  },
  accordianExpanded: {
    boxShadow: "none",
    borderBottom: "1px solid #e5e5ea",
    paddingBottom: 16,
  },
});

const SelectedOrders = ({ showActions = false, defaultExpanded = false, hideSelectOption = false }) => {
  const query = useQueryParams();
  const history = useHistory();
  const classes = styles();

  const [selectedOrders, setSelectedOrders] = useState<OrdersInterface[]>([]);
  const [selectedOrderIDs, setSelectedOrderIDs] = useState<string[]>([]);

  const origOrdersData = useSelector(
    (state: any) => state.ordersData.origOrdersData
  );

  useEffect(() => {
    if (
      selectedOrderIDs.toString() ===
      getQueryParamList(ORDERS_SELECTED_QUERY_PARAM).toString()
    ) {
      return;
    }
    setSelectedOrderIDs(getQueryParamList(ORDERS_SELECTED_QUERY_PARAM));
  }, [query]);

  useEffect(() => {
    if (!selectedOrderIDs.length) {
      setSelectedOrders([]);
      return;
    }

    const tempSelectedOrders: any[] = [];

    for (let order of origOrdersData) {
      if (selectedOrderIDs.includes(order.orderID)) {
        tempSelectedOrders.push(order);
      }
    }

    setSelectedOrders(tempSelectedOrders);
  }, [selectedOrderIDs, origOrdersData]);

  const onOrderSelect = (orderID) => {
    history.replace(generateOrderSelectQueryParam(orderID));
  };

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded} classes={{ expanded: classes.accordianExpanded }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge badgeContent={selectedOrders.length} color="primary">
            <Typography variant="subtitle1">Selected Orders&nbsp;</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails className={classes.accordianDetails}>
          {showActions ? <OrderAssignActions /> : ""}
          <div className="ph-2">
          {!selectedOrders.length ? <Typography variant="body1">No orders selected. Click on orders on map to select</Typography>: ""}
          </div>
          {selectedOrders.map((x, i) => (
            <OrdersCard
              key={i}
              orderDetails={x}
              onOrderSelect={hideSelectOption ? null : onOrderSelect}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SelectedOrders;

import { OrdersInterface } from "states/data/orders";
import { Subject } from "rxjs";
import { WebsocketUpdateDescriptionOrder } from "../websocket";
import { ORDER_CREATE, ORDER_UPDATE } from "global-constants/websocet";

export interface NotificationEventInterface {
  eventType: "ORDER_CREATE"|"ORDER_UPDATE",
  eventMessage: string,
  orderDetails: OrdersInterface,
  showAction?: boolean
}

export const notificationAlertSubject = new Subject<NotificationEventInterface>();

export const pushNewOrderCreated = (orderData: OrdersInterface) => {
  const event: NotificationEventInterface = {
    eventType: ORDER_CREATE,
    eventMessage: `New order ${orderData.orderID} created`,
    orderDetails: orderData,
    showAction: true
  }
  notificationAlertSubject.next(event)
}

export const pushOrderUpdate = (orderData: OrdersInterface, updateDescription: WebsocketUpdateDescriptionOrder) => {
  
  let message = ""
  if (["NEW", "PICKUPFAILED", "DROPOFFFAILED", "CANCELLED"].includes(updateDescription.updatedFields.orderStatus || "")) {
    message = `${orderData.orderID} updated to ${updateDescription.updatedFields.orderStatus}`
  } else {
    return
  }

  const event: NotificationEventInterface = {
    eventType: ORDER_UPDATE,
    eventMessage: message,
    orderDetails: orderData,
    showAction: updateDescription.updatedFields.orderStatus !== "CANCELLED"
  }

  notificationAlertSubject.next(event)
}
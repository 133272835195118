import { ArcLayer } from "@deck.gl/layers";
import { shipTypeIcons } from "../utils/icons";

const orderArcLayer = (
  data,
  hoveredOrder,
  onOrderClick,
  visible = true,
  id = "all-order-arc-layer",
  prominent = true
) => {
  return new ArcLayer({
    id,
    data,
    pickable: true,
    getWidth: 5,
    getHeight: 0.5,
    visible: visible,
    autoHighlight: true,
    // TODO - When order is in WH_DROP, the pick location should be WH
    getSourcePosition: (d: any) => [d.pickLocation.lng, d.pickLocation.lat],
    getTargetPosition: (d: any) => [d.dropLocation.lng, d.dropLocation.lat],
    getSize: (d: any) => 5,
    getSourceColor: prominent ? [0, 180, 0, 120] : [0, 180, 0, 60],
    getTargetColor: prominent ? [0, 100, 180, 120] : [0, 100, 180, 60],
    onHover: hoveredOrder,
    onClick: onOrderClick,
  });
};

export default orderArcLayer;

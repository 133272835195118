import { Badge, Drawer, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { updateQueryParam, useQueryParams } from "utils/history";
import { useEffect, useState } from "react";

import RunList from "apps/nucleus/components/run-list";
import RunDetails from "apps/nucleus/components/run-details";
import { SIDEBAR_TYPE_NUCLEUS_DETAILS } from "global-constants/nucleus/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";

const styles = makeStyles({
  buttonContainer: {
    position: "absolute",
    right: 32,
    top: 16,
  },
  navMenu: {
    position: "absolute",
    right: 0,
    top: 64,
  },
  navLeftSidebar: {
    right: 360,
  },
  navItem: {
    fontSize: 16,
    background: "rgba(255,255,255, 0.95)",
    padding: "0 !important",
    borderRadius: 0,
  },
  drawer: {
    background: "rgba(255,255,255, 0.93)",
    width: 360
  },
});

const RightSidebar = () => {
  const query = useQueryParams();
  const history = useHistory();

  const openSideBar = (sidebarType: string) => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, sidebarType));
  };

  const [sidebarQueryParam, setSidebarQueryParam] = useState<string|null>("")

  useEffect(() => {
    const sidebarQuery = query.get(SIDEBAR_QUERY_PARAM)
    if (sidebarQuery !== sidebarQueryParam) {
      setSidebarQueryParam(sidebarQuery)
    }
  }, [query])

  const renderSideBar = () => {
    switch (sidebarQueryParam) {
      case SIDEBAR_TYPE_NUCLEUS_DETAILS:
        return <RunDetails />;
      default:
        return <RunList />;
    }
  };

  const classes = styles();
  return (
    <>
      <Drawer
        variant="persistent"
        anchor="right"
        open
      >
        <div className={classes.drawer}>
        {renderSideBar()}
        </div>
      </Drawer>
    </>
  );
};

export default RightSidebar;

import { useDispatch, useSelector } from 'react-redux';
import { authDataSlice } from 'states/auth'

import './styles.scss';

import Login from './login'
import Loading from './loading'
import { useEffect } from 'react';
import { checkAdminAuth } from 'services/auth';


export default function Auth() {
  const authData = useSelector(authDataSlice)
  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkAdminAuth())
  }, [])

  if (authData.init) {
    return <Login />  
  }
  return <Loading />

}


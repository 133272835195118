import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";
import APIReq from "services/api";
import { AgentsInterface } from "states/data/agents";
import { useFetchRunDetailsFromRunID } from "./useFetchRunDetailsFromRunID";


export function useFetchAgentsFromRunID() {

    const{
        runDetails: runDetails, 
        loadingRunDetails: isInitiating, 
        hasError: hasError
    }=useFetchRunDetailsFromRunID();

    const agents = useQueries(
        runDetails ? {queries: runDetails?.agent_ids.map((agent_id) => {
          return {
            queryKey: ['agent', agent_id],
            queryFn: () => APIReq.AgentApis.getAgent(agent_id),
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: Infinity,
            enabled: !!runDetails.agent_ids.length
          }
        })} : {queries: []}
    
      );

    const fetchedAgents: Array<AgentsInterface> = useMemo(() => agents.filter(agent => agent.isFetched && agent.isSuccess && agent.data).map(agent => agent.data ? agent.data.data.data: null), [agents]);
    const agentsFetchProgress = useMemo(() => agents.filter(agent => agent.isFetched && agent.isSuccess).length/agents.length, [agents]);
    const agentsFetchErrorRate = useMemo(() => agents.filter(agent => agent.isFetched && agent.isError).length/agents.length, [agents]);

    

    return { agents, isInitiating, hasError, fetchedAgents, agentsFetchProgress, agentsFetchErrorRate };
}
import { IconLayer, TextLayer } from "deck.gl";
import { useDispatch, useSelector } from "react-redux";
import {
  agentsDataSlice,
  AgentsInterface,
  setHoveredAgent,
} from "../../../../../states/data/agents";

import { useEffect } from "react";
import { setLayerAgents } from "states/deck.gl/layers";
import {
  getQueryParam,
  unsetQueryParam,
  updateQueryParam,
  useQueryParams
} from "utils/history";
import { useHistory } from "react-router-dom";
import getAgentIcon from "./utils/agent-icon";
import { OrderAssignStatInterface, ordersAssignStatSlice } from "states/data/orders";
import { AGENT_SELECTED_QUERY_PARAM, SIDEBAR_TYPE_AGENTS, SIDEBAR_TYPE_ASSIGN_ORDERS } from "global-constants/agents/query_params";
import { HIDE_PACK_DETAILS_ON_ICONS_PARAM, SIDEBAR_QUERY_PARAM } from "global-constants/general";
import getAgentIconBackground from "./utils/agent-icon-background";
import getAgentVehicleIcon from "./utils/agent-vehicle-icon";
import agentStatusIcon from "./utils/agent-status-icon";
import { getAgentCarryingLoad } from "./utils/get_agent_carring_load";

const agentsLayer = (agentsData, hidePackDetailsOnIcons, onHoverAgent?, onAgentClick?) => {
  // const filteredAgentsData = (agentsData  && Object.values(agentsData)) || null
  // return new IconLayer({
  //   id: "agent-on-map-layer",
  //   data: agentsData,
  //   sizeScale: 40,
  //   sizeUnits: "meters",
  //   sizeMinPixels: 35,
  //   sizeMaxPixels: 56,
  //   pickable: true,
  //   visible: true,
  //   getIcon: (d: any) => ({
  //     url: getAgentIcon((d.to_ack_order_queue?.items || []), (d.order_queue?.items || []), d.onDutyMode,
  //     (d.agent_info.vehicleInfo || {type: null}).type,
  //     (d.agent_info.returnHome || {dontAssign: false}).dontAssign,
  //     (d.agent_info.returnHome || {enabled: false}).enabled, hidePackDetailsOnIcons),
  //     width: 128,
  //     height: 128,
  //     anchorY: 64,
  //   }),
  //   opacity: 0.6,
  //   getPosition: (d: any) => [
  //     d.agent_info.lastKnownLocation.lng,
  //     d.agent_info.lastKnownLocation.lat,
  //   ],
  //   getSize: () => 6,
  //   getTooltip: (d) => d.agent_info.name,
  //   onHover: onHoverAgent,
  //   onClick: (d) => onAgentClick(d),
  // });
  return [
    new IconLayer({
      id: "agent-on-map-background-layer",
      data: agentsData,
      sizeScale: 40,
      sizeUnits: "meters",
      sizeMinPixels: 35,
      sizeMaxPixels: 56,
      pickable: true,
      visible: true,
      getIcon: (d: any) => ({
        url: getAgentIconBackground((d.to_ack_order_queue?.items || []), 
        (d.order_queue?.items || []), d.onDutyMode,
        (d.agent_info.returnHome || {enabled: false}).enabled),
        width: 128,
        height: 128,
        anchorY: 64,
      }),
      opacity: 0.6,
      getPosition: (d: any) => [
        d.agent_info.lastKnownLocation.lng,
        d.agent_info.lastKnownLocation.lat,
      ],
      getSize: () => 6,
      getTooltip: (d) => d.agent_info.name,
      onHover: onHoverAgent,
      onClick: (d) => onAgentClick(d),
    }),
    new IconLayer({
      id: "agent-on-map-vehicle-layer",
      data: agentsData,
      sizeScale: 40,
      sizeUnits: "meters",
      sizeMinPixels: 35,
      sizeMaxPixels: 56,
      pickable: true,
      visible: true,
      getIcon: (d: any) => ({
        url: getAgentVehicleIcon((d.agent_info.vehicleInfo || {type: null}).type),
        width: 128,
        height: 128,
        anchorY: 64,
      }),
      opacity: 0.6,
      getPosition: (d: any) => [
        d.agent_info.lastKnownLocation.lng,
        d.agent_info.lastKnownLocation.lat,
      ],
      getSize: () => 6,
      getTooltip: (d) => d.agent_info.name,
      onHover: onHoverAgent,
      onClick: (d) => onAgentClick(d),
    }),
    new IconLayer({
      id: "agent-on-map-status-layer",
      data: agentsData,
      sizeScale: 40,
      sizeUnits: "meters",
      sizeMinPixels: 35,
      sizeMaxPixels: 56,
      pickable: true,
      visible: true,
      getIcon: (d: any) => ({
        url: agentStatusIcon((d.agent_info.returnHome || {dontAssign: false}).dontAssign),
        width: 128,
        height: 128,
        anchorY: 64,
      }),
      opacity: 0.6,
      getPosition: (d: any) => [
        d.agent_info.lastKnownLocation.lng,
        d.agent_info.lastKnownLocation.lat,
      ],
      getSize: () => 6,
      getTooltip: (d) => d.agent_info.name,
      onHover: onHoverAgent,
      onClick: (d) => onAgentClick(d),
    }),
    new TextLayer({
      id: "agent-on-map-carryingLoad-layer",
      data: agentsData,
      pickable: true,
      getPosition: (d: any) => [
        d.agent_info.lastKnownLocation.lng,
        d.agent_info.lastKnownLocation.lat,
      ],
      getText: (d: any) => getAgentCarryingLoad((d.order_queue?.items || [])),
      getSize: 16,
      visible: !hidePackDetailsOnIcons,
      getColor: [0, 0, 0, 160],
      getAngle: 0,
      getTextAnchor: "start",
      getAlignmentBaseline: "center",
      getPixelOffset: [5, -20],
      onHover: onHoverAgent,
      onClick: (d) => onAgentClick(d),
      backgroundColor: [255, 255, 255, 255],
      borderRadius: 50,
    })
  ]
};

const AgentsIconLayer = ({selectedAgentID}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  const agentsData: AgentsInterface[] = useSelector(agentsDataSlice);
  const origAgentsData: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );
  const orderAssignStat: OrderAssignStatInterface = useSelector(ordersAssignStatSlice)
  const hidePackDetailsOnIcons = query.get(HIDE_PACK_DETAILS_ON_ICONS_PARAM)

  const onHoverAgent = (hoverInfo) => {
    dispatch(setHoveredAgent(hoverInfo));
  };

  const onAgentClick = (objectInfo) => {
    const agentID = objectInfo?.object?.agentID
    if (orderAssignStat.assignMode) {
      return
    }
    if (getQueryParam(AGENT_SELECTED_QUERY_PARAM) === agentID) {
      history.replace(unsetQueryParam(AGENT_SELECTED_QUERY_PARAM))
      history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_AGENTS))
    } else {
      history.replace(
        updateQueryParam(AGENT_SELECTED_QUERY_PARAM, agentID)
      );
      history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_ASSIGN_ORDERS))
    }
  };

  const renderAgentsMap = () => {
    let agentData = selectedAgentID !== "" ? origAgentsData.filter((x) => selectedAgentID === x.agentID) : agentsData;
    const layer = agentsLayer(agentData, hidePackDetailsOnIcons, onHoverAgent, onAgentClick);
    dispatch(setLayerAgents(layer));
  };

  useEffect(renderAgentsMap, [agentsData, selectedAgentID]);

  return <></>;
};

export default AgentsIconLayer;

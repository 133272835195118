import { AgentCoordinateInterface, AgentsInterface } from "states/data/agents";
import { HTTPatch, HTTPDelete, HTTPGet, HTTPPost, HTTPPut } from "utils/http";
import { AxiosResponse } from "axios";
import { OrdersInterface } from "states/data/orders";
import { AgentTaskLogItemInterface } from "states/data/task-logs";

const PSERVER_URL = process.env.REACT_APP_ENDPOINT;

const AgentRoutes = {
  getOnDutyAgents: () => HTTPGet(`${PSERVER_URL}/m/admin/agents/on_duty`),
  getAgentRouteMap: (agentID: string) =>
    HTTPGet(`${PSERVER_URL}/m/admin/agent/${agentID}/routeMap`),
  getRearrangedRouteData: (agentID: string, onGoingOrders: any[], ignoreRoutes = false) =>
    HTTPPost(`${PSERVER_URL}/m/admin/agent/${agentID}/rearrange_route?ignorePolyline=${ignoreRoutes}`, {
      data: onGoingOrders,
    }),
  setRearrangedRoutes: (agentID: string, onGoingOrders: any[]) =>
    HTTPatch(`${PSERVER_URL}/m/admin/agent/${agentID}/rearrange_route`, {
      data: onGoingOrders,
    }),
  generateWHOTP: (
    agentID: string,
    body: {
      orderIDs: string[];
      taskType: string | "WH_DROP" | "WH_PICK";
      warehouseID: string;
    }
  ) => HTTPPost(`${PSERVER_URL}/m/admin/agent/${agentID}/warehouse_otp`, body),
  removeFromToDropAtCustomerQueue: (agentID: string, orderIDs: string[]) =>
    HTTPPost(`${PSERVER_URL}/m/admin/agent/${agentID}/unassign`, {
      orderIDs,
    }),
  turnOffDuty: (agentID: string) =>
    HTTPDelete(`${PSERVER_URL}/m/admin/agent/${agentID}/duty_mode`),
  allAgents: (page = 0, size = 10, query = "") =>
    HTTPGet(
      `${PSERVER_URL}/m/admin/all_agents?page=${page}&size=${size}&query=${query}`
    ),
  agentDetails: (agentID) =>
    HTTPGet(`${PSERVER_URL}/m/admin/agent/${agentID}`) as Promise<AxiosResponse<{
      data: AgentsInterface;
    }>>,
  agentTaskLogHistory: (agentID: string, start?: number, end?: number) =>
    HTTPGet(
      `${PSERVER_URL}/m/admin/agent/${agentID}/payout/per_km?start=${start}&end=${end}`
    ) as Promise<AxiosResponse<{
      data: {
        taskLogs: AgentTaskLogItemInterface[],
        polyline: string,
        orderDetailList: OrdersInterface[]
      };
    }>>,
  getAgentsPreassignOrderQueue: (agentID: string, data: {orders: Array<{orderID: string, dropAtWH: boolean, warehouseID?: string}>}, autoRouteArrange=false) =>
    HTTPPost(
      `${PSERVER_URL}/m/admin/agent/${agentID}/assign` + (autoRouteArrange ? '?autoRouteArrange=1' : ''),
       data
      //  TODO: get back here and change the type of axios response
    ) as Promise<AxiosResponse<{data: any}>>,
  assignOrdersToAgent: (agentID: string, data: {agentQueue: {items: Array<any>}}) => 
  HTTPatch(`${PSERVER_URL}/m/admin/agent/${agentID}/assign`, data) as Promise<AxiosResponse<{data: any}>>,
  getRouteCostRevenue: (agentID: string, data: {agentQueue: {items: Array<any>}}) => 
  HTTPPost(`${PSERVER_URL}/m/admin/ops/agents/agent_route/${agentID}`, data) as Promise<AxiosResponse<{data: any}>>,
  setNucleusExcluded: (agentID: string, nucleusExcluded: boolean,  onError = () => {}) =>
  HTTPPost(`${PSERVER_URL}/m/admin/ops/agents/nucleus_excluded`, {
    agentID: agentID,
    nucleusExcluded: nucleusExcluded
  }).then()
  .catch(onError)
  .finally(),

  agentReturnHomeLater: (agentID: string, returnTime: number, onError = () => {}) =>
  HTTPPut(`${PSERVER_URL}/m/admin/agent/${agentID}/return_home`, {
    when: 'LATER',
    returnTime: returnTime
  }).then()
  .catch(onError)
  .finally(),

  agentReturnHomeNow: (agentID: string, onError = () => {}) =>
  HTTPPut(`${PSERVER_URL}/m/admin/agent/${agentID}/return_home`, {
    when: 'NOW'
  }).then()
  .catch(onError)
  .finally(),

  agentReturnHomeCancel: (agentID: string, onError = () => {}) =>
  HTTPDelete(`${PSERVER_URL}/m/admin/agent/${agentID}/return_home`).then()
  .catch(onError)
  .finally(),

  agentSetDoNotAssign: (agentID: string, reset: boolean, onError = () => {}) =>
  HTTPPut(`${PSERVER_URL}/m/admin/agent/${agentID}/dont_assign?reset=${reset}`, {})
  .then()
  .catch(onError)
  .finally(),

  agentSetDoNotAssignCancel: (agentID: string, onError = () => {}) =>
  HTTPDelete(`${PSERVER_URL}/m/admin/agent/${agentID}/dont_assign`).then()
  .catch(onError)
  .finally(),

};

export default AgentRoutes;

import { createSlice } from "@reduxjs/toolkit";

export interface AgentInfo {
  name: string;
  phone: string;
  lastKnownLocation: {
    lat: number,
    lng: number,
    bearing: number,
    timestamp: number
  },
  returnHome?: {
    enabled: boolean;
    returnAt: number;
    dontAssign: boolean;
  },
  homeAddress?: {
    coordinates: {
      lat: number,
      lng: number
    },
  },
  vehicleInfo?: {
    type?: string,
  }
}

// export interface AgentsQueue {
//   on_going_orders: any[];
//   to_ack_orders: string[];
//   to_drop_at_wh: string[];
//   to_drop_at_customer: string[];
// }

export type AgentOrderQueueCategory = 'DROP'| 'PICK'| 'WH_DROP'| 'WH_PICK';
export interface OrderQueueItem {
  orderID: string;
  type: AgentOrderQueueCategory;
  ref: string;
  shipType: string;
  latlng: {
    lat: number,
    lng: number
  };
  packSize: number;
  SLA: number;
}

export interface WhOrderQueueItem {
  type: 'WH_PICK'| 'WH_DROP';
  orders: OrderQueueItem[];
  warehouseID: string;
  latlng: {
    lat: number,
    lng: number
  };
  "SLA": number;
}

export interface AgentOrderQueue {
  items: Array<OrderQueueItem | WhOrderQueueItem>;
  updated_at: number;
  updated_by: string;
}

export interface AgentsInterface {
  agentID: string;
  agent_info: AgentInfo;
  onDutyMode: boolean;
  order_queue: AgentOrderQueue;
  to_ack_order_queue: AgentOrderQueue;
  onDutyRegion: number;
  nucleusExcluded?: boolean;
}

export interface HoveredAgentRouteData {
  requestedQueue: HoverAgentRequestedQueue[];
  routeData?: HoverAgentRouteData[];
  reorderQueue: HoverAgentRequestedQueue[];
  travelSpeed?: number;
  requestedAt?: number;
  routeCost?: number | string;
  routeRevenue?: number | string;
}
interface HoverAgentRequestedQueue {
  orderID: string;
  type: string;
  ref: string;
  shipType: string;
  latlng: Latlng;
  SLA?: number;
}
interface Latlng {
  lat: number;
  lng: number;
}
export interface HoverAgentRouteData {
  distance: number;
  duration: number;
  geometry: string;
  cumulativeTravelTime: number;
}

export interface AgentCoordinateInterface {
  coordinates: [number, number],
  type: string
}

const hoveredAgent: { object: AgentsInterface } | null = null;

const initialState: {
  agentsData: AgentsInterface[];
  origAgentsData: AgentsInterface[];
  hoveredAgent: { object: AgentsInterface, sidebarHover: boolean } | null;
  hoveredAgentRouteMap: HoveredAgentRouteData
  initError: boolean;
} = {
  agentsData: [],
  origAgentsData: [],
  hoveredAgent,
  initError: false,
  hoveredAgentRouteMap: {
    requestedQueue: [],
    routeData: [],
    reorderQueue: [],
    travelSpeed: 0,
    requestedAt: 0,
    routeCost: 0,
    routeRevenue: 0
  }
};

const agentsData = createSlice({
  name: "agentsData",
  initialState,
  reducers: {
    setAgentsData: (state, action) => {
      state.origAgentsData = action.payload || [];
    },
    updateAgentsData: (state, action) => {
      state.agentsData = action.payload || [];
    },
    updateOrigAgentsData: (state, action) => {
      state.origAgentsData = action.payload || [];
    },
    setHoveredAgent: (state, action: { payload: any }) => {
      state.hoveredAgent = action.payload;
    },
    setAgentErrorInit: (state) => {
      state.initError = true;
    },
    unsetAgentErrorInit: (state) => {
      state.initError = false;
    },
    updateRouteData: (state, action: { payload: HoveredAgentRouteData }) => {
      const lastRequest = state.hoveredAgentRouteMap.requestedAt ? state.hoveredAgentRouteMap.requestedAt : 0
      if(lastRequest<action.payload.requestedAt!)
      state.hoveredAgentRouteMap = action.payload
    },
    setRouteData: (state, action: { payload: {requestedAt: number, routeData: HoverAgentRouteData[], routeQueue: HoverAgentRequestedQueue[]} }) => {
      const lastRequest = state.hoveredAgentRouteMap.requestedAt ? state.hoveredAgentRouteMap.requestedAt : 0
      if(lastRequest<action.payload.requestedAt!){
      state.hoveredAgentRouteMap.routeData = action.payload.routeData
      state.hoveredAgentRouteMap.requestedAt = action.payload.requestedAt
      state.hoveredAgentRouteMap.requestedQueue = action.payload.routeQueue
    }
    },
    setRouteDataWithCostRevenue: (state, action: { payload: {requestedAt: number, routeData: HoverAgentRouteData[],
       routeQueue: HoverAgentRequestedQueue[], routeCost: number|string, routeRevenue: number|string} }) => {
      const lastRequest = state.hoveredAgentRouteMap.requestedAt ? state.hoveredAgentRouteMap.requestedAt : 0
      if(lastRequest<action.payload.requestedAt!){
        state.hoveredAgentRouteMap.routeData = action.payload.routeData
        state.hoveredAgentRouteMap.requestedAt = action.payload.requestedAt
        state.hoveredAgentRouteMap.requestedQueue = action.payload.routeQueue
        state.hoveredAgentRouteMap.routeCost = action.payload.routeCost
        state.hoveredAgentRouteMap.routeRevenue = action.payload.routeRevenue
      }
    },
    setRouteDataOnly: (state, action: { payload: {requestedAt: number, routeData: HoverAgentRouteData[]} }) => {
      const lastRequest = state.hoveredAgentRouteMap.requestedAt ? state.hoveredAgentRouteMap.requestedAt : 0
      if(lastRequest<action.payload.requestedAt!)
      state.hoveredAgentRouteMap.routeData = action.payload.routeData
      state.hoveredAgentRouteMap.requestedAt = action.payload.requestedAt
    },
    updateHoveredAgentReorderedQueue: (state, action: {payload: HoverAgentRequestedQueue[]}) => {
      state.hoveredAgentRouteMap.reorderQueue = action.payload
    },
    unsetRouteData: (state) => {
      state.hoveredAgentRouteMap = {
        requestedQueue: [],
        routeData: [],
        travelSpeed: 0,
        reorderQueue: [],
        requestedAt: state.hoveredAgentRouteMap.requestedAt,
        routeCost: 0,
        routeRevenue: 0
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setAgentsData,
  setHoveredAgent,
  updateAgentsData,
  updateOrigAgentsData,
  setAgentErrorInit,
  unsetAgentErrorInit,
  updateRouteData,
  setRouteData,
  updateHoveredAgentReorderedQueue,
  unsetRouteData,
  setRouteDataOnly,
  setRouteDataWithCostRevenue
} = agentsData.actions;
export const agentsDataSlice = (state) => state.agentsData.agentsData;
export const hoveredAgentSlice = (state) => state.agentsData.hoveredAgent;
export default agentsData.reducer;

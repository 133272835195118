import { HoverAgentRouteData, setRouteData, setRouteDataOnly, setRouteDataWithCostRevenue } from "states/data/agents";
import APIReq from "services/api";
import { getQueryParam } from "utils/history";
import { AGENT_SELECTED_QUERY_PARAM } from "global-constants/agents/query_params";

export const setHoveredAgentRouteMap =
  (agentID, onGoingQueue, lastKnownCoordinates, ignoreRoutes = false) => (dispatch, getState) => {
    const requestedAt = Date.now();
    const agentCoords = {
      lat: lastKnownCoordinates.lat,
      lng: lastKnownCoordinates.lng
    };
    let coordinatesQueue = [agentCoords, ...onGoingQueue.map(order => order.latlng)]
    APIReq.general
      .getRouteData(coordinatesQueue)
      .then((res: { data: {data: { routeData: HoverAgentRouteData[] }} }) => {
        const activeID =
          getState().agentsData.hoveredAgent?.object?.agentID ||
          getQueryParam(AGENT_SELECTED_QUERY_PARAM);
        if (activeID === agentID) {
          dispatch(setRouteData({routeData: res.data.data.routeData, routeQueue: onGoingQueue, requestedAt: requestedAt}))
        }
      })
      .catch((err) => console.log(err));
  };

export const setAgentRouteMapWithCostRevenue = 
  (agentID, onGoingQueue) => (dispatch, getState) => {
    const requestedAt = Date.now();
    APIReq.agents.getRouteCostRevenue(agentID, {agentQueue: {items: onGoingQueue}})
      .then((res: { data: {data: { routeData: HoverAgentRouteData[], cost: number | string, revenue: number | string }} }) => {
        const activeID =
          getState().agentsData.hoveredAgent?.object?.agentID ||
          getQueryParam(AGENT_SELECTED_QUERY_PARAM);
        if (activeID === agentID) {
          dispatch(setRouteDataWithCostRevenue({
            routeData: res.data.data.routeData,
            routeQueue: onGoingQueue,
            requestedAt: requestedAt,
            routeCost: (res.data.data.cost as number).toFixed(2),
            routeRevenue: (res.data.data.revenue as number).toFixed(2),
          }))
        }
      }
      )
      .catch((err) => console.log(err));
  };
  
import DeckGL from "@deck.gl/react";
import { useSelector, useDispatch } from "react-redux";
import { mapViewPortSlice, updateViewPort } from "../../../states/deck.gl/maps";
import ReactMapGL from "react-map-gl";
import { nucleusLayersSlice } from "states/nucleus/deck.layers";

function DeckView(props) {
  const mapViewPort = useSelector(mapViewPortSlice);
  const layers = useSelector(nucleusLayersSlice);

  const dispatch = useDispatch();

  return (
    <>
      <div className={`map-view`}>
        <DeckGL
          initialViewState={mapViewPort}
          layers={layers}
          // onClick={onMapClick}
          // doubleClickZoom={false}
          controller={true}
          onViewStateChange={(v) => {
            dispatch(updateViewPort(v.viewState));
          }}
          getCursor={(state: any)=> state.isHovering ? "pointer": "default"}
          getTooltip={({object}) => object && object['name']}
        >
          <ReactMapGL
            reuseMaps
            // mapStyle='mapbox://styles/mapbox/light-v11'
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            preventStyleDiffing={true}
          ></ReactMapGL>
        </DeckGL>
        <div className={`child-view`}>{props.children}</div>
      </div>
    </>
  );
}
export default DeckView;
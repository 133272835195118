import { createSlice } from "@reduxjs/toolkit";
import { FlyToInterpolator, LinearInterpolator } from "react-map-gl";

const mapViewPort = createSlice({
  name: "mapViewPort",
  initialState: {
    latitude: 12.9085315,
    longitude: 77.597675,
    zoom: 12,
    bearing: 0,
    pitch: 0,
    transitionInterpolator: new LinearInterpolator(),
    transitionDuration: 200
  },
  reducers: {
    updateLatLng: (
      state,
      action: { payload: { lat: number; lng: number } }
    ) => {
      state.latitude = action.payload.lat;
      state.longitude = action.payload.lng;
    },
    updateZoom: (state, action: {payload: number}) => {
      state.zoom = action.payload
    },
    updateViewPort: (state, action: {payload: object}) => {
      state.latitude = action.payload['latitude']
      state.longitude = action.payload['longitude']
      state.zoom = action.payload['zoom']
      state.bearing = action.payload['bearing']
      state.pitch = action.payload['pitch']
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateLatLng, updateZoom, updateViewPort } = mapViewPort.actions;
export const mapViewPortSlice = (state) => state.mapViewPort;
export default mapViewPort.reducer;

import { useSnackbar } from "notistack";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloseIcon from "@material-ui/icons/Close";
import WebsocketService, { closeWebsocketService } from "./services/websocket";
import {
  WebsocketConnectionStateInterface,
} from "../../states/websockets/connection";
import {
  notificationAlertSubject,
  NotificationEventInterface,
} from "./services/orders/notifications";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getQueryParam, updateQueryParam, useQueryParams } from "utils/history";
import { filter } from "rxjs/operators";
import { DISABLE_WEBSOCKET_SUCCESS_NOTIFICATIONS, SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { ORDER_SEARCH_QUERY_PARAM, SIDEBAR_TYPE_ORDERS } from "global-constants/orders/query_params";
// import { filter } from "rxjs/internal/operators/filter";

function ConnectToSockets(props) {
  const dispatch = useDispatch();
  const query = useQueryParams();
  const history = useHistory();
  const snackbar = useSnackbar();
  const snackBarErrorAgentKey = useRef<any>(null);
  const snackBarLoadingData = useRef<any>(null);

  const agentInitError = useSelector(
    (state: any) => state.agentsData.initError
  );
  const orderInitError = useSelector(
    (state: any) => state.ordersData.initError
  );
  const connectionState: WebsocketConnectionStateInterface = useSelector(
    (state: any) => state.websocketConnection
  );

  const connect = () => {
    dispatch(WebsocketService());
  };

  const filterOrderInSideBar = (orderID) => {
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_ORDERS));
    history.replace(updateQueryParam(ORDER_SEARCH_QUERY_PARAM, orderID));
  };

  const showNotification = (event: NotificationEventInterface) => {
    switch (event.eventType) {
      case "ORDER_CREATE":
        snackbar.enqueueSnackbar(event.eventMessage, {
          variant: "success",
          autoHideDuration: 7000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <>
            <Button
              style={{ color: "white" }}
              onClick={() => filterOrderInSideBar(event.orderDetails.orderID)}
            >
              Show
            </Button>
            <IconButton
              style={{ color: "white" }}
              onClick={() => snackbar.closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            </>
          )
        });
        break;
      case "ORDER_UPDATE":
        snackbar.enqueueSnackbar(event.eventMessage, {
          variant: "default",
          autoHideDuration: 7000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => event.showAction && (
            <>
            <Button
              style={{ color: "white" }}
              onClick={() => filterOrderInSideBar(event.orderDetails.orderID)}
            >
              Show
            </Button>
            <IconButton
              style={{ color: "white" }}
              onClick={() => snackbar.closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            </>
          )
        });
    }
  };

  // If there are errors when loading initial orders and agents data
  useEffect(() => {
    let errorType = "";
    if (orderInitError) {
      errorType = "orders";
    } else if (agentInitError) {
      errorType = "agents";
    }
    if (errorType !== "") {
      snackbar.closeSnackbar(snackBarLoadingData.current);
      snackBarErrorAgentKey.current = snackbar.enqueueSnackbar(
        `Error loading ${errorType} data`,
        {
          preventDuplicate: true,
          persist: true,
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
    } else {
      snackbar.closeSnackbar(snackBarErrorAgentKey.current);
    }
  }, [orderInitError, agentInitError]);

  // If there are errors/initial orders haven't been loaded yet
  useEffect(() => {
    snackbar.closeSnackbar(snackBarLoadingData.current);
    snackBarLoadingData.current = null;
    if (connectionState.errored) {
      snackBarLoadingData.current = snackbar.enqueueSnackbar(
        connectionState.errorMessage,
        {
          persist: true,
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
    } else if (connectionState.initalizing < 1) {
      if (snackBarLoadingData.current) {
        return;
      }
      snackBarLoadingData.current = snackbar.enqueueSnackbar(
        `Loading Data...`,
        {
          persist: true,
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
    } else if ((!connectionState.connected && !connectionState.connecting)) {
      snackbar.closeSnackbar(snackBarLoadingData.current);
      snackBarLoadingData.current = snackbar.enqueueSnackbar(
        `Disconnected from server`,
        {
          persist: true,
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      )
    }
  }, [connectionState]);

  // On alert events
  useEffect(() => {
    connect();
    const subscription = notificationAlertSubject.pipe(filter(ev => !getQueryParam(DISABLE_WEBSOCKET_SUCCESS_NOTIFICATIONS))).subscribe(showNotification);
    return () => {
      dispatch(closeWebsocketService());
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "fixed",
          right: query.get(SIDEBAR_QUERY_PARAM) ? 368 : 8,
          bottom: 8,
          zIndex: 99,
        }}
      >
        <IconButton onClick={connect}>
          <RefreshIcon />
        </IconButton>
      </div>
    </>
  );
}

export default ConnectToSockets;

import { Chip, Dialog, DialogTitle } from "@material-ui/core";
import AgentInfoAccordian from "apps/ops-dashboard/components/agents/AgentInfoAccordian";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgentsInterface } from "states/data/agents";
import { resetWHOTP, WHOTPInterface } from "states/data/orders";
import { useQueryParams } from "utils/history";

function WHOTPDialog() {
  const dispatch = useDispatch();

  const whOTP: WHOTPInterface = useSelector(
    (state: any) => state.ordersData.WHOTP
  );
  const allAgents: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );
  const selectedAgentData = allAgents.find((x) => x.agentID === whOTP.agentID);
  const taskType =
    whOTP.taskType === "WH_DROP" ? "Warehouse Drop" : "Warehouse Pick";

  if (whOTP.whOTP === "") {
    return <></>
  }

  return (
    <Dialog
      onClose={() => dispatch(resetWHOTP())}
      aria-labelledby="wh-otp-dialog"
      open={true}
    >
      <DialogTitle id="wh-otp-dialog-title">OTP - {taskType}</DialogTitle>
      {selectedAgentData && (
        <AgentInfoAccordian agentData={selectedAgentData} />
      )}
      <h1 className={`t-center`}>{whOTP.whOTP}</h1>
      <div className={`d-flex flex-wrap p-1 justify-center`}>
        {whOTP.orderIDs.map((x, i) => (
          <div className="m-1" key={i}>
            <Chip label={x} />
          </div>
        ))}
      </div>
    </Dialog>
  );
}

export default WHOTPDialog;

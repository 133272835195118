import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLayerAgentRoute, setLayerAgentSelectedHome } from "../../../../../states/deck.gl/layers";

import { renderonGoingPathLayer } from "./layers/onGoingPathLayer";
import { AgentsInterface, HoveredAgentRouteData, unsetRouteData, updateRouteData } from "states/data/agents";
import { setAgentRouteMapWithCostRevenue } from "apps/ops-dashboard/services/agents/routemap";
import { useQueryParams } from "utils/history";
import agentHomeArcLayer from "./layers/AgentHomeArcLayer";

const AgentRouteMap = ({ selectedAgentID }) => {
  const dispatch = useDispatch();
  const query = useQueryParams();

  const hoveredAgent = useSelector(
    (state: any) => state.agentsData.hoveredAgent
  );
  const origAgentsData: AgentsInterface[] = useSelector(
    (state: any) => state.agentsData.origAgentsData
  );
  const hoveredAgentRouteMap: HoveredAgentRouteData = useSelector(
    (state: any) => state.agentsData.hoveredAgentRouteMap
  );

  const [activeAgentID, setActiveAgentID] = useState("");

  // Set the agent ID whose current routemap is shown or not if empty agent id
  useEffect(() => {
    const agentID = hoveredAgent?.object?.agentID || selectedAgentID || "";

    // On reload of page, this data will not be loaded, it'll be waiting on ws
    // and that will cause agent route to not render
    // This fixes that issue
    if (!origAgentsData.length) {
      return
    }
    if (agentID === activeAgentID) {
      return;
    }
    setActiveAgentID(agentID);
  }, [hoveredAgent, selectedAgentID, origAgentsData]);

  const renderOnAgentHover = () => {
    if (!activeAgentID || activeAgentID === "") {
      dispatch(setLayerAgentRoute(null));
      dispatch(setLayerAgentSelectedHome(null));
      dispatch(unsetRouteData())
      return
    }
    const agentData = origAgentsData.find((x) => x.agentID === activeAgentID);
    const agent_on_going_queue = agentData?.order_queue.items || [];
    if(agentData?.agent_info.lastKnownLocation) {
      // dispatch(setHoveredAgentRouteMap(activeAgentID, agent_on_going_queue, agentData?.agent_info.lastKnownLocation, (query.get(AGENT_IGNORE_POLYLINE) === '1')));
      dispatch(setAgentRouteMapWithCostRevenue(activeAgentID, agent_on_going_queue))
      if (agentData?.agent_info.returnHome?.enabled){
        if(agentData?.agent_info.homeAddress) {
          dispatch(setLayerAgentSelectedHome([agentHomeArcLayer([agentData?.agent_info])]))
        }
      }
    }
    if (!agent_on_going_queue.length) {
      return;
    }

    
  };
  useEffect(renderOnAgentHover, [activeAgentID]);

  useEffect(() => {
    dispatch(setLayerAgentRoute(renderonGoingPathLayer(hoveredAgentRouteMap.requestedQueue, hoveredAgentRouteMap.routeData)));
  }, [hoveredAgentRouteMap])
  return <></>;
};

export default AgentRouteMap;

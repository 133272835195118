export const vehicleTypeToLoadCapacityMap = {
    'bike': 20,
    'moped': 25,
    'truck': 750,
    'open_cargo_truck': 750,
    'pickup_truck': 750,
    'closed_cargo_truck': 750,
    'closed_truck': 750,
    'three_wheeler': 600,
    'open_cargo_rickshaw': 600,
    'closed_cargo_rickshaw': 600,
    'absent_vehicle': 0
}

export const packSizeKeyToWeight = {
    "X-SMALL": 0.5,
    "SMALL": 3,
    "MEDIUM": 7,
    "LARGE": 15,
    "X-LARGE": 25,
    "XX-LARGE": 50,
    "QUARTER-TRUCK-LOAD": 200,
    "HALF-TRUCK-LOAD": 350,
    "FULL-TRUCK-LOAD": 750,
  }


import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  RadioGroup,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useMemo } from "react";

import ClearIcon from '@material-ui/icons/Clear';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AppSelectIcon from '@rsuite/icons/AppSelect';


import { useHistory } from "react-router-dom";
import { getQueryParam, setQueryParam, unsetQueryParam, updateQueryParam, useQueryParams } from "utils/history";
import { CheckBox, FormatColorResetOutlined } from "@material-ui/icons";
import { sortedFilters } from "global-constants/orders/filters";
import { INVERT_FILTER_SELECTION, ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { FilterInterface, FilterOption, FilterOptionWithFilterType } from "types/filter-types";
import { clearOrderFilterQuery, generateFilterQuery } from "apps/ops-dashboard/services/filters";
import { AGENT_GENERAL_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const styles = makeStyles({
  filterItemContainer: {
    padding: "0 16px",
  },
  filterOptionText: {
    fontSize: "14px",
  },
  filterOptionCheckbox: {
    padding: "6px",
  },
});

const FilterTypeCheckBox = ({
  filterOption,
  isFilterSelected,
  onItemSelect,
  isRadio
}: {
  filterOption: FilterOption;
  isFilterSelected: Function;
  onItemSelect: Function;
  isRadio: boolean
}) => {
  const classes = styles();


  const filterItems = useMemo(() => {
    if(sortedFilters.includes(filterOption.key)){
      return filterOption.items.sort((biz1, biz2)=> biz1.name.toLowerCase().localeCompare(biz2.name.toLowerCase()))
    }
    return filterOption.items
  }, [filterOption])
  return (
    <>
      {filterItems.map((item, i) => (
        <div
          key={i}
          className={`${classes.filterOptionText} d-flex`}
          onClick={() => onItemSelect(filterOption, item.value)}
          style={{cursor: 'pointer'}}
        >
          <Checkbox
            size="small"
            title={item.name}
            className={classes.filterOptionCheckbox}
            checked={isFilterSelected(filterOption, item.value)}
            icon={isRadio?<RadioButtonUncheckedIcon />: <CheckBoxOutlineBlankIcon />}
            checkedIcon={isRadio?<RadioButtonCheckedIcon/> : <CheckBoxIcon />}
          />
          <div className={`mv-auto`} style={{userSelect: 'none'}} >{item.name}</div>
          <br />
        </div>
      ))}
    </>
  );
};


const FilterItem = ({
  filterOption,
  orderFilterQuery,
  agentFilterQuery,
  multiSelectOptions = false,
  allowFilterInversion = false,
}: {
  filterOption: FilterOption;
  orderFilterQuery: FilterInterface[];
  agentFilterQuery: FilterInterface[];
  multiSelectOptions?: boolean;
  allowFilterInversion?: boolean;
}) => {
  const classes = styles();
  const history = useHistory();
  const query = useQueryParams();

  const invertedSelection = useMemo(() => JSON.parse(query.get(INVERT_FILTER_SELECTION)||"[]").includes(filterOption.key), [query])

  useLayoutEffect(() => {
    const afq = JSON.parse(getQueryParam(AGENT_GENERAL_FILTER_QUERY_PARAM) || "[]");
    const ofq = JSON.parse(getQueryParam(ORDER_FILTER_QUERY_PARAM) || "[]");
    if (afq.length<1){
      history.replace(
        updateQueryParam(AGENT_GENERAL_FILTER_QUERY_PARAM, generateFilterQuery('vehicleCategory', "lightLoadVehicle", 'agent'))
      );
    }
    if (ofq.length<1){
      history.replace(
        updateQueryParam(ORDER_FILTER_QUERY_PARAM, generateFilterQuery('requestedAtTimestamp', 0, 'order'))
      );
    }
  }, []);

  const onItemSelect = (filterOption: FilterOptionWithFilterType, val: string) => {
    const key = filterOption.key
    const filterQueryParam = filterOption.filterOn === 'order' ? ORDER_FILTER_QUERY_PARAM : AGENT_GENERAL_FILTER_QUERY_PARAM

    history.replace(
      updateQueryParam(filterQueryParam, generateFilterQuery(key, val, filterOption.filterOn))
    );

  };

  const isFilterSelected = (filterOption: FilterOptionWithFilterType, value: string) => {
    const key = filterOption.key
    const filterQuery = filterOption.filterOn === 'order' ? orderFilterQuery : agentFilterQuery
    const keyIndex = filterQuery.findIndex((x) => x.key === key);
    if (keyIndex > -1) {
      return filterQuery[keyIndex].values.indexOf(value) > -1;
    }
    return false;
  };

  const onSelectAll = (filterOption) => {
    history.replace(
      updateQueryParam(ORDER_FILTER_QUERY_PARAM, clearOrderFilterQuery(filterOption.key))
    );
    filterOption.items.forEach(item => onItemSelect(filterOption, item.value))
  } 

  const onInvertAll = (filterOption) => {
    filterOption.items.forEach(item => onItemSelect(filterOption, item.value))
  }

  const onClearAll = (filterOption) => {
    history.replace(
      updateQueryParam(ORDER_FILTER_QUERY_PARAM, clearOrderFilterQuery(filterOption.key))
    );
  }

  const onInvertFilterSelect = (filterOption) => {
    let invertedFilters = JSON.parse(
      getQueryParam(INVERT_FILTER_SELECTION) || "[]"
    );
    const filterIsInverted = invertedFilters.includes(filterOption.key)
    if (filterIsInverted) {
      invertedFilters = invertedFilters.filter(flt => flt!==filterOption.key)
    }
    else{
      invertedFilters.push(filterOption.key)
    }
    if(invertedFilters.length===0){
      history.replace(unsetQueryParam(INVERT_FILTER_SELECTION))
      return
    }
    const invertedFiltersStr = JSON.stringify(invertedFilters)
    history.replace(updateQueryParam(INVERT_FILTER_SELECTION, invertedFiltersStr))
  }

  const renderFilterBlocks = () => {
    switch (filterOption.elementType) {
      case "checkbox":
        return (
          <FilterTypeCheckBox
            filterOption={filterOption}
            isFilterSelected={isFilterSelected}
            onItemSelect={onItemSelect}
            isRadio={false}
          />
        );
      case "radio":
        return (
          <FilterTypeCheckBox
            filterOption={filterOption}
            isFilterSelected={isFilterSelected}
            onItemSelect={onItemSelect}
            isRadio={true}
          />
        );

      default:
        return <></>;
    }
  };

  if (filterOption.items.length) {
    return (
      <div key={filterOption.key} className={classes.filterItemContainer}>
        <div className="d-flex  pv-1 justify-space-between">
        <div className="font-lg">{filterOption.name}</div>
        {multiSelectOptions ? 
        <div>
          {allowFilterInversion ? 
                  <Tooltip title="Exclude selected" aria-label="exclude selected" placement="top-end">
                  <IconButton size="small" onClick={()=>onInvertFilterSelect(filterOption)} 
                  color={invertedSelection ? "secondary": "primary"}>
                    <AppSelectIcon/>
                  </IconButton>
                </Tooltip> : <></>  
        }
          <Tooltip title="Select all" aria-label="select all" placement="top-end">
            <IconButton size="small" onClick={()=>onSelectAll(filterOption)}><SelectAllIcon/></IconButton>
          </Tooltip>
          <Tooltip title="Invert selection" aria-label="invert selection" placement="top-end">
            <IconButton size="small" onClick={()=>onInvertAll(filterOption)}><IndeterminateCheckBoxIcon/></IconButton>
          </Tooltip>
          <Tooltip title="clear all" aria-label="clear all" placement="top-end" style={{color: 'red'}}>
            <IconButton size="small" onClick={()=>onClearAll(filterOption)}><ClearIcon/></IconButton>
        </Tooltip></div> : <></>}
        </div>
        <div className="">{renderFilterBlocks()}</div>
        <hr />
      </div>
    );
  }

  return <></>;
};

export default FilterItem;

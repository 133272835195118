import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { RUN_ID_QUERY_PARAM } from "global-constants/nucleus/query_params";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import APIReq from "services/api";
import { RunDetailsType } from "types/nucleus-types";
import { useQueryParams, getQueryParam } from "utils/history";


export function useFetchRunDetailsFromRunID() {

    const query = useQueryParams();
    const history = useHistory();
    
    const runID = useMemo(() => getQueryParam(RUN_ID_QUERY_PARAM), [query]);
  
    const {
      data: runDetails,
      isLoading: loadingRunDetails,
      error: hasError,
    }:UseQueryResult<RunDetailsType, any> = useQuery(["runDetails", runID], () => APIReq.nucleusApi.getRunDetails(runID||"").then(res => res.data.data), {
      enabled: !!runID,
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: Infinity
    });

    return {runDetails, loadingRunDetails, hasError}

}
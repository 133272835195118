import getAgentIconBackground from "apps/ops-dashboard/layouts/deck-layers/agents/utils/agent-icon-background";
import agentStatusIcon from "apps/ops-dashboard/layouts/deck-layers/agents/utils/agent-status-icon";
import getAgentVehicleIcon from "apps/ops-dashboard/layouts/deck-layers/agents/utils/agent-vehicle-icon";
import { IconLayer } from "deck.gl";

const allAgentIconLayerNucleus = (agentsData,
    //  hidePackDetailsOnIcons, 
    //  onHoverAgent?, 
    //  onAgentClick?
     ) => {

    return [
      new IconLayer({
        id: "agent-on-map-background-layer",
        data: agentsData,
        sizeScale: 40,
        sizeUnits: "meters",
        sizeMinPixels: 35,
        sizeMaxPixels: 56,
        pickable: true,
        visible: true,
        getIcon: (d: any) => ({
          url: getAgentIconBackground((d.to_ack_order_queue?.items || []), 
          (d.order_queue?.items || []), d.onDutyMode,
          (d.agent_info.returnHome || {enabled: false}).enabled),
          width: 128,
          height: 128,
          anchorY: 64,
        }),
        opacity: 0.6,
        getPosition: (d: any) => [
          d.agent_info.lastKnownLocation.lng,
          d.agent_info.lastKnownLocation.lat,
        ],
        getSize: () => 6,
        getTooltip: (d) => d.agent_info.name,
        // onHover: onHoverAgent,
        // onClick: (d) => onAgentClick(d),
      }),
      new IconLayer({
        id: "agent-on-map-vehicle-layer",
        data: agentsData,
        sizeScale: 40,
        sizeUnits: "meters",
        sizeMinPixels: 35,
        sizeMaxPixels: 56,
        pickable: true,
        visible: true,
        getIcon: (d: any) => ({
          url: getAgentVehicleIcon((d.agent_info.vehicleInfo || {type: null}).type),
          width: 128,
          height: 128,
          anchorY: 64,
        }),
        opacity: 0.6,
        getPosition: (d: any) => [
          d.agent_info.lastKnownLocation.lng,
          d.agent_info.lastKnownLocation.lat,
        ],
        getSize: () => 6,
        getTooltip: (d) => d.agent_info.name,
        // onHover: onHoverAgent,
        // onClick: (d) => onAgentClick(d),
      }),
      new IconLayer({
        id: "agent-on-map-status-layer",
        data: agentsData,
        sizeScale: 40,
        sizeUnits: "meters",
        sizeMinPixels: 35,
        sizeMaxPixels: 56,
        pickable: true,
        visible: true,
        getIcon: (d: any) => ({
          url: agentStatusIcon((d.agent_info.returnHome || {dontAssign: false}).dontAssign),
          width: 128,
          height: 128,
          anchorY: 64,
        }),
        opacity: 0.6,
        getPosition: (d: any) => [
          d.agent_info.lastKnownLocation.lng,
          d.agent_info.lastKnownLocation.lat,
        ],
        getSize: () => 6,
        getTooltip: (d) => d.agent_info.name,
        // onHover: onHoverAgent,
        // onClick: (d) => onAgentClick(d),
      }),
    //   new TextLayer({
    //     id: "agent-on-map-carryingLoad-layer",
    //     data: agentsData,
    //     pickable: true,
    //     getPosition: (d: any) => [
    //       d.agent_info.lastKnownLocation.lng,
    //       d.agent_info.lastKnownLocation.lat,
    //     ],
    //     getText: (d: any) => getAgentCarryingLoad((d.order_queue?.items || [])),
    //     getSize: 16,
    //     visible: !hidePackDetailsOnIcons,
    //     getColor: [0, 0, 0, 160],
    //     getAngle: 0,
    //     getTextAnchor: "start",
    //     getAlignmentBaseline: "center",
    //     getPixelOffset: [5, -20],
    //     // onHover: onHoverAgent,
    //     // onClick: (d) => onAgentClick(d),
    //     backgroundColor: [255, 255, 255, 255],
    //     borderRadius: 50,
    //   })
    ]
  };
  
export default allAgentIconLayerNucleus;
import { createSlice } from "@reduxjs/toolkit";

export interface FailReason {
  reasonCode: string,
  reason: string,
  newStatus: string
}

export interface TaskFailConfigInterface {
  pick: FailReason[],
  drop: FailReason[],
  toStatuses: {
    status: string,
    actionMessage: string
  }[]
}

export interface ServiceableRegionsInterface {
    "code": number,
    "name": string,
    "cityName": string,
    "state": string,
    "center": string,
    "country": string,
    "centerCoordinates": {
      "lat": number,
      "lng": number
    }
}

export interface WarehouseInterface {
  warehouseID : string,
  name : string,
  region : number,
  latitude : number, longitude: number,
  insertedAt :  number,
  active : boolean
}

const taskFailConfig: TaskFailConfigInterface = {
  pick: [],
  drop: [],
  toStatuses: []
};

const serviceableRegionConfig: ServiceableRegionsInterface[] = []

const warehousesConfig: WarehouseInterface[] = []

const initialState = {
  taskFailConfig,
  serviceableRegionConfig,
  warehousesConfig
};

const configsData = createSlice({
  name: "configsData",
  initialState,
  reducers: {
    setTaskFailConfig: (state, action: {payload: TaskFailConfigInterface}) => {
      state.taskFailConfig = action.payload
    },
    setServiceableRegionConfig: (state, action: { payload: ServiceableRegionsInterface[] }) => {
      state.serviceableRegionConfig = action.payload
    },
    setWarehousesConfig: (state, action: { payload: WarehouseInterface[] }) => {
      state.warehousesConfig = [...state.warehousesConfig, ...action.payload]
    },
    unsetWarehousesConfig: (state) => {
      state.warehousesConfig = warehousesConfig
    }
  } 
});

// Action creators are generated for each case reducer function
export const {
  setTaskFailConfig,
  setServiceableRegionConfig,
  setWarehousesConfig,
  unsetWarehousesConfig
} = configsData.actions;
export const taskFailConfigSlice = (state) => state.configsData.taskFailConfig;
export const serviceableConfigSlice = (state) => state.configsData.serviceableRegionConfig;
export const warehousesConfigSlice = (state) => state.configsData.warehousesConfig;

export default configsData.reducer;

import { createMuiTheme } from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: grey[900],
    },
    secondary: {
      main: orange[500],
    },
    text: {
      primary: grey[900],
      secondary: grey.A700
    }
  }
})
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { FailTask } from "apps/ops-dashboard/services/orders/fail-task";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FailReason, TaskFailConfigInterface } from "states/data/configs";
import { FailOrderTaskInterface, resetFailOrderTask } from "states/data/orders";
import DatePicker from "react-datepicker";

const styles = makeStyles({
  customText: {
    marginTop: 0,
  },
});

function FailOrderTaskDialog() {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const classes = styles();

  const failOrderTask: FailOrderTaskInterface = useSelector(
    (state: any) => state.ordersData.failOrderTask
  );

  const taskFailConfig: TaskFailConfigInterface = useSelector(
    (state: any) => state.configsData.taskFailConfig
  );

  const [reasonIndex, setReasonIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState<Date>(new Date());
  const [agentComments, setAgentComments] = useState("");
  const ref = useRef<DatePicker>();

  if (!failOrderTask) {
    return <></>;
  }

  const taskFailReasons =
    failOrderTask.orderStatus === "DROPOFFFAILED"
      ? taskFailConfig.drop
      : taskFailConfig.pick;

  const updateReasonCode = (e) => {
    setReasonIndex(parseInt(e.target.value));
  };

  const onSuccess = () => {
    dispatch(resetFailOrderTask());
  };

  const onFailure = (message: string) => {
    snackbar.enqueueSnackbar(message, { variant: "error" });
  };

  const resetState = () => {
    setReasonIndex(-1);
    setAgentComments("");
    setRescheduleDate(new Date());
    setLoading(false);
  }

  const submit = (e) => {
    setLoading(true);
    resetState();
    FailTask(
      {
        orderID: failOrderTask.orderID,
        orderStatus: failOrderTask.orderStatus,
        reasonCode: taskFailReasons[reasonIndex].reasonCode,
        timestamp: rescheduleDate.getTime(),
        agentComments: agentComments,
      },
      onSuccess,
      onFailure,
      () => {
        setLoading(false);
      }
    );
  };

  const renderLabel = (x: FailReason) => {
    const actionMessage = taskFailConfig.toStatuses.find(
      (y) => y.status === x.newStatus
    )?.actionMessage;
    return (
      <div>
        <div className="font-md">{x.reason}</div>
        <div className="font-sm">{actionMessage}</div>
        {x.newStatus === "RESCHEDULED" && (
          <DatePicker
            ref={ref}
            selected={rescheduleDate}
            onChange={(date) => setRescheduleDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            minDate={new Date()}
            inline
            // withPortal
            shouldCloseOnSelect={false}
            customInput={<Button>Reschedule</Button>}
          ></DatePicker>
        )}
        <hr style={{marginBottom: 0}}/>
      </div>
    );
  };

  return (
    <Dialog
      onClose={() => {dispatch(resetFailOrderTask()); resetState(); }}
      aria-labelledby="wh-otp-dialog"
      open={true}
    >
      <DialogTitle id="wh-otp-dialog-title">
        {failOrderTask.orderStatus === "PICKUPFAILED" && "PICKUPFAILED - "}
        {failOrderTask.orderStatus === "DROPOFFFAILED" && "DROPOFFFAILED - "}
        {failOrderTask.orderID}
      </DialogTitle>

      <div className={`ph-2 pv-2 d-flex flex-column`}>
        <FormControl component="fieldset" disabled={loading}>
          <FormLabel component="legend">Reason for failure</FormLabel>
          <div className="pv-1"></div>
          <RadioGroup
            name="taskFailOptions"
            value={reasonIndex}
            onChange={updateReasonCode}
          >
            {taskFailReasons.map((x, i) => (
              <div className="pt-1" key={i}>
                <FormControlLabel
                  value={i}
                  control={<Radio />}
                  label={renderLabel(x)}
                />
              </div>
            ))}
          </RadioGroup>
          <FormLabel component="legend">Admin comments</FormLabel>
          <div className="pv-1"></div>
          <textarea
            className="form-control"
            placeholder="Add comments here"
            rows={2}
            onChange={(e) => setAgentComments(e.target.value)}
            value={agentComments}
          ></textarea>
        </FormControl>

        <Button className="ml-auto mv-1" onClick={submit} disabled={loading}>
          SUBMIT
        </Button>
      </div>
    </Dialog>
  );
}

export default FailOrderTaskDialog;

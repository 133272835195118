import { InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { updateAgentsWithFilter } from 'apps/ops-dashboard/services/agents/filters'
import { AGENT_SEARCH_QUERY_PARAM } from 'global-constants/agents/query_params'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { unsetQueryParam, updateQueryParam, useQueryParams } from 'utils/history'

function AgentSearch() {

  const dispatch = useDispatch()
  
  const query = useQueryParams()
  const history = useHistory()

  const onSearchChange = (e) => {
    if (e.target.value !== "") {
      history.replace(updateQueryParam(AGENT_SEARCH_QUERY_PARAM, e.target.value))
    } else {
      history.replace(unsetQueryParam(AGENT_SEARCH_QUERY_PARAM))
    }
    dispatch(updateAgentsWithFilter());
  }

  return (
    <div className={"ph-2 w-100"}>
      <TextField
        id="search-orders"
        className={""}
        fullWidth
        size="small"
        placeholder="Search by id, name, phone"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={onSearchChange}
        value={query.get(AGENT_SEARCH_QUERY_PARAM) || ""}
        autoComplete="off"
      />
    </div>
  )
}

export default AgentSearch

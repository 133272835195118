import agentFreeHomeIcon from "assets/new_icons/agents/state/queue/free/home.svg";
import agentFreeDefaultIcon from "assets/new_icons/agents/state/queue/free/default.svg";
import agentFreeWarehouseIcon from "assets/new_icons/agents/state/queue/free/warehouse.svg";

import agentOffDutyHomeIcon from "assets/new_icons/agents/state/queue/off_duty/home.svg";
import agentOffDutyDefaultIcon from "assets/new_icons/agents/state/queue/off_duty/default.svg";
import agentOffDutyWarehouseIcon from "assets/new_icons/agents/state/queue/off_duty/warehouse.svg";

import agentOrderLimitReachedHomeIcon from "assets/new_icons/agents/state/queue/order_limit_reached/home.svg";
import agentOrderLimitReachedDefaultIcon from "assets/new_icons/agents/state/queue/order_limit_reached/default.svg";
import agentOrderLimitReachedWarehouseIcon from "assets/new_icons/agents/state/queue/order_limit_reached/warehouse.svg";

import agentAssignedHomeIcon from "assets/new_icons/agents/state/queue/assigned/home.svg";
import agentAssignedDefaultIcon from "assets/new_icons/agents/state/queue/assigned/default.svg";
import agentAssignedWarehouseIcon from "assets/new_icons/agents/state/queue/assigned/warehouse.svg";

import agentAssignedOngoingHomeIcon from "assets/new_icons/agents/state/queue/assigned_ongoing/home.svg";
import agentAssignedOngoingDefaultIcon from "assets/new_icons/agents/state/queue/assigned_ongoing/default.svg";
import agentAssignedOngoingWarehouseIcon from "assets/new_icons/agents/state/queue/assigned_ongoing/warehouse.svg";

const agentBackgroundMap = {
    free: {
        default: agentFreeDefaultIcon,
        home: agentFreeHomeIcon,
        warehouse: agentFreeWarehouseIcon
    },
    off_duty: {
        default: agentOffDutyDefaultIcon,
        home: agentOffDutyHomeIcon,
        warehouse: agentOffDutyWarehouseIcon
    },
    order_limit_reached: {
        default: agentOrderLimitReachedDefaultIcon,
        home: agentOrderLimitReachedHomeIcon,
        warehouse: agentOrderLimitReachedWarehouseIcon
    },
    assigned: {
        default: agentAssignedDefaultIcon,
        home: agentAssignedHomeIcon,
        warehouse: agentAssignedWarehouseIcon
    },
    assigned_ongoing: {
        default: agentAssignedOngoingDefaultIcon,
        home: agentAssignedOngoingHomeIcon,
        warehouse: agentAssignedOngoingWarehouseIcon
    }
}

const getAgentIconBackground = (to_ack_orders, on_going_orders, onDutyMode,
    returnHome
   ) => {
  const toAckOnGoing = to_ack_orders.length && on_going_orders.length
  const onGoingOrders = on_going_orders
    ? on_going_orders.length > 0
      ? true
      : false
    : false;
  const toAckOrders = to_ack_orders
    ? to_ack_orders.length > 0
      ? true
      : false
    : false;
  const whDropOrders = on_going_orders
  ? on_going_orders.length == 1 && on_going_orders[0].type == "WH_DROP"
    ? true
    : false
  : false;
  let isOffline = !onDutyMode;
  let orders = {}
  on_going_orders.forEach(order => {
    if(["WH_DROP", "WH_PICK"].includes(order.type)) {
      order.orders.forEach(wh_order => {
        if (orders[wh_order.orderID]) {
          orders[wh_order.orderID].push(wh_order)
        } else {
          orders[wh_order.orderID] = [wh_order]
        }
      });
    } else {
    if (orders[order.orderID]) {
      orders[order.orderID].push(order)
    } else {
      orders[order.orderID] = [order]
    }
  }
  });
  let backgroundShape = "default";

  if (onDutyMode) {
    if (returnHome) {
      backgroundShape = "home";
    }
    if (whDropOrders) {
      backgroundShape = "warehouse";
    }
    if (toAckOnGoing) {
      return agentBackgroundMap.assigned_ongoing[backgroundShape];
    }
    if (onGoingOrders) {
      return agentBackgroundMap.order_limit_reached[backgroundShape];
    }
    if (toAckOrders) {
      return agentBackgroundMap.assigned[backgroundShape];
    }
    return agentBackgroundMap.free[backgroundShape];
  } else {
    return agentBackgroundMap.off_duty[backgroundShape];
  }
};

export default getAgentIconBackground;

// Call an API to get the list of runs and display them in cards
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { DateRangePicker, Loader, Pagination } from "rsuite";
import APIReq from "services/api";
import { updateQueryParam } from "utils/history";
import { useInfiniteQuery } from '@tanstack/react-query'
import { RunListType } from "types/nucleus-types";
import { HexColorList } from "global-constants/colors";
import { msToDate } from "utils/datetime";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { RUN_ID_QUERY_PARAM, SIDEBAR_TYPE_NUCLEUS_DETAILS } from "global-constants/nucleus/query_params";

const RunCard = ({ runDetails, colorCode }: {
  runDetails: RunListType;
  colorCode?: string;
}) => {
  const history = useHistory();
  const moveToDetailsPage = (runId: string) => {
    history.replace(updateQueryParam(RUN_ID_QUERY_PARAM, runId));
    history.replace(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_NUCLEUS_DETAILS));
  }
  return (
    <div className="card" style={
      {
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        padding: "16px",
        marginBottom: "16px",
        cursor: "pointer",
        backgroundColor: "whitesmoke"
      }} onClick={() => moveToDetailsPage(runDetails.run_id)}>
      <div className="card-body">

        <h5 className="card-text">
          {msToDate(runDetails.solution_generated_at)}
          {" "}
          {runDetails.solution_found ? "✅" : "❌"}
        </h5>
        <p className="card-text">
          Num agents: {runDetails.num_agents}<br />
          Num orders: {runDetails.num_orders}
        </p>
        <hr style={
          {
            borderColor: colorCode,
            borderWidth: "5px",
          }
        }/>
        <p>
          ID: {runDetails.run_id}
        </p>

      </div>
    </div>
  );
};


const RunList = () => {
  
  const [runs, setRuns] = useState<RunListType[]>([]);
  const [page, setPage] = useState(1);
  const [selectedRange, setSelectedRange] = useState<[(Date | undefined)?, (Date | undefined)?]>([new Date(), new Date()]);
  const {startTs, endTs} = useMemo(() => ({startTs: (selectedRange[0] as Date).getTime(), endTs: (selectedRange[1] as Date).getTime()}), [selectedRange])

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery<RunListType[], Error>({ 
    queryKey: ['runs', startTs, endTs],
    queryFn: async ({ pageParam = page-1 }) => await APIReq.nucleusApi.getRuns(startTs, endTs, pageParam).then((res) => res.data.data),
    getNextPageParam: (lastPage, pages) => lastPage.length < 10? undefined : pages.length+1,
  })

  const paginationElement = () => {
    return (
      <div className="d-flex justify-content-center">
        <Pagination
          prev
          last
          next
          first
          size="md"
          pages={hasNextPage? (data?.pages.length||1)+1 : (data?.pages.length||1)}
          activePage={page}
          onSelect={(e, page) => {
            if (e < (data?.pages.length||1)+1) setPage(e);
            if (hasNextPage && e === (data?.pages.length||1)+1){
              fetchNextPage();
              setPage(e);
            }
          }}
          maxButtons={3}
        />
      </div>
    );
  };

  const dateRangeElement = () => {
    return (
      <div style={{
        padding: "4px"
      }}>
        Date Range: <DateRangePicker showOneCalendar size="md"
        onChange={(dates)=>{setSelectedRange(dates)}}
        value={selectedRange}
        />
      </div>)
  };

  const viewRuns = () => {
    if (isFetching) return <Loader speed="fast" content="loading runs" />;

    return (
      <div>
        {data && (
          <>
            {`${page}, ${data.pages.length || 0}`}
            {data.pages[page - 1].map((run, index) => (
              <RunCard key={run.run_id} runDetails={run} colorCode={HexColorList[index]} />
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div style={{
      margin: "16px",
    }}>
      {dateRangeElement()}
      <h5>Runs</h5>
      <hr />
      {viewRuns()}
      {paginationElement()}
    </div>
  );
};

export default RunList;
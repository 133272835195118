import { Dialog, DialogTitle, makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrderTimelineMediaInterface, unsetOrderTimelineMedia } from 'states/data/orders'

const styles = makeStyles({
    mediaPaper: {
        display: 'grid',
        placeItems: 'center',
        height: 600,
        width: 600
    }
  });

function OrderTimelineMediaDialog() {
    const classes = styles();

    const dispatch = useDispatch()
    const orderTimelineMedia: OrderTimelineMediaInterface = useSelector(
        (state: any) => state.ordersData.orderTimelineMedia
    );
    function decamelize(str){
        return str
            .replace(/([a-z\d])([A-Z])/g, '$1 $2')
            .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2')
            .toLowerCase()
    }
    if (orderTimelineMedia.mediaUrl === "") {
        return <></>
    }
    return (
        <Dialog
            onClose={() => dispatch(unsetOrderTimelineMedia())}
            aria-labelledby="order-timeline-dialog"
            open={true}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="order-timeline-dialog-title">{decamelize(orderTimelineMedia.mediaFrom)}</DialogTitle>
            <Paper className={classes.mediaPaper}>
                <img src={orderTimelineMedia.mediaUrl} alt="media" width="480" />
            </Paper>
        </Dialog>
    )
}

export default OrderTimelineMediaDialog

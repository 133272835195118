import { Paper } from "@material-ui/core";
import Header from "apps/ops-dashboard/layouts/right-sidebar/header";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import { ActiveOrderTimeline, SIDEBAR_TYPE_ORDERS } from "global-constants/orders/query_params";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Badge, Button, Icon, Panel, Timeline } from "rsuite";
import APIReq from "services/api";
import { AgentsInterface } from "states/data/agents";
import { setOrderTimelineMedia } from "states/data/orders";
import { tsToTime } from "utils/datetime";
import { getQueryParam, setQueryParam, unsetQueryParam, updateQueryParam, useQueryParams } from "utils/history";

export interface auditLogInterface {
  orderID: string;
  userID: string;
  audit_log: {
    timeExecuted: number;
    eventType: string;
    from?: string;
    changeKey?: any[];
    currentValue?: any[];
    from_user?: {
      userID: string;
      name: string;
    };
    from_agent?: {
      agentID: string;
      name: string;
    }
  }[]
}


function OrderAuditLog() {
  const dispatch = useDispatch()
  const [auditLog, setAuditLog] = useState<auditLogInterface>()
  const [lastTimelineFetch, setLastTimelineFetch] = useState(0)
  const [orderId, setOrderId] = useState('')

  const history = useHistory()

  useLayoutEffect(() => {
    setOrderId(getQueryParam(ActiveOrderTimeline) as string)
    return () =>{
      setOrderId('')
      history.replace(unsetQueryParam(ActiveOrderTimeline))
      history.push(updateQueryParam(SIDEBAR_QUERY_PARAM, SIDEBAR_TYPE_ORDERS))
    }
  }, [])

  useEffect(() => {
    if(!!orderId) getAuditLogData()
  }, [orderId])

  const getAuditLogData = useCallback(
    () => {
      const myFetchTime = Date.now()
      if (myFetchTime > lastTimelineFetch) {
        setLastTimelineFetch(myFetchTime)
        APIReq.orders.getAuditLog(orderId)
          .then((res) => {
            setAuditLog(res.data);
          })
          .catch((err) => { console.log(err) })
      }
    },
    [orderId],
  )

  const getFormattedChangeValue = (changeKey, changeValue) => {
    switch (changeKey) {
      case "SCHEDULEDTIMESTAMP": return tsToTime(changeValue)
      case "MEDIA": return (
        <Button appearance="link"
          onClick={() => dispatch(setOrderTimelineMedia({
          mediaFrom: Object.keys(changeValue)[0],
          mediaUrl: changeValue[Object.keys(changeValue)[0]]
          }))}
          size="sm"
        >View media</Button>
      )
      case "PACK": return (
        `no: ${changeValue?.no_of_items},
         wt: ${changeValue?.weight},
        ${changeValue?.message ? `msg: ${changeValue?.message}` : ''}`
      )
      default: return changeValue && JSON.stringify(changeValue).replaceAll("\"", "")
    }
  }
  return (
    <div className="pv-1 ph-2">
      <Header title={`Order Timeline ${orderId}`} />
        { auditLog?.audit_log.map((item, i) => (
            <Paper className="d-block font-sm w-100 p-1 mt-1 mb-1" key={i}>
              <div className="d-flex  justify-space-between">
              <span>{
                (item.from_user && `${item.from_user?.name} • 
                  ${item.from_user?.userID === auditLog.userID ? 'user' : 'admin'}`) ||
                (item.from_agent && `${item.from_agent?.name} • agent`) ||
                "unknown user"
              }</span>
                <span>{ tsToTime(item.timeExecuted) }</span>
            </div>
            <hr />
            <div className="flex-column d-flex font-sm ">
                {item.eventType === 'update' ? item?.changeKey?.map((change, i) => (
                  <div className="d-flex  justify-space-between flex-wrap"
                    key={i}>
                    <span>{ `• ` }{change}</span>
                      <span>{getFormattedChangeValue(change.toUpperCase(), item?.currentValue ? item.currentValue[i] : '')}</span>
                  </div>
                )): <span><b>Created</b></span>}
              </div>
            </Paper>
        ))}
    </div> 
  );
}

export default OrderAuditLog;

import { createSlice } from "@reduxjs/toolkit";

const ordersIndex = 0;
const agentsIndex = 1;

const allOrdersIndex = 0;
const allAgentsIndex = 0;

const initState = [
  [
    null, // All orders
  ], // Order layer
  [
    null, // All agents
  ]
];

const layerData = createSlice({
  name: "layers",
  initialState: initState,
  reducers: {
    setNucleusOrdersLayers: (state, action: { payload: any }) => {
      state[ordersIndex][allOrdersIndex] = action.payload;
    },
    setNucleusAgentsLayers: (state, action: { payload: any }) => {
      state[agentsIndex][allAgentsIndex] = action.payload;
    },
    resetNucleusLayers: (state) => initState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setNucleusOrdersLayers,
  setNucleusAgentsLayers,
  resetNucleusLayers,
} = layerData.actions;
export const nucleusLayersSlice = (state) => state.nucleusDeckLayers;
export default layerData.reducer;
